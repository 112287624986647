import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import AccountFilterProvider from 'context/AccountFilter';
import PermissionRoute from 'components/ProtectedRoute';
import { Platforms } from 'constants/platforms';
import Header from 'components/MainHeader';
import Analytics from 'pages/Analytics';
import Competitive from 'pages/Competitive';
import Dashboard from 'pages/Dashboard';
import AdRules from 'pages/AdRules';

const ClientRouter = () => {
  return (
    <AccountFilterProvider>
      <Header />
      <Routes>
        <Route path="/:platform/ad-rules" element={<AdRules />} />
        <Route path="/analytics" element={<Analytics />} />
        <Route path="/table" element={<Competitive />} />
        <Route
          path="/tiktok"
          element={
            <PermissionRoute platform={Platforms.tiktok}>
              <Dashboard platform={Platforms.tiktok} />
            </PermissionRoute>
          }
        />
        <Route
          path="/facebook"
          element={
            <PermissionRoute platform={Platforms.facebook}>
              <Dashboard platform={Platforms.facebook} />
            </PermissionRoute>
          }
        />
        <Route
          path="/google"
          element={
            <PermissionRoute platform={Platforms.google}>
              <Dashboard platform={Platforms.google} />
            </PermissionRoute>
          }
        />
        <Route
          path="/yandex"
          element={
            <PermissionRoute platform={Platforms.yandex}>
              <Dashboard platform={Platforms.yandex} />
            </PermissionRoute>
          }
        />
        <Route path="*" element={<Navigate to="/tiktok" replace />} />
      </Routes>
    </AccountFilterProvider>
  );
};

export default ClientRouter;
