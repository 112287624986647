import React, { useEffect } from 'react';
import { Box, Card, Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Line } from 'react-chartjs-2';
import _isempty from 'lodash.isempty';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';

import { useMediaQuery } from 'hooks/useMediaQuery';
import Loader from 'components/Common/Loader';

import AnalyticsFiltersProvider, { useAnalyticsFilters } from './AnalyticsFiltersContext';
import MobileAnalyticsFilters from './AnalyticsFilters/MobileAnalyticsFilters';
import DesktopAnalyticsFilters from './AnalyticsFilters/DesktopAnalyticsFilters';
import { useAnalyticsRequest } from './hooks/useAnalyticsRequest';
import { useAnalyticMetrics } from './hooks/useAnalyticMetrics';
import { ANALYTIC_METRICS, CHART_OPTIONS } from './constants';
import { formatValue } from './utils';
import { styles } from './styles';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const Analytics = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { isDesktop, isMobile } = useMediaQuery();
  const { period, userId, country, platform, offerName, errors } = useAnalyticsFilters();
  const { analytics, loading, offerNames, fetchAnalytics } = useAnalyticsRequest(
    {
      period,
      offerName,
      userId,
      platform,
      country,
    },
    {
      skip: isMobile || !userId || !_isempty(errors),
    }
  );

  useEffect(() => {
    if (isDesktop) return;
    fetchAnalytics();
  }, []);

  const { chartData, getMetricAnalytic, tooltip, setSelectedMetric, selectedMetric } = useAnalyticMetrics(analytics);

  return (
    <Grid container>
      <Grid item lg={8} md={9} xs={12} sx={styles.container}>
        <Grid container sx={styles.header}>
          <Grid item>
            <Typography variant="h1" sx={styles.title}>
              {t('analytics.title')}
            </Typography>
          </Grid>
          {isMobile && (
            <Grid item>
              <MobileAnalyticsFilters offerNames={offerNames} fetchAnalytics={fetchAnalytics} />
            </Grid>
          )}
        </Grid>
        {isDesktop && <DesktopAnalyticsFilters offerNames={offerNames} />}
        <Card sx={styles.card}>
          {loading && <Loader sx={styles.loader} />}
          {!loading && (
            <>
              <Grid container sx={styles.tabs}>
                {ANALYTIC_METRICS.map(({ label, key }) => (
                  <Grid
                    item
                    key={key}
                    sx={{
                      ...styles.tab,
                      ...(key === selectedMetric && {
                        borderTop: `2px solid ${theme.palette.primary.main}`,
                        color: theme.otherColors.text,
                      }),
                    }}
                    onClick={() => setSelectedMetric(key)}>
                    <Typography variant="h5" sx={styles.label}>
                      {t(label)}
                    </Typography>
                    <Typography mt={0.5} sx={styles.value}>
                      {`${formatValue(key, getMetricAnalytic(key)?.body?.value)}`}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
              <Box mt={2}>
                <Line
                  data={chartData}
                  options={{
                    ...CHART_OPTIONS,
                    plugins: { ...CHART_OPTIONS.plugins, tooltip },
                  }}
                />
              </Box>
            </>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

const Wrapper = () => (
  <AnalyticsFiltersProvider>
    <Analytics />
  </AnalyticsFiltersProvider>
);

export default Wrapper;
