import React, { useState } from 'react';
import TableRow from '@mui/material/TableRow';

import { AdLevels } from 'constants/levels';
import { Platforms } from 'constants/platforms';

import DashboardTableColumn from './DashboardTableColumn';
import { usePriorityContext } from './Context/Priority';
import { canChangeStatus } from './utils';
import { TableColumn, isModalExist } from './constants';
import { StatsItem } from 'models/stats';

const styles = {
  clickable: {
    cursor: 'pointer',
  },
  tableRow: (theme: any) => ({
    height: '48px',
    [theme.breakpoints.down('sm')]: {
      height: '32px',
    },
  }),
};

interface DashboardTableRowProps {
  columns: TableColumn[];
  level: AdLevels;
  platform: Platforms;
  hasEditPermission: boolean;
  handleRowClick: (id: string) => void;
  refreshData: () => void;
  row: StatsItem;
  changeStatus: (id: string, value: boolean) => void;
  updatingStatuses: string[];
  openDashboardDialog: (data: StatsItem, type: AdLevels) => void;
}

const DashboardTableRow: React.FC<DashboardTableRowProps> = ({
  level,
  handleRowClick,
  platform,
  columns,
  hasEditPermission,
  row,
  refreshData,
  openDashboardDialog,
  updatingStatuses,
  changeStatus,
}) => {
  const { checkPriority } = usePriorityContext();
  const isPriority = checkPriority(row.id);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const onRowClick = () => {
    setIsExpanded((prev) => !prev);
    handleRowClick(row.id);
  };
  return (
    <TableRow
      sx={styles.tableRow}
      onClick={onRowClick}
      style={{ ...(level !== AdLevels.ad && row.id !== 'overall' && styles.clickable) }}>
      {columns.map((column) => (
        <DashboardTableColumn
          isExpanded={isExpanded}
          key={column.key}
          column={column}
          row={row}
          level={level}
          onChangeStatus={changeStatus}
          isStatusUpdating={updatingStatuses.indexOf(row.id) !== -1}
          openDashboardDialog={openDashboardDialog}
          hasEditPermission={hasEditPermission}
          platform={platform}
          disabled={!canChangeStatus(platform, level)}
          refreshData={refreshData}
          isPriority={isPriority}
          isModalExist={isModalExist(platform, level)}
        />
      ))}
    </TableRow>
  );
};

export default DashboardTableRow;
