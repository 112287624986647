import _get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, Grid, TextField, Typography, useTheme } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import { styles } from './styles';

interface Props {
  data: any;
  onSubmit: (data: any) => void;
  saving: boolean;
}

const VariablesForm: React.FC<Props> = ({ data, onSubmit, saving }) => {
  const { t } = useTranslation();

  const { handleSubmit, control } = useForm({
    defaultValues: {
      kzCancelRate: _get(data, 'KZ.cancelRate', 0) * 100,
      kzFinishRate: _get(data, 'KZ.finishRate', 0) * 100,
      kzDeliveryFee: _get(data, 'KZ.deliveryFee', 0),
      kzCancelFee: _get(data, 'KZ.cancelFee', 0),
      kzVat: _get(data, 'KZ.vat', 0) * 100,
      byCancelRate: _get(data, 'BY.cancelRate', 0) * 100,
      byFinishRate: _get(data, 'BY.finishRate', 0) * 100,
      byDeliveryFee: _get(data, 'BY.deliveryFee', 0),
      byCancelFee: _get(data, 'BY.cancelFee', 0),
      byVat: _get(data, 'BY.vat', 0) * 100,
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {FIELDS.map((field) => (
        <Grid container key={field.name} sx={styles.row}>
          <Grid item xs={4}>
            <Typography variant="h5">
              {t(field.label)} {field.country}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Controller
              name={field.name as any}
              control={control}
              rules={{ required: true }}
              render={({ field: props }) => (
                <TextField
                  type="number"
                  InputProps={{
                    endAdornment: <Typography>{field.postFix}</Typography>,
                  }}
                  {...props}
                />
              )}
            />
          </Grid>
        </Grid>
      ))}
      <Button variant="contained" color="primary" type="submit" disabled={saving}>
        {t('admin.table.save')}
      </Button>
    </form>
  );
};

export default VariablesForm;

const FIELDS = [
  {
    name: 'kzCancelRate',
    label: 'admin.table.cancelRate',
    postFix: '%',
    country: 'KZ',
  },
  {
    name: 'kzFinishRate',
    label: 'admin.table.finishRate',
    postFix: '%',
    country: 'KZ',
  },
  {
    name: 'kzDeliveryFee',
    label: 'admin.table.deliveryFee',
    postFix: '₸',
    country: 'KZ',
  },
  {
    name: 'kzCancelFee',
    label: 'admin.table.cancelFee',
    postFix: '₸',
    country: 'KZ',
  },
  {
    name: 'kzVat',
    label: 'admin.table.vat',
    postFix: '%',
    country: 'KZ',
  },
  {
    name: 'byCancelRate',
    label: 'admin.table.cancelRate',
    postFix: '%',
    country: 'BY',
  },
  {
    name: 'byFinishRate',
    label: 'admin.table.finishRate',
    postFix: '%',
    country: 'BY',
  },
  {
    name: 'byDeliveryFee',
    label: 'admin.table.deliveryFee',
    postFix: 'Br',
    country: 'BY',
  },
  {
    name: 'byCancelFee',
    label: 'admin.table.cancelFee',
    postFix: 'Br',
    country: 'BY',
  },
  {
    name: 'byVat',
    label: 'admin.table.vat',
    postFix: '%',
    country: 'BY',
  },
];
