import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';

import enTranslation from './lang/en';
import ruTranslation from './lang/ru';

const DEFAULT_LOCALE = 'ru';

moment.locale(DEFAULT_LOCALE);

const resources = {
  en: {
    translation: enTranslation,
  },
  ru: {
    translation: ruTranslation,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: DEFAULT_LOCALE,
    fallbackLng: DEFAULT_LOCALE,

    simplifyPluralSuffix: false,
    pluralSeparator: '_',
    keySeparator: '.',

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
