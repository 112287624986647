import { useState, useEffect } from 'react';

export const useDebounce = <T>(value: T, delay: number): T => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (typeof debouncedValue !== 'object' && value !== debouncedValue) {
        setDebouncedValue(value);
      }
      if (JSON.stringify(value) !== JSON.stringify(debouncedValue)) {
        setDebouncedValue(value);
      }
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
};
