import React from 'react';
import { Box, Dialog, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ConfirmationModal from 'components/Common/ConfirmationModal';
import CrudTable from 'components/Admin/CrudTable';

import NewUserForm from './NewUserForm';
import { useUsers } from 'services/users/useUsers';
import { USERS_TABLE_COLUMNS } from './configs';
import { useNewUserDialog } from './hooks/newUser';
import { useDeleteUserDialog } from './hooks/deleteUser';
import { usePermissions } from './hooks/usePermissions';

const styles = {
  userCreatedContainer: {
    backgroundColor: (theme: any) => theme.otherColors.celeste,
    my: 2,
    py: 1,
  },
};
const Users = () => {
  const { permissions } = usePermissions();
  const { users, loading, refetch } = useUsers();
  const { t } = useTranslation();
  const {
    editedUser,
    onEdit,
    onAdd,
    isNewUserDialogOpen,
    onSuccessEdit,
    onSuccessCreate,
    createdUser,
    passwordResetUser,
    onSuccessPasswordReset,
  } = useNewUserDialog();
  const { deletedUser, onDelete, onSuccessDelete, isDeleting, handleDelete } = useDeleteUserDialog(refetch);

  return (
    <Box>
      <Grid item>
        <Typography variant="h1">{t('admin.users.users')}</Typography>
      </Grid>
      {createdUser && (
        <Grid item>
          <Box sx={styles.userCreatedContainer}>
            <Typography variant="h6">{t('admin.users.userCreated', { password: createdUser.password })}</Typography>
          </Box>
        </Grid>
      )}
      {passwordResetUser && (
        <Grid item>
          <Box sx={styles.userCreatedContainer}>
            <Typography variant="h6">
              {t('admin.users.passwordReset', { password: passwordResetUser.password })}
            </Typography>
          </Box>
        </Grid>
      )}
      <Grid item>
        <CrudTable
          onAdd={onAdd}
          onEdit={onEdit}
          onDelete={onDelete}
          columns={USERS_TABLE_COLUMNS}
          rows={users}
          isLoading={loading}
        />
      </Grid>
      <Dialog open={isNewUserDialogOpen}>
        <NewUserForm
          onCreate={onSuccessCreate}
          user={editedUser}
          onCancel={onSuccessEdit}
          onSuccess={onSuccessEdit}
          onSuccessPasswordReset={onSuccessPasswordReset}
          refetch={refetch}
          permissions={permissions}
        />
      </Dialog>
      <ConfirmationModal
        title={t('admin.users.deleteUser')}
        subtitle={t('admin.users.deleteUserConfirm', { name: deletedUser?.name })}
        onCancel={onSuccessDelete}
        onConfirm={handleDelete}
        open={isDeleting}
      />
    </Box>
  );
};

export default Users;
