import { AnalyticMetrics } from './models';
import { Countries } from '../../components/CountrySelect/models';

export const ANALYTIC_METRICS = [
  {
    key: AnalyticMetrics.spend,
    label: 'analytics.spend',
  },
  {
    key: AnalyticMetrics.conversion,
    label: 'analytics.conversion',
  },
  {
    key: AnalyticMetrics.costPerConversion,
    label: 'analytics.costPerConversion',
  },
];

export const CHART_OPTIONS = {
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
    },
    x: {
      grid: {
        display: false,
      },
    },
  },
  responsive: true,
  elements: {
    point: {
      radius: 0,
    },
  },
  interaction: {
    intersect: false,
    mode: 'index' as any,
  },
  tooltips: {
    mode: 'index',
    intersect: false,
  },
};

export const COUNTRY_OPTIONS = [
  {
    id: Countries.RB,
    label: 'dashboard.belarus',
  },
  {
    id: Countries.KZ,
    label: 'dashboard.kazakhstan',
  },
];

export const DEFAULT_OPTION = {
  label: 'analytics.all',
  id: 'all',
};
