import React, { useEffect, useState } from 'react';
import { Box, Card, Grid, Tooltip, Typography, useTheme } from '@mui/material';
import _get from 'lodash.get';
import { styles } from './styles';
import { formatValue } from 'pages/Analytics/utils';
import { Currencies } from 'models/currencies';

interface Column {
  title: string;
  key: string;
  width?: number | string;
}

interface Props {
  columns: Column[];
  item: any;
  country: string;
}

const CompetitiveCard: React.FC<Props> = ({ columns, item, country }) => {
  const theme = useTheme();
  const currency = country === 'BY' ? Currencies.BYN : Currencies.KZT;

  const tooltip = (
    <Box>
      Сумма в CRM: {Number(item.metrics.crmSum).toFixed(2)} {currency}
      <br />
      Прогноз Выручки: {Number(item.metrics.profit).toFixed(2)} {currency}
      <br />
      Прогноз Доставки: {Number(item.metrics.deliveryFee).toFixed(2)} {currency}
      <br />
      Прогноз Себестоимости: {Number(item.metrics.selfCost).toFixed(2)} {currency}
      <br />
      Прогноз НДС: {Number(item.metrics.vat).toFixed(2)} {currency}
      <br />
      Прогноз Выкупов: {Math.trunc(Number(item.metrics.finishCount))}
      <br />
      Расходы: {Number(item.metrics.spend).toFixed(2)} $<br />
      Продаж: {Number(item.metrics.count)}
    </Box>
  );

  return (
    <Card sx={[styles.card]} id={`${country}-card-${item.user?._id || 'total'}`}>
      <Grid container>
        {columns.map((column) => (
          <Grid item key={column.title} sx={column.width ? { width: column.width } : null}>
            <Tooltip title={item.user ? tooltip : ''}>
              <Typography sx={styles.label}>
                {column.key === 'user.name'
                  ? _get(item, column.key, 'Итог')
                  : formatValue(column.key.split('.')[1] as any, _get(item, column.key), currency)}
              </Typography>
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};

export default React.memo(CompetitiveCard);
