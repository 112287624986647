import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Dialog, Grid, useTheme } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import PendingOperationsView from 'components/DashboardTable/PendingOperations/PendingOperationsView';
import { ReactComponent as FilterAccountsIcon } from 'assets/svg/filterAccounts.svg';
import CommonDateRangePicker from 'components/Common/CommonDateRangePicker';
import { useDashboardFilterContext } from 'context/DashboardFilter';
import AdvertiserFilter from 'components/AdvertiserFilter';
import CountrySelect from 'components/CountrySelect';
import { usePlatform } from 'hooks/usePlatform';
import { useDialog } from 'hooks/useDialog';
import Excel from 'components/Excel';

import OfferFilter from './OfferFilter';

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    '&:last-child': {
      marginRight: 0,
    },
  },
  filterAccountsContainer: {
    alignItems: 'flex-end',
  },
  button: {
    height: '36px',
    padding: '0 28px',
    boxSizing: 'border-box',
  },
};
const DesktopDashboardHeader: React.FC<any> = () => {
  const navigate = useNavigate();
  const platform = usePlatform();
  const { t } = useTranslation();
  const theme = useTheme();
  const { openDialog, isDialogOpen, closeDialog } = useDialog();
  const { country, setCountry, currentDate, onDateChange, activeRows } = useDashboardFilterContext();

  const openAdRules = () => navigate(`/${platform}/ad-rules`);

  return (
    <>
      <Grid container sx={styles.container}>
        <Grid item xs={11}>
          <Grid container columnSpacing={2}>
            <Grid item sx={styles.gridItem} xs={2}>
              <CountrySelect onChange={setCountry} value={country} label={t('dashboard.country')} />
            </Grid>
            <Grid item sx={styles.gridItem} xs={2}>
              <CommonDateRangePicker
                label={t('dashboard.period')}
                value={currentDate}
                onChange={(date: any) => onDateChange(date)}
                maxDate={new Date()}
              />
            </Grid>
            <Grid item sx={styles.gridItem} xs={2}>
              <OfferFilter />
            </Grid>
            <Grid item sx={{ ...styles.gridItem, ...styles.filterAccountsContainer }}>
              <Button
                variant="outlined"
                onClick={openDialog}
                color="primary"
                startIcon={<FilterAccountsIcon fill={theme.palette.primary.main} />}
                sx={styles.button}>
                {t(activeRows.length > 0 ? 'dashboard.filterAccountsCount' : 'dashboard.filterAccounts', {
                  count: activeRows.length,
                })}
              </Button>
            </Grid>
            <Grid item sx={{ ...styles.gridItem, ...styles.filterAccountsContainer }}>
              <Button
                variant="outlined"
                onClick={openAdRules}
                color="primary"
                startIcon={<AccessTimeIcon />}
                sx={styles.button}>
                {t('dashboard.autoRules')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container sx={{ justifyContent: 'flex-end' }}>
            <Grid item sx={styles.gridItem}>
              <PendingOperationsView />
            </Grid>
            <Grid item sx={styles.gridItem}>
              <Excel />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog open={isDialogOpen}>
        <AdvertiserFilter close={closeDialog} />
      </Dialog>
    </>
  );
};

export default DesktopDashboardHeader;
