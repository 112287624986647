import React from 'react';
import { Dialog, Grid, IconButton, Typography } from '@mui/material';
import { AdLevels } from 'constants/levels';

import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import { styles, dialogStyles } from 'components/Common/Shared/dialogStyles';
import { StatsItem } from 'models/stats';
import getAdCampaignSettings from './AdCampaignSettings';
import AccountSettings from './AccountSettings';
import getGroupSettings from './GroupSettings';
import DialogContent from './DialogContent';

interface Props {
  type: AdLevels;
  data: StatsItem;
  close: () => void;
  refetchData: () => void;
  changeStatus: (id: string, value: boolean) => void;
}

const DashboardDialog: React.FC<Props> = ({ type, ...props }) => {
  const renderDialogContent = () => {
    switch (type) {
      case AdLevels.campaign:
        return <DialogContent getContent={getAdCampaignSettings} {...props} />;
      case AdLevels.group:
        return <DialogContent getContent={getGroupSettings} {...props} />;
      default:
        return null;
    }
  };
  return (
    <Dialog open={true} PaperProps={{ sx: dialogStyles.primaryContainer }}>
      <Grid container sx={styles.headerContainer}>
        <Grid item>
          <Typography variant="h3">{props.data.name}</Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={props.close} sx={styles.closeButton} size="large">
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      {renderDialogContent()}
    </Dialog>
  );
};

export default DashboardDialog;
