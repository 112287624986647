import React from 'react';
import { Dialog, DialogActions, DialogContent, Button, Typography, Box } from '@mui/material';
import LoadingButton from 'components/Common/LoadingButton';
import { useTranslation } from 'react-i18next';

interface ConfirmationModalProps {
  title: string;
  subtitle: string;
  body?: React.ReactNode;
  onCancel: () => void;
  onConfirm: () => void;
  isLoading?: boolean;
  open: boolean;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  title,
  subtitle,
  body,
  onCancel,
  onConfirm,
  open,
  isLoading = false,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} sx={{ '& .MuiPaper-root': { p: 1.5, pb: 1 } }}>
      <Box sx={{ mx: 2, mt: 1 }}>
        <Typography variant="h2">{title}</Typography>
      </Box>
      <DialogContent sx={{ mx: 2, mt: 2, p: 0 }}>
        <Typography>{subtitle}</Typography>
      </DialogContent>
      {body}
      <DialogActions sx={{ m: 2, p: 0 }}>
        <Button onClick={onCancel}>{t('common.cancel')}</Button>
        <LoadingButton color="primary" onClick={onConfirm} variant="contained" isLoading={isLoading}>
          {t('common.confirm')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
