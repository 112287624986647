import { Typography } from '@mui/material';
import _isempty from 'lodash.isempty';
import i18n from '../../../i18n';

import { compareIncludes, compareNumber } from 'components/Admin/CrudTable/utils';
import { Column } from 'components/Admin/CrudTable/models';
import { User } from 'models/user';

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export const USERS_TABLE_COLUMNS: Column<User>[] = [
  {
    title: 'ID',
    key: 'id',
    compareFunction: compareNumber,
  },
  {
    title: i18n.t('common.name'),
    key: 'name',
    compareFunction: compareIncludes,
  },
  {
    title: 'Email',
    key: 'email',
    compareFunction: compareIncludes,
  },
  {
    title: 'Code',
    key: 'code',
    compareFunction: compareIncludes,
  },
  {
    title: i18n.t('admin.users.permissions'),
    key: 'permissions',
    render: ({ permissions }: User) => {
      const platforms: { [key: string]: string[] } = {};
      permissions.forEach(({ permission }, index) => {
        const [platformDashboard, operation] = permission.split(':');
        const [platformName] = platformDashboard.split('-');
        const platformOperations = platforms[platformName];
        const operationName = capitalizeFirstLetter(i18n.t(`permissions.operations.${operation}`));
        if (_isempty(platformOperations)) {
          platforms[platformName] = [operationName];
        } else {
          platforms[platformName] = [...platformOperations, operationName];
        }
      });
      return Object.entries(platforms).map(([platformName, permissions]) => (
        <Typography mt={1}>{`${capitalizeFirstLetter(platformName)}: ${permissions.join(', ')}`}</Typography>
      ));
    },
  },
  {
    title: i18n.t('common.admin'),
    key: 'isAdmin',
    render: (user) => (user.isAdmin ? '+' : ''),
  },
];
