import _get from 'lodash.get';

import { AdLevels } from 'constants/levels';
import { Platforms } from 'constants/platforms';
import { Currencies } from 'models/currencies';
import { StatsItem } from 'models/stats';

export interface TableColumn {
  key: string;
  show?: boolean;
  left?: boolean;
  bold?: boolean;
  name?: string;
  width?: string;
  levelStyles?: (theme: any, level: AdLevels) => any;
  format?: (value: any, row: StatsItem) => string | number;
  styles?: any;
  color?: (value: any) => string;
}

const getTextStyles = (theme: any, level: AdLevels) => {
  const styles = {
    fontWeight: 600,
  };

  switch (level) {
    case AdLevels.campaign:
      return { ...styles, fontWeight: 500 };
    case AdLevels.group:
      return { ...styles, fontWeight: 400 };
    case AdLevels.ad:
      return { ...styles, fontWeight: 300 };
    default:
      return styles;
  }
};

export const PLATFORMS_WITH_ACCOUNT_LINK = [Platforms.tiktok, Platforms.facebook];

export const CAN_CHANGE_STATUS_PLATFORMS: { [key: string]: { [key: string]: boolean } } = {
  [Platforms.tiktok]: {
    [AdLevels.advertiser]: true,
    [AdLevels.campaign]: true,
    [AdLevels.group]: true,
    [AdLevels.ad]: true,
  },
  [Platforms.facebook]: {
    [AdLevels.advertiser]: true,
    [AdLevels.campaign]: true,
    [AdLevels.group]: true,
    [AdLevels.ad]: true,
  },
  [Platforms.google]: {
    [AdLevels.advertiser]: true,
    [AdLevels.campaign]: true,
    [AdLevels.group]: true,
    [AdLevels.ad]: true,
  },
  [Platforms.yandex]: {
    [AdLevels.advertiser]: true,
    [AdLevels.campaign]: true,
    [AdLevels.group]: false,
    [AdLevels.ad]: true,
  },
};

export const ACTIVE_STATUSES = [
  'ACCOUNT_STATUS_ENABLE',
  'ADGROUP_STATUS_DELIVERY_OK',
  'CAMPAIGN_STATUS_ENABLE',
  'AD_STATUS_DELIVERY_OK',
  'ACTIVE',
  'ACCEPTED',
  'ENABLED',
  'ON',
  'ACTIVE',
  'ENABLE',
];
export const DISABLED_STATUSES = [
  'ADGROUP_STATUS_CAMPAIGN_DISABLE',
  'ACCOUNT_STATUS_DISABLED',
  'ADGROUP_STATUS_DISABLE',
  'CAMPAIGN_STATUS_DISABLE',
  'AD_STATUS_DISABLE',
  'REJECTED',
  'PAUSED',
  'SUSPENDED',
  'DISABLE',
];
export const DELETED_STATUSES = ['ADGROUP_STATUS_DELETE', 'CAMPAIGN_STATUS_DELETE'];

export const formatMoney = (value: number, row: { currency?: Currencies }) => {
  if (!value && value !== 0) {
    return '-';
  }
  const valueStr = Number(value).toFixed(2);
  switch (row.currency) {
    case Currencies.BYN:
      return `${valueStr} Br`;
    case Currencies.KZT:
      return `${Math.floor(value)} ₸`;
    case Currencies.RUB:
      return `${valueStr} ₽`;
    case Currencies.PLN:
      return `${valueStr} zł`;
    case Currencies.USD:
      return `$ ${valueStr}`;
    default:
      return Intl.NumberFormat('en-US', { style: 'currency', currency: row.currency }).format(value);
  }
};

const formatPercent = (value: number) => value && `${Number(value).toFixed(2)}%`;
export const formatMoneyUSD = (value: number) => formatMoney(value, { currency: Currencies.USD });

const COMMON_COLUMNS: TableColumn[] = [
  { key: 'bid', name: 'Bid', format: formatMoney },
  { key: 'conversion', name: 'Conv.', format: (value: number) => Math.round(value) },
  { key: 'crm', name: 'CRM', format: (value: number) => Math.round(value) },
  { key: 'cost_per_conversion', name: 'CPA', format: formatMoneyUSD },
  { key: 'spend', name: 'Spend', format: formatMoneyUSD },
  { key: 'clicks', name: 'Clicks' },
  { key: 'impressions', name: 'Impr.' },
  { key: 'conversion_rate', name: 'CR', format: formatPercent },
  { key: 'ctr', name: 'CTR', format: formatPercent },
  { key: 'cpc', name: 'CPC', format: formatMoneyUSD },
  { key: 'cpm', name: 'CPM', format: formatMoneyUSD },
  { key: 'reach', name: 'Reach' },
  { key: 'frequency', name: 'Freq.' },
  { key: 'balance', name: 'Balance', format: formatMoneyUSD },
];

export const DASHBOARD_COLUMNS: TableColumn[] = [
  { key: 'options', show: true },
  {
    key: 'name',
    name: 'Account Name',
    bold: true,
    left: true,
    levelStyles: getTextStyles,
  },
  ...COMMON_COLUMNS,
];

export const DASHBOARD_AD_COLUMNS: TableColumn[] = [
  { key: 'options', show: true },
  { key: 'name', bold: true, left: true, levelStyles: getTextStyles },
  ...COMMON_COLUMNS,
];

export const DASHBOARD_GROUP_COLUMNS: TableColumn[] = [
  { key: 'options', show: true },
  { key: 'name', bold: true, left: true, levelStyles: getTextStyles },
  ...COMMON_COLUMNS,
];

export const DASHBOARD_CAMPAIGN_COLUMNS = [
  { key: 'options', show: true },
  { key: 'name', left: true, bold: true, levelStyles: getTextStyles },
  ...COMMON_COLUMNS,
];

export const MODALS_BY_LEVELS = {
  [Platforms.tiktok]: {
    [AdLevels.group]: true,
    [AdLevels.advertiser]: true,
  },
  [Platforms.facebook]: {
    [AdLevels.group]: true,
    [AdLevels.advertiser]: true,
  },
  [Platforms.google]: {
    [AdLevels.group]: true,
    [AdLevels.campaign]: true,
    [AdLevels.advertiser]: true,
  },
  [Platforms.yandex]: {
    [AdLevels.campaign]: true,
    [AdLevels.advertiser]: true,
  },
};

export const isModalExist = (platform: Platforms, level: AdLevels) => _get(MODALS_BY_LEVELS, [platform, level], false);
