import AnalyticsIcon from '@mui/icons-material/Analytics';

export const LINKS = [
  {
    link: '/table',
    Icon: AnalyticsIcon,
    label: 'analytics.table',
  },
  {
    link: '/analytics',
    Icon: AnalyticsIcon,
    label: 'analytics.title',
  },
];
