import { useEffect, useState } from 'react';
import { analytics as analyticsEndpoint } from 'constants/endpoints';
import ApiClient from 'services/apiClient';

import { normalizeAnalytics } from '../utils';
import { DEFAULT_OPTION } from '../constants';
import { Analytic } from '../models';

interface AnalyticsRequestBody {
  period: { startDate: Date; endDate: Date };
  offerName: string;
  userId: string;
  platform: string;
  country: string;
}

interface AnalyticsRequestOptions {
  skip: boolean;
}

export const useAnalyticsRequest = (
  { period, offerName, userId, platform, country }: AnalyticsRequestBody,
  options?: AnalyticsRequestOptions
) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [analytics, setAnalytics] = useState<Analytic[]>([]);
  const [offerNames, setOfferNames] = useState<string[]>([]);

  const getAnalytics = async () => {
    try {
      setLoading(true);
      const res = await ApiClient.GET(analyticsEndpoint, {
        params: {
          startDate: period.startDate.toISOString(),
          endDate: period.endDate.toISOString(),
          offerName: offerName === DEFAULT_OPTION.id ? '' : offerName,
          userId: userId === DEFAULT_OPTION.id ? '' : userId,
          platform: platform === DEFAULT_OPTION.id ? '' : platform,
          country: country === DEFAULT_OPTION.id ? '' : country,
        },
      });

      setAnalytics(() => normalizeAnalytics(res.statistics));
      setOfferNames(res.offerNames);
    } catch (e) {
      console.log((e as any).statusText);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (options?.skip) return;
    getAnalytics();
  }, [period, platform, userId, offerName, country]);

  return {
    loading,
    analytics,
    offerNames,
    fetchAnalytics: getAnalytics,
  };
};
