import React from 'react';
import moment from 'moment';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import CommonDateRangePicker from 'components/Common/CommonDateRangePicker';
import { getDataFromLocalStorage } from 'utils/localStorage';
import CountrySelect from 'components/CountrySelect/CountrySelect';
import { Countries } from 'components/CountrySelect/models';
import LoadingButton from 'components/Common/LoadingButton/LoadingButton';
import { Levels } from './models';

interface Props {
  onClose: () => void;
  onSubmit: (body: { level: Levels; dateFrom: string; dateTo: string; country: Countries; unit?: string }) => void;
}

const styles = {
  headerContainer: {
    padding: '21px 31px',
    boxShadow: (theme: any) => `inset 0px -1px 0px ${theme.otherColors.lightGray}`,
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  closeButton: {
    padding: 0,
  },
  dialogContent: {
    padding: '24px 31px',
  },
  dialogPaper: { minWidth: '780px' },
  inputContainer: {
    width: '280px',
  },
  datePickerContainer: {
    marginLeft: '52px',
  },
  footer: {
    padding: '24px 31px',
    backgroundColor: (theme: any) => theme.otherColors.lightBlue,
    justifyContent: 'flex-start',
  },
  exportButton: {
    width: '120px',
  },
};

const ExcelDialog: React.FC<Props> = ({ onClose, onSubmit }) => {
  const [country, setCountry] = React.useState<Countries>(() => {
    const savedCountry = getDataFromLocalStorage('dashboardCountry');
    return savedCountry || Countries.all;
  });
  const [unit, setUnit] = React.useState<string>('' as string);
  const [level, setLevel] = React.useState<Levels>(Levels.advertisers);
  const [isExporting, setExporting] = React.useState<boolean>(false);
  const [exportDate, setExportDate] = React.useState(() => {
    const savedDate = getDataFromLocalStorage('dashboardPeriod');
    return {
      startDate: savedDate ? new Date(savedDate.startDate) : new Date(),
      endDate: savedDate ? new Date(savedDate.endDate) : new Date(),
    };
  });
  const { t } = useTranslation();

  const handleExport = async () => {
    setExporting(true);
    await onSubmit({
      level,
      dateFrom: exportDate.startDate.toISOString(),
      dateTo: exportDate.endDate.toISOString(),
      country,
      unit: unit || undefined,
    });
    setExporting(false);
  };

  const handleSelect = (e: SelectChangeEvent) => {
    const newLevel = e.target.value as Levels;
    setLevel(newLevel);
  };

  const handleSelectUnit = (e: SelectChangeEvent) => {
    const newUnit = e.target.value as string;
    setUnit(newUnit);
  };

  return (
    <Dialog open onClose={onClose} PaperProps={{ sx: styles.dialogPaper }}>
      <DialogTitle sx={styles.headerContainer}>
        <Grid container sx={styles.headerTitle}>
          <Grid item>
            <Typography variant="h2">{t('dashboard.exportToExcel')}</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={onClose} sx={styles.closeButton} size="large">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={styles.dialogContent}>
        <Grid container gap={2}>
          <Grid item sx={styles.inputContainer}>
            <FormControl fullWidth>
              <Typography variant="h5">{t('dashboard.level')}</Typography>
              <Select labelId="level-label" value={level} onChange={handleSelect} variant="outlined">
                <MenuItem value={Levels.advertisers}>{t('dashboard.advertisers')}</MenuItem>
                <MenuItem value={Levels.campaigns}>{t('dashboard.campaigns')}</MenuItem>
                <MenuItem value={Levels.groups}>{t('dashboard.groups')}</MenuItem>
                <MenuItem value={Levels.ads}>{t('dashboard.ads')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item sx={styles.inputContainer}>
            <Box>
              <CommonDateRangePicker
                value={exportDate}
                onChange={(value: any) => {
                  setExportDate(value);
                }}
                label={t('dashboard.period')}
                maxDate={moment().utcOffset(3).toDate()}
              />
            </Box>
          </Grid>
          <Grid item sx={styles.inputContainer}>
            <FormControl fullWidth>
              <Typography variant="h5">{t('dashboard.country')}</Typography>
              <CountrySelect value={country} onChange={setCountry} />
            </FormControl>
          </Grid>
          <Grid item sx={styles.inputContainer}>
            <FormControl fullWidth>
              <Typography variant="h5">{t('dashboard.unit')}</Typography>
              <Select labelId="unit-label" value={unit} onChange={handleSelectUnit} variant="outlined">
                <MenuItem value="">{t('dashboard.units.no')}</MenuItem>
                <MenuItem value="day">{t('dashboard.units.day')}</MenuItem>
                <MenuItem value="week">{t('dashboard.units.week')}</MenuItem>
                <MenuItem value="month">{t('dashboard.units.month')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={styles.footer}>
        <LoadingButton
          variant="contained"
          onClick={handleExport}
          isLoading={isExporting}
          color="primary"
          sx={styles.exportButton}>
          {t('common.export')}
        </LoadingButton>
        <Button onClick={onClose} variant="contained" color="secondary">
          {t('common.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExcelDialog;
