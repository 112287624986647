import React from 'react';
import { formatMoney } from 'components/DashboardTable/constants';
import { Analytic, AnalyticMetrics, MonthValue } from './models';
import { Currencies } from 'models/currencies';

export const formatValue = (key?: AnalyticMetrics, value: number | undefined = 0, currency?: Currencies) => {
  switch (key) {
    case AnalyticMetrics.cpl:
    case AnalyticMetrics.spend:
    case AnalyticMetrics.costPerConversion:
      return formatMoney(value, { currency: Currencies.USD });
    case AnalyticMetrics.vp2:
    case AnalyticMetrics.vp2Single:
      return formatMoney(value, { currency: currency || Currencies.USD });
    case AnalyticMetrics.conversion:
      return Math.round(value);
    case AnalyticMetrics.romi:
      return value ? `${(value * 100).toFixed(1)}%` : 0;
    default:
      return value;
  }
};

const sortDayStrings = ({ day: day1 }: MonthValue, { day: day2 }: MonthValue) => {
  day1 = day1.split('.').reverse().join('');
  day2 = day2.split('.').reverse().join('');
  return day1 > day2 ? 1 : day1 < day2 ? -1 : 0;
};
export const normalizeAnalytics = (analytics: Analytic[]): Analytic[] =>
  analytics.map(({ key, body: { value, monthValues } }) => ({
    key,
    body: {
      value: value || 0,
      monthValues: monthValues.sort(sortDayStrings),
    },
  }));
