export const styles = {
  title: (theme: any) => ({
    fontSize: '32px',
    mt: '0',
    mb: '0',
    [theme.breakpoints.down('md')]: {
      fontSize: '24px',
    },
  }),
  country: {
    transform: 'rotate(-90deg) translate(-35px)',
    width: 30,
  },
  header: (theme: any) => ({
    justifyContent: 'space-between',
    mt: '30px',
    mb: '15px',
    [theme.breakpoints.down('md')]: {
      fontSize: '20px',
      mt: '15px',
      mb: '8px',
    },
  }),
  container: (theme: any) => ({
    padding: '30px',
    [theme.breakpoints.down('md')]: {
      padding: '16px',
    },
  }),
  loader: {
    mt: '30px',
  },
  select: {
    '.MuiOutlinedInput-notchedOutline': { border: 0 },
  },
  tab: {
    cursor: 'pointer',
    color: (theme: any) => theme.otherColors.darkGray,
    p: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginLeft: 2,
    '&:first-child': {
      marginLeft: 0,
    },
  },
  tabs: {
    display: 'flex',
  },
  card: (theme: any) => ({
    padding: '4px 16px',
    left: 0,
    right: 0,
    transition: 'transform 0.7s ease-in-out',
    position: 'absolute',
    [theme.breakpoints.down('md')]: {
      padding: '8px',
    },
    '&:last-child': {
      backgroundColor: '#fff5b3',
    },
  }),
  tabContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: (theme: any) => ({
    textAlign: 'left',
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      marginRight: '2px',
    },
  }),
  value: (theme: any) => ({
    fontWeight: 500,
    fontSize: 20,
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  }),
};
