import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { useDashboardFilterContext } from 'context/DashboardFilter';

const styles = {
  select: {
    height: '36px',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
  },
};

const OfferFilter = () => {
  const { t } = useTranslation();
  const { offerNameFilter, setOfferNameFilter, offerFilterOptions } = useDashboardFilterContext();

  const onChange = (e: SelectChangeEvent) => {
    const offerName = e.target.value as string;
    setOfferNameFilter(offerName);
  };

  return (
    <FormControl fullWidth>
      <Typography variant="h5">{t('dashboard.offerFilter')}</Typography>
      <Select value={offerNameFilter} onChange={onChange} sx={styles.select} disabled={offerFilterOptions.length < 1}>
        <MenuItem key="all" value="all">
          {t('dashboard.all')}
        </MenuItem>
        {offerFilterOptions.map((offerName) => (
          <MenuItem key={offerName} value={offerName}>
            {offerName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default OfferFilter;
