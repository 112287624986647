export const styles = {
  contentContainer: (theme: any) => ({
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    padding: '0 31px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 26px',
    },
  }),
  secondContentContainer: (theme: any) => ({
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    padding: '0 31px',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      padding: '0 26px',
    },
  }),
  switchContainer: {
    alignItems: 'center',
    marginBottom: '20px',
    flexWrap: 'nowrap',
  },
  inputsContainer: {
    flexWrap: 'nowrap',
  },
  secondInput: {
    marginLeft: '24px',
  },
  confirmButton: {
    width: '120px',
    height: '36px',
    boxSizing: 'border-box',
    marginRight: '25px',
  },
  headerContainer: (theme: any) => ({
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 31px',
    height: '69px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 26px',
    },
  }),
  dialogFooter: (theme: any) => ({
    height: '98px',
    backgroundColor: theme.otherColors.lightBlue,
    padding: '0 26px',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    margin: 0,
    borderBottomRightRadius: '4px',
    borderBottomLeftRadius: '4px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 26px',
      height: '102px',
    },
  }),
  closeButton: {
    padding: 0,
  },
};

export const dialogStyles = {
  primaryContainer: (theme: any) => ({
    width: '780px',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width: '390px',
    },
  }),
};
