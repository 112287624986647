import { Platforms } from 'constants/platforms';
import TiktokGroupSettings from './TiktokGroupSettings/TiktokGroupSettings';
import FacebookGroupSettings from './FacebookGroupSettings/FacebookGroupSettings';
import GoogleGroupSettings from './GoogleGroupSettings/GoogleGroupSettings';
import defaultGroupSettings from './DefaultGroupSettings';

const getGroupSettings = (platform: Platforms) => {
  switch (platform) {
    case Platforms.tiktok:
      return TiktokGroupSettings;
    case Platforms.facebook:
      return FacebookGroupSettings;
    case Platforms.google:
      return GoogleGroupSettings;
    default:
      return defaultGroupSettings;
  }
};

export default getGroupSettings;
