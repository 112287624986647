import { useCallback, useState } from 'react';

import { useDialog } from 'hooks/useDialog';
import { User } from 'models/user';

export const useNewUserDialog = () => {
  const [createdUser, setCreatedUser] = useState<User | null>(null);
  const [editedUser, setEditedUser] = useState<User | null>(null);
  const [passwordResetUser, setPasswordResetUser] = useState<User | null>(null);
  const { openDialog, closeDialog, isDialogOpen } = useDialog();

  const onEdit = useCallback(
    (user: User) => {
      setEditedUser(user);
      openDialog();
    },
    [openDialog]
  );

  const onAdd = useCallback(() => {
    openDialog();
  }, [openDialog]);

  const onSuccessEdit = useCallback(() => {
    closeDialog();
    setEditedUser(null);
  }, [closeDialog]);

  const onSuccessCreate = useCallback((user: User) => {
    closeDialog();
    setCreatedUser(user);
  }, []);

  const onSuccessPasswordReset = useCallback(
    (user: User) => {
      closeDialog();
      setPasswordResetUser(user);
    },
    [closeDialog]
  );

  return {
    onEdit,
    onAdd,
    editedUser,
    onSuccessEdit,
    onSuccessCreate,
    createdUser,
    onSuccessPasswordReset,
    passwordResetUser,
    isNewUserDialogOpen: isDialogOpen,
  };
};
