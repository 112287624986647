import React, { useState } from 'react';
import { Typography, Box, IconButton, Drawer } from '@mui/material';

import { ReactComponent as MenuIcon } from 'assets/svg/menu.svg';
import Menu from 'components/Menu';
import Notifications from './Notifications';

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    paddingLeft: 2,
    paddingRight: 2,
    height: '56px',
  },
  menuButton: {
    padding: 0,
  },
  drawer: {
    width: '100%',
  },
  title: {
    whiteSpace: 'nowrap',
  },
};
const MobileHeader = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const openMenu = () => setMenuOpen(true);
  const closeMenu = () => setMenuOpen(false);

  return (
    <Box sx={styles.container}>
      <Typography variant="h2" sx={styles.title}>
        Ads Manager
      </Typography>
      <Box>
        <Notifications />
        <IconButton onClick={openMenu} sx={styles.menuButton} size="large">
          <MenuIcon />
        </IconButton>
        <Drawer
          open={menuOpen}
          onClose={closeMenu}
          PaperProps={{
            sx: styles.drawer,
          }}>
          <Menu closeMenu={closeMenu} />
        </Drawer>
      </Box>
    </Box>
  );
};

export default MobileHeader;
