import React, { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Box,
  Toolbar,
  List,
  Divider,
  IconButton,
  ListItemButton,
  ListItem,
  ListItemText,
  ListItemIcon,
  Grid,
} from '@mui/material';

import { Drawer, AppBar, DrawerHeader } from './styled';
import { ADMIN_MENU_ITEMS } from './configs';

interface MiniDrawerProps {
  children: React.ReactNode;
}

const styles = {
  listItem: { display: 'block' },
  listItemButton: {
    minHeight: 48,
    px: 2.5,
  },
  listItemIcon: {
    minWidth: 0,
    justifyContent: 'center',
  },
  mainContent: {
    flexGrow: 1,
    p: 3,
  },
  username: {
    color: (theme: any) => theme.otherColors.white,
  },
  userIcon: {
    mr: 1,
    color: (theme: any) => theme.otherColors.white,
  },
};

interface DrawerNavigationLinkProps {
  Icon: any;
  description: string;
  link: string;
  open: boolean;
}

const DrawerNavigationLink: React.FC<DrawerNavigationLinkProps> = ({ Icon, description, link, open }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isSelected = link === pathname;

  const handleLinkPress = useCallback(() => {
    navigate(link);
  }, [navigate]);

  const generatedStyles = useMemo(
    () => ({
      listItemButton: {
        justifyContent: open ? 'initial' : 'center',
      },
      listItemIcon: {
        mr: open ? 3 : 'auto',
      },
      listItemText: {
        opacity: open ? 1 : 0,
      },
    }),
    [open]
  );

  return (
    <ListItem selected={link === pathname} key={description} disablePadding sx={styles.listItem}>
      <ListItemButton
        onClick={handleLinkPress}
        sx={{
          ...styles.listItemButton,
          ...generatedStyles.listItemButton,
        }}>
        <ListItemIcon
          sx={{
            color: isSelected ? theme.palette.primary.main : 'gray',
            ...styles.listItemIcon,
            ...generatedStyles.listItemIcon,
          }}>
          <Icon />
        </ListItemIcon>
        <ListItemText
          primary={description}
          sx={{
            color: isSelected ? theme.palette.primary.main : 'gray',
            ...generatedStyles.listItemText,
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};

const MiniDrawer: React.FC<MiniDrawerProps> = ({ children }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const handleDrawerOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <Grid container sx={{ height: '100%' }}>
            <Grid item xs={11} display="flex">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 5,
                  ...(open && { display: 'none' }),
                }}
                size="large">
                <MenuIcon sx={{ color: 'gray' }} />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose} size="large">
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {ADMIN_MENU_ITEMS.map(({ Icon, description, link, divider }) => (
            <>
              <DrawerNavigationLink key={link} Icon={Icon} description={description} link={link} open={open} />
              {divider && <Divider />}
            </>
          ))}
        </List>
      </Drawer>
      <Box component="main" sx={styles.mainContent}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
};

export default MiniDrawer;
