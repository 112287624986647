import React from 'react';
import { Box, useTheme } from '@mui/material';
import { ReactComponent as ArrowIcon } from 'assets/svg/table-arrow.svg';
import { OrderBy } from 'components/DashboardTable/DashboardTable';

export interface SortArrowProps {
  value?: OrderBy;
}

const SortArrow: React.FC<SortArrowProps> = ({ value }) => {
  const theme = useTheme();

  return (
    <Box sx={styles}>
      <ArrowIcon
        style={{
          transform: value === -1 ? 'rotate(180deg)' : 'none',
          color: theme.otherColors.darkGray,
          transition: 'background-color 0.2s ease',
        }}
      />
    </Box>
  );
};

const styles = {
  ml: '4px',
};

export default SortArrow;
