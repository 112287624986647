import { object as yupObject, string as yupString, boolean as yupBoolean } from 'yup';
import { Permission } from 'models/permission';

export enum NewUserFormFields {
  name = 'name',
  code = 'code',
  email = 'email',
  isAdmin = 'isAdmin',
}

export type NewUserFormValues = {
  [NewUserFormFields.name]: string;
  [NewUserFormFields.email]: string;
  [NewUserFormFields.code]?: string | null;
  [NewUserFormFields.isAdmin]?: boolean;
};

export const VALIDATION_SCHEMA = yupObject().shape({
  [NewUserFormFields.name]: yupString().trim().required('errors.required'),
  [NewUserFormFields.email]: yupString().trim().email().required('errors.required'),
  [NewUserFormFields.code]: yupString().trim().nullable().optional(),
  [NewUserFormFields.isAdmin]: yupBoolean(),
});

const getPermissionsSchema = (permissions: Permission[]) => {
  const permissionsValidationRules = permissions.reduce((acc: any, permission) => {
    acc[permission._id] = yupBoolean();
    return acc;
  }, {});

  return yupObject().shape({ ...permissionsValidationRules });
};
export const getNewUserFormSchema = (permissions: Permission[]) => {
  const permissionsSchema = getPermissionsSchema(permissions);
  return VALIDATION_SCHEMA.concat(permissionsSchema);
};
