import { useCallback, useState } from 'react';

import { usePlatform } from 'hooks/usePlatform';
import { adRules } from 'constants/endpoints';
import ApiClient from 'services/apiClient';
import { Condition } from 'models/adRule';

export interface CreateAdRulesData {
  name: string;
  level: string;
  advertiserIds: string[];
  conditions: {
    [key: string]: Condition;
  };
  action: string;
  timeMode: string;
  payload?: {
    limit?: number;
    updateType?: string;
    updateValue?: number;
  };
}

export const useCreateAdRulesRequest = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const platform = usePlatform(true);

  const createAdRules = useCallback(
    async ({ name, level, conditions, advertiserIds, payload, timeMode, action }: CreateAdRulesData) => {
      setLoading(true);
      const res = await ApiClient.POST(adRules, {
        body: {
          name,
          level,
          platform,
          conditions,
          advertiserIds,
          action,
          timeMode,
          payload,
        },
      });
      setLoading(false);
      return res;
    },
    []
  );

  return {
    createAdRules,
    loading,
  };
};
