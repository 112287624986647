import { number as yupNumber, object as yupObject } from 'yup';

export enum TiktokGroupSettingsFormFields {
  rate = 'rate',
  budget = 'budget',
}

export type TiktokGroupSettingsFormValues = {
  [TiktokGroupSettingsFormFields.rate]: number;
  [TiktokGroupSettingsFormFields.budget]: number;
};

export const VALIDATION_SCHEMA = yupObject().shape({
  [TiktokGroupSettingsFormFields.rate]: yupNumber().required('errors.required'),
  [TiktokGroupSettingsFormFields.budget]: yupNumber().required('errors.required'),
});
