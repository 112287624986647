import { Platforms } from 'constants/platforms';
import GoogleAdCampaignSettings from './GoogleAdCampaignSettings';
import YandexAdCampaignSettings from './YandexAdCampaignSettings';

const getAdCampaignSettings = (platform: Platforms) => {
  switch (platform) {
    case Platforms.yandex:
      return YandexAdCampaignSettings;
    case Platforms.google:
      return GoogleAdCampaignSettings;
    default:
      return null;
  }
};

export default getAdCampaignSettings;
