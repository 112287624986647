import i18n from 'i18n';
import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';

import { History, HistoryActions } from 'models/history';
import { Platforms } from 'constants/platforms';
import { styles } from './styles';

const getSharedTranslateData = ({ entity, entityName }: History) => ({
  entity: i18n.t(`notifications.entities.${entityName}`),
  entityName: entity?.name,
});

export const renderNotificationMessage = (notification: History, platform: Platforms) => {
  const { initiator, action } = notification;
  const translateData = getSharedTranslateData(notification);

  switch (action) {
    case HistoryActions.AD_OFF_BY_AUTO_RULE:
    case HistoryActions.GROUP_OFF_BY_AUTO_RULE:
    case HistoryActions.CAMPAIGN_OFF_BY_AUTO_RULE:
      return (
        <Typography>
          <Trans key="notifications.messages.offByAutoRule">
            {translateData.entity} <i>{translateData.entityName}</i> выключена по автоправилу{' '}
            <Link to={`/${platform}/ad-rules`} style={styles.link}>
              {initiator?.name}
            </Link>
          </Trans>
        </Typography>
      );
    case HistoryActions.GROUP_OFF_BY_SCHEDULED:
    case HistoryActions.AD_OFF_BY_SCHEDULED:
    case HistoryActions.CAMPAIGN_OFF_BY_SCHEDULED:
      return (
        <Typography>
          <Trans
            key="notifications.messages.offByScheduled"
            values={translateData}
            defaults="{{entity}} <i>{{entityName}}</i> выключена отложенной операцией"
          />
        </Typography>
      );
    case HistoryActions.AD_ON_BY_SCHEDULED:
    case HistoryActions.CAMPAIGN_ON_BY_SCHEDULED:
    case HistoryActions.GROUP_ON_BY_SCHEDULED:
      return (
        <Typography>
          <Trans
            key="notifications.messages.onByScheduled"
            defaults="{{entity}} <i>{{entityName}}</i> включена отложенной операцией"
            values={translateData}
          />
        </Typography>
      );
    case HistoryActions.CAMPAIGN_BID_UPDATE_BY_AUTO_RULE:
    case HistoryActions.GROUP_BID_UPDATE_BY_AUTO_RULE:
    case HistoryActions.AD_BID_UPDATE_BY_AUTO_RULE:
      return (
        <Typography>
          <Trans
            key="notifications.messages.updateBidByAutoRule"
            values={translateData}
            defaults="{{entity}} <i>{{entityName}}</i> обновлена ставка по автоправилу"
          />
        </Typography>
      );
    default:
      return '';
  }
};
