import { useCallback, useState } from 'react';

import ApiClient from 'services/apiClient';
import { usePlatform } from 'hooks/usePlatform';
import { changeCampaignBidAndBudget } from 'constants/endpoints';

export const useUpdateCampaignBudgetAndBid = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const platform = usePlatform();

  const updateCampaignBudgetAndBid = useCallback(
    async (data: { id: string; data: { budget?: number; bid?: number } }) => {
      try {
        setLoading(true);
        const { id, data: body } = data;
        const res = await ApiClient.PUT(changeCampaignBidAndBudget(id, platform), { body });
        return res;
      } catch (e) {
        console.log((e as any).statusText);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return {
    updateCampaignBudgetAndBid,
    loading,
  };
};
