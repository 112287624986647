import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';

import CommonTextField from 'components/Common/CommonTextField';
import CommonCheckbox from 'components/Common/CommonCheckbox';
import LoadingButton from 'components/Common/LoadingButton';
import { Permission } from 'models/permission';
import { User } from 'models/user';

import { useNewUserForm } from './hooks/useNewUserForm';
import { NewUserFormFields } from './form';
import { useTranslation } from 'react-i18next';

interface NewUserFormProps {
  user: User | null;
  onCancel: () => void;
  onSuccess: () => void;
  onCreate: (user: User) => void;
  onSuccessPasswordReset: (user: User) => void;
  refetch: () => void;
  permissions: Permission[];
}

const NewUserForm: React.FC<NewUserFormProps> = ({
  user,
  onCancel,
  onSuccess,
  onCreate,
  onSuccessPasswordReset,
  refetch,
  permissions,
}) => {
  const { t } = useTranslation();
  const { onSubmit, control, isLoading, onGenerateNewPassword, permissionsByDashboard, operations } = useNewUserForm({
    onSuccessPasswordReset,
    user,
    onSuccess,
    onCreate,
    refetch,
    permissions,
  });

  return (
    <form onSubmit={onSubmit} style={{ padding: '16px', minWidth: 500 }}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h4">{user ? t('admin.users.editUser') : t('admin.users.createUser')}</Typography>
        </Grid>
      </Grid>
      <Grid container flexDirection={'column'} alignItems={'flex-start'} sx={{ marginTop: 2, marginBottom: 2 }}>
        <CommonTextField label={t('common.name')} variant="outlined" name={NewUserFormFields.name} control={control} />
        <CommonTextField
          label="Email"
          variant="outlined"
          name={NewUserFormFields.email}
          control={control}
          sx={{ mt: 2 }}
        />
        <CommonTextField
          label="Code"
          variant="outlined"
          name={NewUserFormFields.code}
          control={control}
          sx={{ mt: 2 }}
        />
        <Grid container>
          <Typography variant="h4" mt={2}>
            {t('admin.users.permissions')}
          </Typography>
          <Grid container flexDirection="row" mt={2}>
            <Grid item xs={2}></Grid>
            {operations.map((operation) => (
              <Grid item xs={3} display="flex" justifyContent="center" key={operation}>
                <Typography variant="h5">{t(`permissions.operations.${operation}`)}</Typography>
              </Grid>
            ))}
          </Grid>
          {Object.entries(permissionsByDashboard).map(([key, value]) => (
            <Grid container flexDirection="row" alignItems="center">
              <Grid item xs={2}>
                <Typography variant="h5">{t(`permissions.platforms.${key}`)}</Typography>
              </Grid>
              {value.map(({ _id }) => (
                <Grid item xs={3} display="flex" justifyContent="center" key={_id}>
                  <CommonCheckbox name={_id} control={control} />
                </Grid>
              ))}
            </Grid>
          ))}
        </Grid>
        <Grid item mt={2}>
          <CommonCheckbox label={t('common.admin')} name={NewUserFormFields.isAdmin} control={control} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4}>
          {user && (
            <Button type="button" onClick={onGenerateNewPassword} sx={{ alignSelf: 'flex-start' }}>
              {t('admin.users.resetPassword')}
            </Button>
          )}
        </Grid>
        <Grid item xs={8}>
          <Box display="flex" justifyContent="flex-end">
            <Button type="button" onClick={onCancel}>
              {t('common.cancel')}
            </Button>
            <LoadingButton type="submit" variant="contained" isLoading={isLoading}>
              {t('common.save')}
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default NewUserForm;
