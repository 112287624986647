import { object as yupObject, string as yupString, array as yupArray, boolean as yupBoolean } from 'yup';

export enum NewAdRuleFormFields {
  name = 'name',
  level = 'level',
  conditions = 'conditions',
  action = 'action',
  timeMode = 'timeMode',
  isActive = 'isActive',
  updateBidValue = 'updateBidValue',
  updateBidType = 'updateBidType',
}

export type NewAdRuleFormValues = {
  [NewAdRuleFormFields.name]: string;
  [NewAdRuleFormFields.level]: string;
  [NewAdRuleFormFields.isActive]: boolean;
  [NewAdRuleFormFields.conditions]: {
    value: string | number;
    selected: boolean;
    operation: string;
    condition: string;
  }[];
  [NewAdRuleFormFields.action]: string;
  [NewAdRuleFormFields.timeMode]: string;
  [NewAdRuleFormFields.updateBidValue]: string | number;
  [NewAdRuleFormFields.updateBidType]: string;
};

export const VALIDATION_SCHEMA = yupObject().shape({
  [NewAdRuleFormFields.name]: yupString().trim().required('errors.requiredShort'),
  [NewAdRuleFormFields.level]: yupString().trim().required('errors.requiredShort'),
  [NewAdRuleFormFields.conditions]: yupArray(
    yupObject().shape({
      selected: yupBoolean(),
      operation: yupString().when('selected', {
        is: true,
        then: yupString().required('errors.requiredShort'),
      }),
      value: yupString().when('selected', {
        is: true,
        then: yupString().required('errors.requiredShort'),
      }),
    })
  ),
  [NewAdRuleFormFields.action]: yupString().trim().required('errors.requiredShort'),
  [NewAdRuleFormFields.timeMode]: yupString().trim().required('errors.requiredShort'),
  [NewAdRuleFormFields.isActive]: yupBoolean(),
  [NewAdRuleFormFields.updateBidValue]: yupString().trim(),
  [NewAdRuleFormFields.updateBidType]: yupString().trim(),
});
