import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { GoogleGroupSettingsFormFields, GoogleGroupSettingsFormValues, VALIDATION_SCHEMA } from './form';
import { useUpdateGroupBudgetAndRate } from '../../hooks/useUpdateGroupBudgetAndRate';
import { StatsItem } from 'models/stats';

interface Props {
  data: StatsItem;
  close: () => void;
  refetchData: () => void;
}

export const useGoogleGroupSettingsForm = ({ data, close, refetchData }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { updateGroupBudgetAndRate } = useUpdateGroupBudgetAndRate();
  const defaultValues = { rate: data.bid || 0 };
  const { handleSubmit, control, watch } = useForm<GoogleGroupSettingsFormValues>({
    mode: 'onBlur',
    defaultValues,
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const currentRate = watch(GoogleGroupSettingsFormFields.rate);

  const onSubmit = handleSubmit(
    async ({ rate }) => {
      if (!data.id) return;
      setLoading(true);
      if (rate !== defaultValues.rate) {
        await updateGroupBudgetAndRate({ id: data.id, data: { bid: rate } });
        refetchData();
      }
      setLoading(false);
      close();
    },
    (e) => console.log(e)
  );

  return {
    control,
    onSubmit,
    loading,
    disabled: Number(currentRate) === defaultValues.rate,
  };
};
