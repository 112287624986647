export const specificStyles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 26px',
    paddingTop: '10px',
  },
  filterButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    boxShadow: '0px 5px 14px rgba(45, 55, 72, 0.1)',
    borderRadius: 0,
  },
  drawer: {
    filterAccountsDrawer: {
      height: '80lvh',
      borderRadius: '20px 20px 0px 0px',
    },
  },
  footer: (theme: any) => ({
    bottom: '32px',
    backgroundColor: theme.otherColors.lightBlue,
    padding: '0 26px',
    height: '102px',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    margin: 0,
    borderBottomRightRadius: '4px',
    borderBottomLeftRadius: '4px',
  }),
};
