import { AdLevels } from 'constants/levels';
import { Platforms } from 'constants/platforms';

export enum Conditions {
  conversions = 'conversions',
  spend = 'spend',
  cpa = 'cpa',
}

export interface Condition {
  operation: string;
  value: number;
}

export interface AdRule {
  _id: string;
  name: string;
  conditions?: {
    [key: string]: Condition;
  };
  level: AdLevels;
  user: {
    _id: string;
    name: string;
  };
  isActive: boolean;
  platform: Platforms;
  advertiserIds: string[];
  action: string;
  payload?: {
    limit?: number;
    updateType?: string;
    updateValue?: number;
  };
  timeMode: string;
}
