import { useCallback, useState } from 'react';

import ApiClient from 'services/apiClient';
import { userById } from 'constants/endpoints';
import { NewUserFormValues } from '../form';

export const useEditUser = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const editUser = useCallback(async (data: { id: string; data: NewUserFormValues }, refetch?: () => void) => {
    try {
      setLoading(true);
      const { id, data: body } = data;
      const res = await ApiClient.PUT(userById(id), { body });
      refetch && refetch();
      return res;
    } catch (e) {
      console.log((e as any).statusText);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    editUser,
    loading,
  };
};
