import { useEffect, useState } from 'react';

import { advertisers as advertisersEndpoint } from 'constants/endpoints';
import { Advertiser } from 'models/advertiser';
import ApiClient from 'services/apiClient';
import { usePlatform } from 'hooks/usePlatform';

export const useAdvertisers = () => {
  const platform = usePlatform();
  const [advertisers, setAdvertisers] = useState<Advertiser[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const loadAdvertisers = async () => {
      try {
        setLoading(true)
        const res = await ApiClient.GET(advertisersEndpoint(platform));
        setAdvertisers(res)
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
    }
    loadAdvertisers()
  }, [platform])

  return {
    advertisers,
    loading,
  }
}