import React, { useCallback } from 'react';
import { TableRow as TableRowMui, TableCell as TableCellMui, IconButton, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { useCrudTableContext } from './CrudTableContext';
import { Column, Params } from './models';
import TableCell from './TableCell';

const styles = {
  actionsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
};

interface TableRowProps<T> {
  isActionsDisabled?: (row: T) => boolean;
  columns: Column<T>[];
  row: T;
}

const TableRow = <T extends Params<T>>({ columns, row, isActionsDisabled }: TableRowProps<T>) => {
  const { onEdit, onDelete } = useCrudTableContext();

  const handleEdit = useCallback(() => onEdit(row), [row]);
  const handleDelete = useCallback(() => onDelete(row), [row]);
  const isDisabled = isActionsDisabled ? isActionsDisabled(row) : false;

  return (
    <TableRowMui>
      <TableCellMui
        sx={{
          borderTopWidth: isDisabled ? 0 : 1,
          borderBottomWidth: isDisabled ? 0 : 1,
        }}>
        {!isDisabled && (
          <Box sx={styles.actionsContainer}>
            <IconButton onClick={handleEdit}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={handleDelete}>
              <DeleteIcon />
            </IconButton>
          </Box>
        )}
      </TableCellMui>
      {columns.map((column) => (
        <TableCell key={column.title} row={row} column={column} />
      ))}
    </TableRowMui>
  );
};

export default TableRow;
