import _get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, Grid, TextField, Typography, useTheme } from '@mui/material';

import Loader from 'components/Common/Loader';
import { useVariablesRequest } from './useVariablesRequest';
import { styles } from './styles';
import VariablesForm from './VariablesForm';

const Variables = () => {
  const { t } = useTranslation();

  const { sendNewSettings, loading, data, saving } = useVariablesRequest();
  if (loading) {
    return <Loader />;
  }

  return (
    <Box>
      <Grid item>
        <Typography variant="h1">{t('admin.table.title')}</Typography>
      </Grid>
      <Card sx={styles.card}>
        <VariablesForm data={data} onSubmit={sendNewSettings} saving={saving} />
      </Card>
    </Box>
  );
};

export default Variables;
