import React from 'react';
import { Button, Dialog, Drawer, Grid, IconButton, useTheme } from '@mui/material';

import { ReactComponent as FilterAccountsIcon } from 'assets/svg/filterAccounts.svg';
import { useDashboardFilterContext } from 'context/DashboardFilter';
import CountrySelect from 'components/CountrySelect';
import { useDialog } from 'hooks/useDialog';
import { formatDate } from 'utils/date';
import AdvertiserFilter from 'components/AdvertiserFilter/AdvertiserFilter';

import FilterModal from './FilterModal';

const styles = {
  filterButtonContainer: {
    marginLeft: '16px',
  },
  accountsButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    marginLeft: '16px',
    boxShadow: '0px 5px 14px rgba(45, 55, 72, 0.1)',
    borderRadius: 0,
  },
  filterAccountsDrawer: {
    height: '80lvh',
    borderRadius: '20px 20px 0px 0px',
  },
  filterButton: (theme: any) => ({
    width: '100%',
    height: '40px',
    backgroundColor: '#fff',
    fontSize: '12px',
    color: theme.palette.primary.main,
    boxShadow: '0px 5px 14px rgba(45, 55, 72, 0.1)',
    '&:hover': {
      backgroundColor: '#fff',
      color: theme.palette.primary.main,
      boxShadow: '0px 5px 14px rgba(45, 55, 72, 0.1)',
    },
  }),
  container: {
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  },
  select: {
    height: '40px',
    boxSizing: 'border-box',
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: '12px',
    '& .MuiSelect-icon': {
      fill: 'transparent',
    },
    '& .MuiSelect-outlined': {
      color: (theme: any) => theme.palette.primary.main,
      border: 'none',
      boxShadow: '0px 5px 14px rgba(45, 55, 72, 0.1)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '40px',
      boxSizing: 'border-box',
    },
  },
};

const MobileDashboardHeader: React.FC = () => {
  const theme = useTheme();
  const { isDialogOpen, closeDialog, openDialog } = useDialog();
  const { country, setCountry, currentDate, onDateChange } = useDashboardFilterContext();

  const [accountSelectOpen, setAccountSelectOpen] = React.useState(false);
  const closeAccountSelectDrawer = () => setAccountSelectOpen(false);
  const openAccountSelectDrawer = () => setAccountSelectOpen(true);

  return (
    <Grid container sx={styles.container}>
      <Grid item xs={4}>
        <CountrySelect onChange={setCountry} value={country} sx={styles.select} />
      </Grid>
      <Grid item sx={styles.filterButtonContainer} xs={6}>
        <Button sx={styles.filterButton} onClick={openDialog}>
          {`${formatDate(currentDate.startDate)}-${formatDate(currentDate.endDate)}`}
        </Button>
      </Grid>
      <Grid item xs={2}>
        <IconButton onClick={openAccountSelectDrawer} sx={styles.accountsButton}>
          <FilterAccountsIcon fill={theme.palette.primary.main} />
        </IconButton>
      </Grid>
      <Dialog open={isDialogOpen}>
        <FilterModal close={closeDialog} currentDate={currentDate} handleDateChange={onDateChange} />
      </Dialog>
      <Drawer
        open={accountSelectOpen}
        onClose={closeAccountSelectDrawer}
        anchor="bottom"
        PaperProps={{
          sx: styles.filterAccountsDrawer,
        }}>
        <AdvertiserFilter close={closeAccountSelectDrawer} />
      </Drawer>
    </Grid>
  );
};

export default MobileDashboardHeader;
