import { useMediaQuery as useMediaQueryMui } from '@mui/material';

const MOBILE_MAX_WIDTH = 600;
const DESKTOP_MIN_WIDTH = 601;

export const useMediaQuery = () => {
  const isMobile = useMediaQueryMui(`(max-width: ${MOBILE_MAX_WIDTH}px)`);
  const isDesktop = useMediaQueryMui(`(min-width: ${DESKTOP_MIN_WIDTH}px)`);

  return { isDesktop, isMobile };
};
