import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Card } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as LogoIcon } from 'assets/svg/logo.svg';
import { useAuth } from 'context/Auth';
import { styles } from '../styles';

const Login = () => {
  const [error, setError] = useState<string>('');
  const { login } = useAuth();
  const { t } = useTranslation();
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      setError('');
      await login(e.target[0].value, e.target[2].value);
    } catch (err) {
      setError(t('errors.loginError'));
    }
  };

  return (
    <Box sx={styles.wrapper}>
      <form
        onSubmit={handleSubmit}
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Card sx={styles.card}>
          <LogoIcon />
          <Typography variant="h4" sx={styles.loginMessage}>
            {t('login.enter')}
          </Typography>
          <Box sx={styles.inputContainer}>
            <Typography variant="h5" sx={styles.inputLabel}>
              {t('login.login')}
            </Typography>
            <TextField sx={styles.input} name="login" placeholder={t('login.enterLogin')} variant="outlined" required />
          </Box>
          <Box sx={styles.inputContainer}>
            <Typography variant="h5" sx={styles.inputLabel}>
              {t('login.password')}
            </Typography>
            <TextField
              sx={styles.input}
              name="password"
              type="password"
              placeholder={t('login.enterPassword')}
              variant="outlined"
              required
            />
          </Box>
          <Button variant="contained" color="primary" type="submit" sx={styles.submitButton}>
            {t('login.enter')}
          </Button>
          {!!error && <Typography color="error">{error}</Typography>}
        </Card>
      </form>
    </Box>
  );
};

export default Login;
