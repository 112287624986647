import { useCallback, useState } from 'react';

import { pendingOperationById } from 'constants/endpoints';
import ApiClient from 'services/apiClient';

export const useDeletePendingOperationRequest = (refetch: () => void) => {
  const [loading, setLoading] = useState<boolean>(false);

  const deletePendingOperation = useCallback(
    async (id: string) => {
      try {
        setLoading(true);
        await ApiClient.DELETE(pendingOperationById(id));
        await refetch();
      } catch (e) {
        console.log((e as any).statusText);
      } finally {
        setLoading(false);
      }
    },
    [refetch]
  );

  return {
    loading,
    deletePendingOperation,
  };
};
