import { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { AdLevels } from 'constants/levels';
import { AdRule } from 'models/adRule';
import { NewAdRuleFormFields, NewAdRuleFormValues, VALIDATION_SCHEMA } from './form';
import { CreateAdRulesData, useCreateAdRulesRequest } from '../hooks/useNewAdRule';
import { useUpdateAdRuleRequest } from '../hooks/useUpdateAdRule';
import { CONDITIONS } from './constants';

interface AdRuleFormProps {
  close: () => void;
  refetch: () => void;
  adRule?: AdRule;
}

const getDefaultValues = (adRule?: AdRule): NewAdRuleFormValues => {
  const defaultConditions = CONDITIONS.map((condition) => ({
    condition,
    selected: false,
    operation: '',
    value: '',
  }));

  if (adRule) {
    const { name, level, isActive, conditions, payload, timeMode, action } = adRule;
    return {
      name,
      level,
      isActive,
      timeMode,
      action,
      updateBidValue: payload?.updateValue || '',
      updateBidType: payload?.updateType || '',
      conditions: conditions
        ? CONDITIONS.map((condition) => {
            const defaultCondition = conditions[condition];
            return {
              condition,
              selected: !!defaultCondition,
              operation: defaultCondition?.operation || '',
              value: defaultCondition?.value || '',
            };
          })
        : defaultConditions,
    };
  }
  return {
    name: '',
    level: AdLevels.group,
    isActive: false,
    conditions: defaultConditions,
    timeMode: 'now',
    action: 'updateBid',
    updateBidValue: '',
    updateBidType: '',
  };
};

export const useNewAdRuleForm = ({ close, refetch, adRule }: AdRuleFormProps) => {
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>(adRule?.advertiserIds || []);
  const [loading, setLoading] = useState<boolean>(false);
  const { createAdRules } = useCreateAdRulesRequest();
  const { updateAdRule } = useUpdateAdRuleRequest();

  const {
    handleSubmit,
    control,
    // TODO add NewAdRuleFormValues type, fix ts error
    watch,
  } = useForm<NewAdRuleFormValues>({
    mode: 'onBlur',
    defaultValues: getDefaultValues(adRule),
    resolver: yupResolver(VALIDATION_SCHEMA) as any,
  });

  const values = watch();
  const conditionSelected = values.conditions.some(({ selected }: { selected: boolean }) => selected);

  const { fields: conditionFields } = useFieldArray({
    control,
    name: NewAdRuleFormFields.conditions,
  });

  const onSubmit = handleSubmit(async ({ name, level, conditions }: NewAdRuleFormValues) => {
    try {
      setLoading(true);
      const body: CreateAdRulesData = {
        name,
        level,
        conditions: {},
        advertiserIds: selectedAccounts,
        action: values.action,
        timeMode: values.timeMode,
        payload: {
          updateType: values.updateBidType,
          updateValue: Number(values.updateBidValue),
        },
      };

      conditions.forEach(({ condition, operation, selected, value }) => {
        if (!selected) return;
        body.conditions[condition] = { operation, value: Number(value) };
      });

      if (adRule) {
        await updateAdRule(adRule._id, body);
      } else {
        await createAdRules(body);
      }
      await refetch();
      setLoading(false);
      close();
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  });

  return {
    control,
    onSubmit,
    conditionFields,
    loading,
    values,
    selectedAccounts,
    setSelectedAccounts,
    disabled: selectedAccounts.length === 0 || !conditionSelected,
  };
};
