import React from 'react';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { usePendingOperations } from 'context/PendingOperations/PendingOperations';
import { useDialog } from 'hooks/useDialog';

import NewPendingOperationsDialog from './NewPendngOperationsDialog';

const styles = {
  button: {
    mr: 2,
  },
  resetButton: {
    color: (theme: any) => theme.otherColors.lightGray,
  },
  footer: {
    borderTop: (theme: any) => `1px solid ${theme.otherColors.lightGray}`,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '70px',
    position: 'absolute',
    background: 'white',
    padding: '0 30px',
    bottom: 0,
    left: 0,
  },
};
const PendingOperationsFooter = () => {
  const { t } = useTranslation();
  const { isDialogOpen, openDialog, closeDialog } = useDialog();
  const { selectedCampaigns, resetSelectedCampaigns } = usePendingOperations();

  return selectedCampaigns.length > 0 ? (
    <>
      {isDialogOpen && <NewPendingOperationsDialog close={closeDialog} />}
      <Box sx={styles.footer}>
        <Button variant="contained" onClick={openDialog} sx={styles.button}>
          {t('dashboard.pendingOperation')} ({selectedCampaigns.length})
        </Button>
        <Button variant="text" onClick={resetSelectedCampaigns}>
          {t('dashboard.reset')}
        </Button>
      </Box>
    </>
  ) : null;
};

export default PendingOperationsFooter;
