import { number as yupNumber, object as yupObject } from 'yup';

export enum FacebookGroupSettingsFormFields {
  budget = 'budget',
  lifetimeBudget = 'lifetimeBudget',
}

export type FacebookGroupSettingsFormValues = {
  [FacebookGroupSettingsFormFields.budget]: number;
  [FacebookGroupSettingsFormFields.lifetimeBudget]: number;
};

export const VALIDATION_SCHEMA = yupObject().shape({
  [FacebookGroupSettingsFormFields.budget]: yupNumber().required('errors.required'),
  [FacebookGroupSettingsFormFields.lifetimeBudget]: yupNumber().required('errors.required'),
});
