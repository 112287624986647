import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { YandexAdCampaignSettingsFormValues, VALIDATION_SCHEMA } from './form';
import { StatsItem } from 'models/stats';
import { useUpdateCampaignBudgetAndBid } from '../../hooks/useUpdateCampaignBudgetAndBid';

interface Props {
  data: StatsItem;
  close: () => void;
  refetchData: () => void;
}

export const useYandexAdCampaignSettingsForm = ({ data, refetchData, close }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { updateCampaignBudgetAndBid } = useUpdateCampaignBudgetAndBid();

  const { handleSubmit, control } = useForm<YandexAdCampaignSettingsFormValues>({
    mode: 'onBlur',
    defaultValues: {
      bid: data.bid,
    },
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const onSubmit = handleSubmit(
    async ({ bid }) => {
      setLoading(true);
      if (bid !== data.budget) {
        await updateCampaignBudgetAndBid({ id: data.id, data: { bid } });
        refetchData();
      }
      setLoading(false);
      refetchData();
      close();
    },
    (e) => console.log(e)
  );

  return {
    control,
    onSubmit,
    loading,
  };
};
