import { useCallback, useState } from 'react';

import ApiClient from 'services/apiClient';
import { usePlatform } from 'hooks/usePlatform';
import { changeGroupBidAndBudget } from 'constants/endpoints';

export const useUpdateGroupBudgetAndRate = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const platform = usePlatform();

  const updateGroupBudgetAndRate = useCallback(
    async (data: {
      id: string;
      data: {
        budget?: number;
        lifetimeBudget?: number;
        bid?: number;
      };
    }) => {
      try {
        setLoading(true);
        const { id, data: body } = data;
        const res = await ApiClient.PUT(changeGroupBidAndBudget(id, platform), { body });
        return res;
      } catch (e) {
        console.log((e as any).statusText);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return {
    updateGroupBudgetAndRate,
    loading,
  };
};
