export const styles = {
  title: (theme: any) => ({
    fontSize: '32px',
    mt: '0',
    mb: '0',
  }),
  header: (theme: any) => ({
    justifyContent: 'space-between',
    mt: '30px',
    mb: '15px',
    [theme.breakpoints.down('md')]: {
      fontSize: '20px',
      mt: '15px',
      mb: '8px',
    },
  }),
  container: (theme: any) => ({
    padding: '0 30px',
    [theme.breakpoints.down('md')]: {
      padding: '0 16px',
    },
  }),
  loader: {
    mt: '30px',
  },
  select: {
    '.MuiOutlinedInput-notchedOutline': { border: 0 },
  },
  tab: {
    cursor: 'pointer',
    color: (theme: any) => theme.otherColors.darkGray,
    p: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginLeft: 2,
    '&:first-child': {
      marginLeft: 0,
    },
  },
  tabs: {
    display: 'flex',
  },
  card: (theme: any) => ({
    padding: '30px 32px',
    pt: 0,
    [theme.breakpoints.down('md')]: {
      padding: '24px 16px',
      pt: 0,
    },
  }),
  tabContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: (theme: any) => ({
    textAlign: 'left',
    fontSize: 14,
    [theme.breakpoints.down('md')]: {
      fontSize: 10,
    },
  }),
  value: (theme: any) => ({
    fontWeight: 500,
    fontSize: 20,
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  }),
};
