import React, { useMemo, useState } from 'react';

import { SortField, useDashboardFilterContext } from 'context/DashboardFilter';
import ApiClient from 'services/apiClient';
import { Countries } from 'components/CountrySelect/models';
import { AdLevels } from 'constants/levels';
import { Platforms } from 'constants/platforms';
import { getSortFieldName } from './utils';
import _isempty from 'lodash.isempty';
import offerFilter from './DashboardHeader/OfferFilter';
import { useTranslation } from 'react-i18next';
import { RawStatsItem, StatsItem } from 'models/stats';

interface Props {
  show?: boolean;
  level: AdLevels;
  endpoint: string;
  setUpdatingStatuses: (statuses: string[]) => void;
  platform: Platforms;
}

const useDashboardData = ({ show, level, endpoint, setUpdatingStatuses, platform }: Props) => {
  const { t } = useTranslation();
  const { offerNameFilter, setOfferFilterOptions, activeRows, currentDate, country, sort, setDashboardError } =
    useDashboardFilterContext();
  const [statistics, setStatistics] = React.useState<StatsItem[]>([]);
  const [isLoading, setLoading] = React.useState(false);
  const [updateIndex, setUpdateIndex] = React.useState<number>(0);

  const sortData = useMemo(
    () => (sort?.order ? { field: getSortFieldName(sort.field), order: sort.order } : null),
    [sort]
  );

  React.useEffect(() => {
    if (!show) return;
    const loadItems = async () => {
      if (level === AdLevels.advertiser && !activeRows?.length) return;
      try {
        setLoading(true);
        setDashboardError('');
        const body = getBody({
          level,
          date: currentDate,
          country,
          activeRows,
          platform,
          sort: sortData,
          offerName: offerNameFilter,
        });

        const stats: RawStatsItem[] =
          level === AdLevels.advertiser
            ? await ApiClient.POST(endpoint, { body })
            : await ApiClient.GET(endpoint, { params: body });

        setStatistics(stats.map(({ metrics, ...item }) => ({ ...metrics, ...item })));
        if (level === AdLevels.advertiser) {
          const overallStats = stats.find(({ id }) => id === 'overall');
          const offerNames = overallStats?.offerNames;
          offerNames && setOfferFilterOptions(offerNames.sort((a, b) => a.localeCompare(b)));
        }
        setLoading(false);
        setUpdatingStatuses([]);
      } catch (error) {
        setLoading(false);
        setStatistics([]);
        setDashboardError(t('dashboard.errors.fetchDataError'));
        console.error(error);
      }
    };
    loadItems();
    const interval = setInterval(loadItems, 60 * 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, updateIndex, currentDate, country, sortData, offerNameFilter]);

  const forceUpdate = () => {
    setUpdateIndex(updateIndex + 1);
  };

  return {
    statistics,
    isLoading,
    forceUpdate,
  };
};

export default useDashboardData;

interface BodyData {
  level: AdLevels;
  date?: { startDate: Date; endDate: Date };
  country?: Countries | '';
  activeRows?: string[];
  platform: Platforms;
  sort: SortField | null;
  offerName: string;
}

const getBody = ({ level, date, country, activeRows, platform, sort, offerName }: BodyData) => {
  const { startDate, endDate } = date || {};
  switch (level) {
    case AdLevels.advertiser:
      return {
        platform,
        sort,
        ids: activeRows,
        startDate: startDate ? startDate.toISOString() : undefined,
        endDate: endDate ? endDate.toISOString() : undefined,
        offerName: offerName === 'all' ? '' : offerName,
        ...(country && country !== Countries.all ? { country } : null),
      };
    default:
      return {
        startDate: startDate ? startDate.toISOString() : undefined,
        endDate: endDate ? endDate.toISOString() : undefined,
        sort: JSON.stringify(sort),
        ...(country && country !== Countries.all ? { country } : null),
      };
  }
};
