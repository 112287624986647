import React from 'react';

interface CrudTableContextValue<T> {
  onAdd: () => void;
  onEdit: (row: T) => void;
  onDelete: (row: T) => void;
}

interface CrudTableProviderProps extends CrudTableContextValue<any> {
  children: React.ReactNode;
}

export const CrudTableContext = React.createContext<CrudTableContextValue<any> | undefined>(undefined);

export const CrudTableProvider: React.FC<CrudTableProviderProps> = ({ children, ...actions }) => (
  <CrudTableContext.Provider value={{ ...actions }}>{children}</CrudTableContext.Provider>
);

export const useCrudTableContext = () => {
  const context = React.useContext(CrudTableContext);

  if (context === undefined) {
    throw new Error('FormContext must be used within a FormProvider');
  }
  return context;
};
