import Drawer from 'components/Admin/Drawer';
import { Outlet } from 'react-router-dom';

const AdminWrapper = () => (
  <Drawer>
    <Outlet />
  </Drawer>
);

export default AdminWrapper;
