import React from 'react';
import _get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import LaunchIcon from '@mui/icons-material/Launch';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Box, Checkbox, IconButton, TableCell, Tooltip, Typography, useTheme } from '@mui/material';

import { usePendingOperations } from 'context/PendingOperations/PendingOperations';
import { useDashboardFilterContext } from 'context/DashboardFilter';
import { ReactComponent as ArrowIcon } from 'assets/svg/arrow.svg';
import StatusCircle from 'components/StatusCircle';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { usePlatform } from 'hooks/usePlatform';
import { Platforms } from 'constants/platforms';
import { AdLevels } from 'constants/levels';
import { StatsItem } from 'models/stats';
import { customDateFormat } from 'utils/date';

import { checkLimit, formatBidValue, generateLink, getBackgroundColor, getColor, getDifference } from './utils';
import { usePriorityContext } from './Context/Priority';
import { PLATFORMS_WITH_ACCOUNT_LINK, TableColumn } from './constants';

interface Props {
  level: AdLevels;
  row: StatsItem;
  column: TableColumn;
  isStatusUpdating: boolean;
  onChangeStatus: (id: string, value: boolean) => void;
  disabled?: boolean;
  openDashboardDialog: (row: StatsItem, level: AdLevels) => void;
  hasEditPermission: boolean;
  platform: Platforms;
  refreshData: () => void;
  isExpanded: boolean;
  isPriority?: boolean;
  isModalExist: boolean;
}

const DashboardTableColumn: React.FC<Props> = ({
  level,
  onChangeStatus,
  row,
  column,
  isStatusUpdating,
  disabled,
  openDashboardDialog,
  hasEditPermission,
  refreshData,
  isExpanded,
  isPriority = false,
  isModalExist,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const platform = usePlatform();
  const { changePriority } = usePriorityContext();
  const { isMobile, isDesktop } = useMediaQuery();
  const { currentDate } = useDashboardFilterContext();
  const { toggleCampaignSelect, selectedCampaignsIds, pendingOperationEntitiesIds, openPendingOperationsDialog } =
    usePendingOperations();

  const openOptionsModal = (e: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    openDashboardDialog(row, level);
  };

  const renderArrow = () => {
    if (level === AdLevels.ad || row.id === 'overall') return null;
    return <ArrowIcon style={{ minWidth: 20, transform: isExpanded ? 'rotate(90deg)' : 'none' }} />;
  };

  const defaultCell = (
    <Tooltip title={row.id !== 'overall' ? getDifference(row.updatedAt) : ''} placement="top" arrow>
      <TableCell
        style={{
          ...column.styles,
          ...(column.levelStyles ? column.levelStyles(theme, level) : {}),
          textAlign: 'left',
          color: column.color ? column.color(_get(row, column.key)) : null,
          backgroundColor: getBackgroundColor(theme, level),
        }}
        key={column.key}
        component="td">
        {column.format ? column.format(_get(row, column.key), row) : _get(row, column.key)}
      </TableCell>
    </Tooltip>
  );
  switch (column.key) {
    case 'options':
      if (isMobile) return null;
      if (row.id === 'overall') {
        return <TableCell component="td" />;
      }
      return (
        <TableCell
          component="td"
          style={{ backgroundColor: getBackgroundColor(theme, level) }}
          sx={styles.leftCell}
          onClick={(e) => e.stopPropagation()}>
          {hasEditPermission &&
            (level === AdLevels.advertiser ? (
              <Tooltip title={t('dashboard.makePriority')}>
                <IconButton onClick={() => changePriority(row.id, !isPriority)} sx={styles.optionsButton} size="large">
                  {isPriority ? <StarBorderIcon style={{ opacity: 1 }} color={'primary'} /> : <StarBorderIcon />}
                </IconButton>
              </Tooltip>
            ) : (
              <Checkbox
                size="small"
                color="primary"
                onChange={() => toggleCampaignSelect({ id: row.id, level })}
                checked={selectedCampaignsIds.includes(row.id)}
                onClick={(e) => e.stopPropagation()}
              />
            ))}
        </TableCell>
      );
    case 'crm': {
      const sales = row.crm?.salesCount;
      const averageCheck = Number(row.crm?.averagePrice).toFixed(2);
      const leads = row.crm?.leads;
      const updatedAt = row.crm?.updatedAt ? customDateFormat(row.crm.updatedAt, 'YYYY-MM-DD HH:mm') : '';

      const tooltip = !!leads && (
        <>
          {t('dashboard.leads', { leads })}
          <br />
          {t('dashboard.sales', { sales })}
          <br />
          {t('dashboard.averageCheck', { averageCheck })}
          <br />
          {t('dashboard.lastUpdate', { updatedAt })}
        </>
      );
      const dateStart = customDateFormat(currentDate.startDate, 'YYYY-MM-DD');
      const dateEnd = customDateFormat(currentDate.endDate, 'YYYY-MM-DD');
      const spanProps = !!leads && {
        href: `https://instagram234.retailcrm.ru/orders/?filter%5BcreatedAt%5D%5Bgte%5D%5Babs%5D=${dateStart}&filter%5BcreatedAt%5D%5Blte%5D%5Babs%5D=${dateEnd}&filter%5BcustomFields%5D%5Blead_domain%5D=id%3D${row.externalId}`,
        component: 'a',
        target: '_blank',
        rel: 'noopener noreferrer',
        sx: { color: '#005eff', textDecoration: 'none' },
      };
      return (
        <Tooltip title={tooltip}>
          <TableCell
            style={{
              ...column.styles,
              ...(column.levelStyles ? column.levelStyles(theme, level) : {}),
              color: column.color ? column.color(_get(row, column.key)) : null,
              backgroundColor: getBackgroundColor(theme, level),
              textAlign: 'left',
            }}
            key={column.key}
            component="td">
            <Typography {...spanProps} fontSize={'inherit'}>
              {leads}
            </Typography>
          </TableCell>
        </Tooltip>
      );
    }
    case 'conversion': {
      return (
        <TableCell
          style={{
            ...column.styles,
            ...(column.levelStyles ? column.levelStyles(theme, level) : {}),
            color: column.color ? column.color(_get(row, column.key)) : null,
            backgroundColor: getBackgroundColor(theme, level),
            textAlign: 'left',
          }}
          key={column.key}
          component="td">
          <Typography fontSize={'inherit'}>
            {column.format ? column.format(_get(row, column.key), row) : _get(row, column.key)}
          </Typography>
        </TableCell>
      );
    }
    case 'bid':
      if (row.id === 'overall') return defaultCell;
      return (
        <TableCell
          onClick={isModalExist ? openOptionsModal : undefined}
          style={{
            ...column.styles,
            ...(column.levelStyles ? column.levelStyles(theme, level) : {}),
            color: column.color ? column.color(_get(row, column.key)) : null,
            backgroundColor: getBackgroundColor(theme, level),
            textAlign: 'left',
            whiteSpace: 'pre',
          }}
          key={column.key}
          component="td">
          {formatBidValue(row, platform, level)}
        </TableCell>
      );
    case 'cost_per_conversion':
      return (
        <Tooltip title={row.id !== 'overall' ? getDifference(row.updatedAt) : ''} placement="top" arrow>
          <TableCell
            style={{
              backgroundColor: getColor(row, column.key as any) || getBackgroundColor(theme, level),
              textAlign: 'left',
            }}
            component="td">
            {column.format?.(_get(row, column.key), row)}
          </TableCell>
        </Tooltip>
      );
    case 'name': {
      if (row.id === 'overall') return defaultCell;
      return (
        <TableCell
          style={{
            ...column.styles,
            ...(column.levelStyles ? column.levelStyles(theme, level) : {}),
            textAlign: 'left',
            color: column.color ? column.color(_get(row, column.key)) : null,
            backgroundColor: getBackgroundColor(theme, level),
            ...(isPriority && { borderLeft: '2px solid red' }),
          }}
          key={column.key}
          component="td">
          <Box sx={styles.nameColumnContainer}>
            {isDesktop && renderArrow()}
            {row.id !== 'overall' && (
              <Box sx={{ ml: level === AdLevels.ad && isDesktop ? 2.5 : 0 }} onClick={(e) => e.stopPropagation()}>
                <StatusCircle
                  data={row}
                  onChangeStatus={onChangeStatus}
                  isStatusUpdating={isStatusUpdating}
                  disabled={disabled || !hasEditPermission || level === AdLevels.advertiser}
                  refreshData={refreshData}
                  level={level}
                />
              </Box>
            )}
            <Box sx={styles.accountNameContainer}>
              <Box sx={styles.name}>
                {column.format ? column.format(_get(row, column.key), row) : _get(row, column.key)}
                {level === AdLevels.advertiser && PLATFORMS_WITH_ACCOUNT_LINK.includes(platform) && isDesktop && (
                  <IconButton
                    onClick={(e) => e.stopPropagation()}
                    component="a"
                    target="_blank"
                    href={generateLink({ account: row, platform, date: currentDate })}
                    sx={styles.nameButton}>
                    <LaunchIcon sx={styles.nameButtonIcon} />
                  </IconButton>
                )}
                {pendingOperationEntitiesIds.includes(row.id) && (
                  <IconButton
                    onClick={(e: any) => {
                      e.stopPropagation();
                      if (isMobile) return;
                      openPendingOperationsDialog();
                    }}
                    sx={styles.nameButton}>
                    <AccessTimeIcon sx={styles.nameButtonIcon} />
                  </IconButton>
                )}
              </Box>
              {row.offerNames && row.offerNames.length > 0 && (
                <Tooltip title={row.offerNames.join(', ')}>
                  <Typography sx={styles.offerName}>{`${row.offerNames.join(', ')}`}</Typography>
                </Tooltip>
              )}
            </Box>
          </Box>
        </TableCell>
      );
    }
    default:
      return defaultCell;
  }
};

export default DashboardTableColumn;

const styles = {
  nameButton: {
    display: 'inline-block',
    padding: 0,
    color: (theme: any) => theme.palette.primary.main,
    ml: '5px',
    fontSize: 14,
  },
  nameButtonIcon: {
    fontSize: 14,
  },
  checkbox: {
    margin: -5,
  },
  nameWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  toggleButton: {
    cursor: 'pointer',
    fontSize: '10px',
    marginTop: '5px',
    color: (theme: any) => theme.otherColors.darkGray,
    '&:hover': {
      color: (theme: any) => theme.palette.primary.main,
    },
  },
  leftCell: {
    textAlign: 'center',
    'td&': {
      px: 0.5,
      py: 0,
    },
  },
  optionsButton: {
    padding: '9px',
    '& svg': {
      opacity: 0,
    },
    '&:hover': {
      '& svg': {
        opacity: 1,
      },
    },
  },
  name: {},
  nameColumnContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  accountNameContainer: (theme: any) => ({
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width: 100,
    },
  }),
  offerName: (theme: any) => ({
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: '13px',
    letterSpacing: 0,
    maxWidth: 200,
    color: (theme: any) => theme.otherColors.darkGray,
    [theme.breakpoints.down('sm')]: {
      fontSize: '9px',
      lineHeight: '8px',
    },
  }),
};
