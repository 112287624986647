import React from 'react';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface CommonDatePickerProps extends DatePickerProps<any> {
  value: any;
  onChange: any;
  width?: string;
  label?: string;
  name?: string;
  error?: string;
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  picker: {
    height: 36,
  },
  datePicker: {
    width: '50%',
    marginTop: 0,
    '& + .MuiFormControl-root': {
      mt: 0,
    },
  },
  startDatePicker: {
    '& .MuiOutlinedInput-root': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  endDatePicker: {
    '& .MuiOutlinedInput-root': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  pickerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  errorMessage: (theme: any) => ({
    color: theme.palette.warning.dark,
  }),
};
const CommonDatePicker: React.FC<CommonDatePickerProps> = ({
  value,
  onChange,
  width = '100%',
  label,
  error,
  ...props
}) => {
  const { t } = useTranslation();
  const { startDate, endDate } = value;

  return (
    <Box width={width} sx={styles.container}>
      {label && <Typography variant="h5">{label}</Typography>}
      <Box sx={styles.pickerContainer}>
        <DatePicker
          value={startDate ? new Date(startDate) : startDate}
          onChange={(date: any) => onChange({ startDate: date, endDate })}
          format="dd.MM.yyyy"
          sx={{ ...styles.datePicker, ...styles.startDatePicker }}
          {...props}
        />
        <DatePicker
          value={endDate ? new Date(endDate) : endDate}
          onChange={(date: any) => onChange({ startDate, endDate: date })}
          format="dd.MM.yyyy"
          sx={{ ...styles.datePicker, ...styles.endDatePicker }}
          {...props}
        />
      </Box>
      {error && (
        <Typography variant="subtitle2" sx={styles.errorMessage}>
          {t(error)}
        </Typography>
      )}
    </Box>
  );
};

export default CommonDatePicker;
