import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';

import { usePlatform } from 'hooks/usePlatform';
import { COUNTRY_OPTIONS_BY_PLATFORM } from './constants';
import { Countries } from './models';

interface Props {
  value: Countries | '';
  onChange: Dispatch<SetStateAction<Countries>>;
  sx?: any;
  label?: string;
}

const styles = {
  select: {
    height: '36px',
  },
};

const CountrySelect: React.FC<Props> = ({ value, onChange, sx, label }) => {
  const { t } = useTranslation();
  const platform = usePlatform();

  const handleSelect = (e: SelectChangeEvent) => {
    const newCountry = e.target.value as Countries;
    onChange(newCountry);
  };

  return (
    <FormControl fullWidth>
      {label && <Typography variant="h5">{label}</Typography>}
      <Select
        sx={{ ...styles.select, '.MuiOutlinedInput-notchedOutline': { border: 0 }, ...sx }}
        value={value}
        onChange={handleSelect}
        variant="outlined">
        {COUNTRY_OPTIONS_BY_PLATFORM[platform].map(({ id, label }) => (
          <MenuItem key={id} value={id}>
            {t(label)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CountrySelect;
