import { number as yupNumber, object as yupObject } from 'yup';

export enum GoogleGroupSettingsFormFields {
  rate = 'rate',
}

export type GoogleGroupSettingsFormValues = {
  [GoogleGroupSettingsFormFields.rate]: number;
};

export const VALIDATION_SCHEMA = yupObject().shape({
  [GoogleGroupSettingsFormFields.rate]: yupNumber().required('errors.required'),
});
