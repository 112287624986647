import i18n from 'i18n';
import React from 'react';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { AdRule, Conditions } from 'models/adRule';
import { getNounCount } from 'utils/translate';
import { Advertiser } from 'models/advertiser';
import { User } from 'models/user';
import AdRuleSwitch from './AdRuleSwitch';

interface ConfigColumn {
  key: string;
  title: string;
  cellStyles?: any;
  render?: (adRule: AdRule) => any;
}

interface ConfigColumnsProps {
  user: User | null | undefined;
  onEdit: (adRule: AdRule) => void;
  onDelete: (adRule: AdRule) => void;
  refetchAdRules: () => void;
  advertisers: Advertiser[];
}

const iconStyles = {
  width: '20px',
  height: '20px',
  color: (theme: any) => theme.otherColors.lightGray,
};

export const getConfigColumns = ({
  user,
  onDelete,
  refetchAdRules,
  onEdit,
  advertisers,
}: ConfigColumnsProps): ConfigColumn[] => [
  {
    title: 'dashboard.adRules.rule',
    key: 'name',
  },
  {
    title: 'dashboard.adRules.accounts',
    key: 'accounts',
    render: ({ advertiserIds }) => {
      const advertisersNames = advertisers
        .filter(({ _id }) => advertiserIds.includes(_id))
        .map(({ name }) => name)
        .join(', ');

      return (
        <Tooltip title={advertisersNames}>
          <Typography>
            {i18n.t(`dashboard.adRules.accountsCount`, {
              count: getNounCount(advertiserIds.length),
              accountsCount: advertiserIds.length,
            })}
          </Typography>
        </Tooltip>
      );
    },
  },
  {
    title: 'dashboard.adRules.level',
    key: 'level',
    render: ({ level }) => i18n.t(`adLevels.${level}`),
  },
  {
    title: 'dashboard.adRules.conditions',
    key: 'conditions',
    render: ({ conditions }) => {
      if (!conditions) return '';
      const finalString = Object.entries(conditions).reduce((acc, [key, { operation, value }]) => {
        const conditionTranslate = i18n.t(`dashboard.adRules.${key}`);
        const operationTranslate = i18n.t(`dashboard.adRules.signs.${operation}`);
        const valueCurrency =
          key !== Conditions.conversions
            ? value.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })
            : value;

        return `${acc}${conditionTranslate} ${operationTranslate} ${valueCurrency}\n`;
      }, '');
      return <Typography sx={{ whiteSpace: 'pre' }}>{finalString}</Typography>;
    },
  },
  {
    title: 'dashboard.adRules.user',
    key: 'user',
    render: ({ user: { name } }) => name,
  },
  {
    title: 'dashboard.adRules.status',
    key: 'status',
    cellStyles: {
      width: '30px',
    },
    render: (adRule) => <AdRuleSwitch adRule={adRule} refetch={refetchAdRules} />,
  },
  {
    title: '',
    key: 'edit',
    cellStyles: {
      width: '60px',
    },
    render: (adRule) => (
      <Box display={'flex'}>
        <IconButton onClick={() => onEdit(adRule)}>
          <EditIcon sx={iconStyles} />
        </IconButton>
        {adRule.user._id === user?._id || user?.isAdmin ? (
          <IconButton onClick={() => onDelete(adRule)}>
            <DeleteIcon sx={iconStyles} />
          </IconButton>
        ) : null}
      </Box>
    ),
  },
];
