import { number as yupNumber, object as yupObject } from 'yup';

export enum YandexAdCampaignSettingsFormFields {
  bid = 'bid',
}

export type YandexAdCampaignSettingsFormValues = {
  [YandexAdCampaignSettingsFormFields.bid]: number;
};

export const VALIDATION_SCHEMA = yupObject().shape({
  [YandexAdCampaignSettingsFormFields.bid]: yupNumber().required('errors.required'),
});
