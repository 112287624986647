import { ACTIVE_STATUSES, DELETED_STATUSES, DISABLED_STATUSES } from '../DashboardTable/constants';

export enum CircleStatuses {
  active = 'active',
  disabled = 'disabled',
  deleted = 'deleted',
  unknown = 'unknown',
}

export const CircleColors = {
  active: '#48BB78',
  disabled: '#D02B20',
  deleted: '#adabab',
  unknown: '#9a9a9a',
};

export const getStatusKey = (value: string) => {
  if (ACTIVE_STATUSES.indexOf(value) !== -1) {
    return CircleStatuses.active;
  }
  if (DISABLED_STATUSES.indexOf(value) !== -1) {
    return CircleStatuses.disabled;
  }
  if (DELETED_STATUSES.indexOf(value) !== -1) {
    return CircleStatuses.deleted;
  }
  return CircleStatuses.unknown;
};
