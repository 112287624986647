import { useLocation } from 'react-router-dom';

import { Platforms } from 'constants/platforms';

const PLATFORM_PATHNAMES: { [key: string]: Platforms } = {
  tiktok: Platforms.tiktok,
  facebook: Platforms.facebook,
  google: Platforms.google,
  yandex: Platforms.yandex,
};

export const usePlatform = (isActive?: boolean) => {
  const { pathname } = useLocation();
  return PLATFORM_PATHNAMES[pathname.split('/')[1]] as Platforms;
};
