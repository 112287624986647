import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Box, IconButton, Typography, useTheme } from '@mui/material';

import { usePlatformLinks } from 'components/MainHeader/usePlatformLinks';
import { ReactComponent as BackIcon } from 'assets/svg/back.svg';
import { ReactComponent as UserIcon } from 'assets/svg/user.svg';
import { ReactComponent as ExitIcon } from 'assets/svg/exit.svg';
import { usePlatform } from 'hooks/usePlatform';
import { useAuth } from 'context/Auth';
import { LINKS } from './constants';
import { styles } from './styles';

interface MenuProps {
  closeMenu: () => void;
}

const Menu: React.FC<MenuProps> = ({ closeMenu }) => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const platform = usePlatform();
  const { t } = useTranslation();
  const platformLinks = usePlatformLinks();
  const {
    authData: { user },
    logout,
  } = useAuth();

  return (
    <Box sx={styles.card}>
      <Box sx={styles.backArrowContainer}>
        <IconButton onClick={closeMenu} sx={styles.backButton} size="large">
          <BackIcon />
        </IconButton>
      </Box>
      <Typography variant="h1" sx={styles.title}>
        Ads Manager
      </Typography>
      <Box sx={styles.userContainer}>
        <UserIcon style={styles.icon} />
        <Typography>{user?.name}</Typography>
      </Box>
      <Box sx={styles.platformsContainer}>
        {LINKS.map(({ link, Icon, label }) => (
          <Link to={link} key={link} style={styles.link} onClick={closeMenu}>
            <Box sx={styles.platform}>
              <Box sx={styles.platformIcon}>
                <Icon style={{ fill: link === pathname ? theme.palette.primary.main : theme.otherColors.darkGray }} />
              </Box>
              <Typography
                style={{ color: link === pathname ? theme.palette.primary.main : theme.otherColors.darkGray }}>
                {t(label)}
              </Typography>
            </Box>
          </Link>
        ))}
        {platformLinks.map(
          ({ link, Icon, isVisible, linkedPlatform }) =>
            isVisible && (
              <Link to={link} key={link} style={styles.link} onClick={closeMenu}>
                <Box sx={styles.platform}>
                  <Box sx={styles.platformIcon}>
                    <Icon
                      style={{
                        fill: platform === linkedPlatform ? theme.palette.primary.main : theme.otherColors.darkGray,
                      }}
                    />
                  </Box>
                  <Typography
                    style={{
                      color: platform === linkedPlatform ? theme.palette.primary.main : theme.otherColors.darkGray,
                    }}>
                    {linkedPlatform}
                  </Typography>
                </Box>
              </Link>
            )
        )}
      </Box>
      <Box sx={styles.logoutContainer} onClick={logout}>
        <ExitIcon style={styles.icon} />
        <Typography sx={styles.exitText}>{t('common.exit')}</Typography>
      </Box>
    </Box>
  );
};

export default Menu;
