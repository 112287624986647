import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { GoogleAdCampaignSettingsFormFields, GoogleAdCampaignSettingsFormValues, VALIDATION_SCHEMA } from './form';
import { useUpdateCampaignBudgetAndBid } from '../../hooks/useUpdateCampaignBudgetAndBid';
import { StatsItem } from 'models/stats';

interface Props {
  data: StatsItem;
  close: () => void;
  refetchData: () => void;
}

export const useGoogleAdCampaignSettingsForm = ({ data, refetchData, close }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { updateCampaignBudgetAndBid } = useUpdateCampaignBudgetAndBid();

  const { handleSubmit, control, watch } = useForm<GoogleAdCampaignSettingsFormValues>({
    mode: 'onBlur',
    defaultValues: { budget: data.budget || 0 },
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const currentBudget = watch(GoogleAdCampaignSettingsFormFields.budget);

  const onSubmit = handleSubmit(
    async ({ budget }) => {
      setLoading(true);
      if (budget !== data.budget) {
        await updateCampaignBudgetAndBid({ id: data.id, data: { budget } });
        refetchData();
      }
      setLoading(false);
      close();
    },
    (e) => console.log(e)
  );

  return {
    control,
    onSubmit,
    loading,
    disabled: Number(currentBudget) === data.budget,
  };
};
