import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { usePendingOperations } from 'context/PendingOperations/PendingOperations';
import { useDialog } from 'hooks/useDialog';

import PendingOperationsViewDialog from './PendingOperationsViewDialog';

const styles = {
  icon: (theme: any) => ({
    color: theme.palette.primary.main,
  }),
};

const PendingOperationsView: React.FC = () => {
  const { t } = useTranslation();
  const { pendingOperations, refetchPendingOperations } = usePendingOperations();
  const { isDialogOpen, closeDialog, openDialog } = useDialog();

  useEffect(() => {
    if (isDialogOpen) {
      refetchPendingOperations();
    }
  }, [isDialogOpen]);

  useEffect(() => {
    if (pendingOperations.length === 0) {
      closeDialog();
    }
  }, [closeDialog, pendingOperations.length]);

  return pendingOperations.length > 0 ? (
    <>
      <Tooltip title={t('dashboard.pendingOperations')}>
        <IconButton onClick={openDialog}>
          <AccessTimeIcon sx={styles.icon} />
        </IconButton>
      </Tooltip>
      {isDialogOpen && <PendingOperationsViewDialog close={closeDialog} />}
    </>
  ) : null;
};

export default PendingOperationsView;
