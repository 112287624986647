export const styles = {
  wrapper: {
    width: '100vw',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: (theme: any) => theme.palette.primary.main,
    padding: '0 26px',
    boxSizing: 'border-box',
  },
  card: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    padding: '60px 30px',
    boxSizing: 'border-box',
    maxWidth: 500,
  },
  input: {
    width: '100%',
  },
  loginMessage: {
    marginTop: '30px',
    fontSize: '16px',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '24px',
    width: '100%',
  },
  inputLabel: {
    fontSize: '14px',
    marginBottom: '4px',
  },
  submitButton: {
    width: '100%',
    marginTop: '30px',
    height: '40px',
    borderRadius: '4px',
  },
  changePasswordLink: {
    textDecoration: 'none',
    color: '#346FB0',
  },
  changePasswordText: {
    mt: 2,
  },
};
