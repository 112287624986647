import { useCallback, useState } from 'react';

import { AdRule } from 'models/adRule';
import { useDialog } from 'hooks/useDialog';
import { useDeleteAdRuleRequest } from './useDeleteAdRule';

export const useDeleteAdRuleDialog = (refetchAdRules: () => void) => {
  const { deleteAdRule } = useDeleteAdRuleRequest(refetchAdRules);
  const [loading, setLoading] = useState<boolean>(false);
  const [deletedAdRule, setDeletedAdRule] = useState<AdRule | null>(null);

  const {
    openDialog: openDeleteAdRuleDialog,
    closeDialog: closeDeleteAdRuleDialog,
    isDialogOpen: isDeleteAdRuleDialogOpen,
  } = useDialog();

  const handleOpenDeleteAdRuleDialog = useCallback(
    (adRule: AdRule) => {
      setDeletedAdRule(adRule);
      openDeleteAdRuleDialog();
    },
    [openDeleteAdRuleDialog]
  );

  const handleDeleteAdRule = async () => {
    setLoading(true);
    if (!deletedAdRule) return;
    await deleteAdRule(deletedAdRule._id);
    refetchAdRules();
    setLoading(false);
    closeDeleteAdRuleDialog();
  };

  return {
    deleteAdRule: handleDeleteAdRule,
    openDeleteAdRuleDialog: handleOpenDeleteAdRuleDialog,
    closeDeleteAdRuleDialog,
    isDeleteAdRuleDialogOpen,
    loading,
  };
};
