export const styles = {
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  card: {
    padding: '20px',
  },
  tableContainer: {
    padding: '20px',
  },
};
