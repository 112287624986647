import React, { useCallback, useContext, useMemo } from 'react';
import { useLocalStorage } from 'react-use';
import { Platforms } from '../constants/platforms';
import { noop } from 'lodash';
import { usePlatform } from '../hooks/usePlatform';
import { useAuth } from './Auth';
import ApiClient from 'services/apiClient';
import { changeTrackedAccount } from 'constants/endpoints';
import _isEmpty from 'lodash/isEmpty';

interface AccountFilterData {
  setFilter: (newIds: string[]) => void;
  toggleFilter: () => void;
  activeRows: string[];
  isFilterActive: boolean;
}

const AccountFilter = React.createContext<AccountFilterData>({
  toggleFilter: noop,
  setFilter: noop,
  activeRows: [],
  isFilterActive: false,
});

const AccountFilterProvider: React.FC = ({ children }) => {
  const [tiktokOldFilters] = useLocalStorage<string[]>('filter', []);
  const platform = usePlatform();
  const { authData, updateTrackedAccounts } = useAuth();

  const getActiveRows = useCallback(() => {
    const trackedAccounts = authData?.user?.trackedAccounts[platform];

    if (!_isEmpty(trackedAccounts)) {
      return trackedAccounts || [];
    }

    if (platform === Platforms.tiktok) {
      return tiktokOldFilters || [];
    }

    return [];
  }, [authData?.user?.trackedAccounts, platform, tiktokOldFilters]);

  const setActiveRows = async (accountIds: string[]) => {
    await ApiClient.PATCH(changeTrackedAccount(authData?.userId!), {
      body: {
        accountIds,
        platform,
      },
    });
    updateTrackedAccounts(accountIds, platform);
  };

  const activeRows = useMemo(() => getActiveRows(), [getActiveRows]);

  const [isFilterActive, setFilterActive] = React.useState(false);

  const setFilter = (newIds: string[]) => {
    setActiveRows(newIds);
    setFilterActive(false);
  };

  const toggleFilter = () => setFilterActive(!isFilterActive);

  return (
    <AccountFilter.Provider
      value={{
        isFilterActive,
        activeRows,
        setFilter,
        toggleFilter,
      }}>
      {children}
    </AccountFilter.Provider>
  );
};

export const useAccountFilter = () => {
  const contextValue = useContext(AccountFilter);
  return contextValue;
};

export default AccountFilterProvider;
