import { useCallback, useEffect, useState } from 'react';

import { tableSettings as tableSettingsEndpoint } from 'constants/endpoints';
import ApiClient from 'services/apiClient';

export const useVariablesRequest = () => {
  const [saving, setSaving] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>(null);

  const getSettings = async () => {
    try {
      setLoading(true);
      const res = await ApiClient.GET(tableSettingsEndpoint);
      setData(res.payload);
    } catch (e) {
      console.log((e as any).statusText);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSettings();
  }, []);

  const sendNewSettings = useCallback(async (body) => {
    const {
      kzCancelRate,
      kzFinishRate,
      kzDeliveryFee,
      kzCancelFee,
      kzVat,
      byCancelRate,
      byFinishRate,
      byDeliveryFee,
      byCancelFee,
      byVat,
    } = body;
    try {
      setSaving(true);
      const res = await ApiClient.POST(tableSettingsEndpoint, {
        body: {
          KZ: {
            cancelRate: Number(kzCancelRate) / 100,
            finishRate: Number(kzFinishRate) / 100,
            deliveryFee: Number(kzDeliveryFee),
            cancelFee: Number(kzCancelFee),
            vat: Number(kzVat) / 100,
          },
          BY: {
            cancelRate: Number(byCancelRate) / 100,
            finishRate: Number(byFinishRate) / 100,
            deliveryFee: Number(byDeliveryFee),
            cancelFee: Number(byCancelFee),
            vat: Number(byVat) / 100,
          },
        },
      });
      alert('Настройки успешно сохранены');
    } catch (e) {
      console.log((e as any).statusText);
    } finally {
      setSaving(false);
    }
  }, []);

  return {
    loading,
    data,
    saving,
    sendNewSettings,
  };
};
