import { useCallback, useState } from 'react';

import { CampaignData } from 'context/PendingOperations/PendingOperations';
import { PendingOperationActions } from 'models/pendingOperations';
import { pendingOperationsBulk } from 'constants/endpoints';
import ApiClient from 'services/apiClient';

export interface CreatePendingOperationsData {
  action: PendingOperationActions;
  campaigns: CampaignData[];
  date: string;
}

export const useCreatePendingOperationsRequest = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const createPendingOperations = useCallback(async ({ campaigns, date, action }: CreatePendingOperationsData) => {
    try {
      setLoading(true);
      const res = await ApiClient.POST(pendingOperationsBulk, {
        body: {
          data: campaigns.map(({ id, level }) => ({
            level,
            action,
            levelId: id,
            eventTime: date,
          })),
        },
      });
      return res;
    } catch (e) {
      console.log((e as any).statusText);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    createPendingOperations,
    loading,
  };
};
