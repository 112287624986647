import React from 'react';

import { usePlatform } from 'hooks/usePlatform';
import { Platforms } from 'constants/platforms';
import { DashboardModalProps } from './models';

interface DialogContentProps extends DashboardModalProps {
  getContent: (platform: Platforms) => React.JSXElementConstructor<DashboardModalProps> | null;
}
const DialogContent: React.FC<DialogContentProps> = ({ getContent, ...props }) => {
  const platform = usePlatform();
  const Content = getContent(platform);
  if (!Content) return null;
  return <Content {...props} />;
};

export default DialogContent;
