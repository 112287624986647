import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Box, Grid, Popover, Typography, useTheme } from '@mui/material';

import { useAuth } from 'context/Auth';
import { usePlatform } from 'hooks/usePlatform';
import { ReactComponent as UserIcon } from 'assets/svg/userWhite.svg';

import { LINKS } from './constants';
import Notifications from './Notifications';
import { usePlatformLinks } from './usePlatformLinks';

const styles = {
  userLink: {
    width: 'fit-content',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    height: '64px',
    padding: '0 30px',
  },
  drawer: {
    width: '100%',
  },
  linksContainer: {
    display: 'flex',
    height: '100%',
    marginLeft: '70px',
  },
  linkContainer: {
    width: '96px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  link: {
    textDecoration: 'none',
  },
  gridItem: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  userContainer: {
    justifyContent: 'flex-end',
  },
  title: {
    whiteSpace: 'nowrap',
  },
  logoutText: {
    padding: 2,
    cursor: 'pointer',
  },
};

const DesktopHeader = () => {
  const theme = useTheme();
  const platform = usePlatform();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const {
    authData: { user },
    logout,
  } = useAuth();
  const platformLinks = usePlatformLinks();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const openPopover = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Grid container sx={styles.container}>
      <Grid item xs={2} sx={styles.gridItem}>
        <Typography variant="h2" sx={styles.title}>
          Ads Manager
        </Typography>
      </Grid>
      <Grid item xs={8} sx={styles.gridItem}>
        <Box sx={styles.linksContainer}>
          {LINKS.map(({ label, link }) => (
            <Link
              to={link}
              key={label}
              style={{
                backgroundColor: link === pathname ? 'rgba(255,255,255, 0.1)' : 'unset',
                color: theme.palette.secondary.light,
                ...styles.link,
              }}>
              <Box sx={styles.linkContainer}>
                <Typography
                  variant="h5"
                  style={{
                    opacity: link === pathname ? 1 : 0.4,
                    textAlign: 'center',
                  }}>
                  {t(label)}
                </Typography>
              </Box>
            </Link>
          ))}
          {platformLinks.map(
            ({ label, link, isVisible, linkedPlatform }) =>
              isVisible && (
                <Link
                  to={link}
                  key={label}
                  style={{
                    backgroundColor: linkedPlatform === platform ? 'rgba(255,255,255, 0.1)' : 'unset',
                    color: theme.palette.secondary.light,
                    ...styles.link,
                  }}>
                  <Box sx={styles.linkContainer}>
                    <Typography
                      variant="h5"
                      style={{
                        opacity: linkedPlatform === platform ? 1 : 0.4,
                      }}>
                      {label}
                    </Typography>
                  </Box>
                </Link>
              )
          )}
        </Box>
      </Grid>
      <Grid item xs={2} style={{ ...styles.gridItem, ...styles.userContainer }}>
        <Notifications />
        <Box aria-describedby={id} onClick={openPopover} sx={styles.userLink}>
          <UserIcon style={{ marginRight: '10px' }} />
          <Typography>{user?.name}</Typography>
        </Box>
      </Grid>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <Typography onClick={logout} sx={styles.logoutText}>
          {t('common.logout')}
        </Typography>
      </Popover>
    </Grid>
  );
};

export default DesktopHeader;
