export enum Currencies {
  RUB = 'RUB',
  BYN = 'BYN',
  USD = 'USD',
  PLN = 'PLN',
  EUR = 'EUR',
  KZT = 'KZT',
}

export enum CurrenciesSymbols {
  RUB = '₽',
  USD = '$',
  BYN = 'руб',
  PLN = 'zł',
  EUR = '€',
}
