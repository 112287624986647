import { Navigate, Route, Routes } from 'react-router-dom';

import Login from 'pages/Auth/Login';
import ChangePassword from 'pages/Auth/ChangePassword';

const AuthRouter = () => (
  <Routes>
    <Route index element={<Navigate to="/login" replace />} />
    <Route path="/login" element={<Login />} />
    <Route path="/change-password" element={<ChangePassword />} />
    <Route path="*" element={<Navigate to="/login" replace />} />
  </Routes>
);

export default AuthRouter;
