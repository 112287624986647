import React from 'react';

import MuiLoadingButton from '@mui/lab/LoadingButton';
import Loader from 'components/Common/Loader';
import { LoadingButtonProps } from './models';

const LoadingButton: React.FC<LoadingButtonProps> = ({ isLoading = false, disabled, children, ...props }) => (
  <MuiLoadingButton disabled={disabled || isLoading} loading={isLoading} loadingIndicator={<Loader />} {...props}>
    {children}
  </MuiLoadingButton>
);

export default LoadingButton;
