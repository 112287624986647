import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Grid, IconButton, Table, TableBody, TableCell, TableHead, Typography, TableRow } from '@mui/material';
import _get from 'lodash.get';

import { usePendingOperations } from 'context/PendingOperations/PendingOperations';
import ConfirmationModal from 'components/Common/ConfirmationModal';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import { styles } from 'components/Common/Shared/dialogStyles';
import { useDialog } from 'hooks/useDialog';

import { useDeletePendingOperationRequest } from './useDeletePendingOperationRequest';
import { getConfigColumns } from './config';

interface PendingOperationsDialogProps {
  close: () => void;
}

const specificStyles = {
  container: {
    flexDirection: 'column',
    minWidth: '800px',
  },
  tableContainer: {
    px: '31px',
    paddingBottom: '20px',
    maxHeight: '300px',
    overflow: 'auto',
  },
  headCell: (theme: any) => ({
    fontSize: '12px',
    color: theme.otherColors.darkGray,
    textTransform: 'uppercase',
    border: `1px solid ${theme.otherColors.lightGray}`,
  }),
  bodyCell: (theme: any) => ({
    fontSize: '12px',
    border: `1px solid ${theme.otherColors.lightGray}`,
  }),
  bodyRow: {
    height: '40px',
  },
};

const PendingOperationsViewDialog: React.FC<PendingOperationsDialogProps> = ({ close }) => {
  const { t } = useTranslation();
  const { openDialog, closeDialog, isDialogOpen } = useDialog();
  const { pendingOperations, refetchPendingOperations } = usePendingOperations();
  const { deletePendingOperation, loading } = useDeletePendingOperationRequest(refetchPendingOperations);
  const [deletedItemId, setDeletedItemId] = useState<string>('');

  const onDelete = (id: string) => {
    setDeletedItemId(id);
    openDialog();
  };

  const handleDelete = async () => {
    await deletePendingOperation(deletedItemId);
    closeDialog();
  };

  const configColumns = useMemo(() => getConfigColumns({ deletePendingOperation: onDelete }), [deletePendingOperation]);

  return (
    <Dialog open={true} PaperProps={{ sx: specificStyles.container }}>
      <Grid container sx={styles.headerContainer}>
        <Grid item>
          <Typography variant="h3">{t('dashboard.pendingOperations')}</Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={close} sx={styles.closeButton} size="large">
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container sx={specificStyles.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              {configColumns.map(({ title, key }) => (
                <TableCell key={key} sx={specificStyles.headCell}>
                  {title ? t(title) : ''}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {pendingOperations.map((pendingOperation) => (
              <TableRow sx={specificStyles.bodyRow} key={pendingOperation._id}>
                {configColumns.map(({ render, key }) => (
                  <TableCell key={key} sx={specificStyles.bodyCell}>
                    {render ? render(pendingOperation) : _get(pendingOperation, key, '')}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
      <ConfirmationModal
        title={t('dashboard.deleteOperation')}
        subtitle={t('dashboard.deleteOperationSubtitle')}
        onCancel={closeDialog}
        onConfirm={handleDelete}
        open={isDialogOpen}
        isLoading={loading}
      />
    </Dialog>
  );
};

export default PendingOperationsViewDialog;
