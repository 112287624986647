import { useCallback, useEffect, useMemo, useState } from 'react';

import { adRules as adRulesEndpoint } from 'constants/endpoints';
import ApiClient from 'services/apiClient';
import { AdRule } from 'models/adRule';
import { usePlatform } from 'hooks/usePlatform';

export const useAdRulesRequest = () => {
  const platform = usePlatform();
  const [loading, setLoading] = useState<boolean>(false);
  const [adRules, setAdRules] = useState<AdRule[]>([]);

  const getAdRules = useCallback(async () => {
    try {
      setLoading(true);
      const res = await ApiClient.GET(adRulesEndpoint);
      setAdRules(res);
    } catch (e) {
      console.log((e as any).statusText);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getAdRules();
  }, [getAdRules]);

  const activeAdRules = useMemo(() => adRules.filter((adRule) => adRule.platform === platform), [adRules, platform]);

  return {
    loading,
    adRules: activeAdRules,
    refetchAdRules: getAdRules,
  };
};
