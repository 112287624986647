import { createTheme } from '@mui/material/styles';
import React from 'react';
import { ReactComponent as CheckboxIcon } from 'assets/svg/checkbox.svg';
import { ReactComponent as CheckboxCheckedIcon } from 'assets/svg/checkboxChecked.svg';

declare module '@mui/material/styles' {
  interface Theme {
    otherColors: {
      gray: React.CSSProperties['color'];
      darkGray: React.CSSProperties['color'];
      lightGray: React.CSSProperties['color'];
      lightBlue: React.CSSProperties['color'];
      celeste: React.CSSProperties['color'];
      white: React.CSSProperties['color'];
      text: React.CSSProperties['color'];
      warning: React.CSSProperties['color'];
    };
  }

  interface ThemeOptions {
    otherColors: {
      gray: React.CSSProperties['color'];
      darkGray: React.CSSProperties['color'];
      lightGray: React.CSSProperties['color'];
      lightBlue: React.CSSProperties['color'];
      celeste: React.CSSProperties['color'];
      white: React.CSSProperties['color'];
      text: React.CSSProperties['color'];
      warning: React.CSSProperties['color'];
    };
  }
}

let theme = createTheme();
theme = createTheme({
  otherColors: {
    gray: '#e5e5e5',
    darkGray: '#A0AEC0',
    lightGray: '#E2E8F0',
    lightBlue: '#F9FAFC',
    celeste: '#a6d5fa',
    white: '#fff',
    text: '#2D3748',
    warning: '#FF8227',
  },
  palette: {
    primary: {
      dark: '#003467',
      light: '#02447B',
      main: '#346FB0',
    },
    secondary: {
      dark: '#fafafa',
      light: '#fff',
      main: '#fff',
    },
    warning: {
      dark: '#f93030',
      light: '#fc7878',
      main: '#D02B20',
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
    h1: {
      fontSize: 32,
      marginBottom: 30,
      fontWeight: 400,
      marginTop: 30,
    },
    h2: {
      fontSize: 24,
      fontWeight: 500,
      [theme.breakpoints.down('md')]: {
        fontSize: 20,
      },
    },
    h3: {
      fontSize: 18,
      fontWeight: 600,
      [theme.breakpoints.down('md')]: {
        fontSize: 18,
      },
    },
    h4: {
      fontSize: 16,
      fontWeight: 600,
      [theme.breakpoints.down('md')]: {
        fontSize: 16,
      },
    },
    h5: {
      fontSize: 14,
      fontWeight: 500,
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
      },
    },
    body1: {
      fontSize: 14,
    },
    body2: {
      fontSize: 12,
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
      },
    },
  },
  components: {
    MuiCheckbox: {
      defaultProps: {
        icon: <CheckboxIcon />,
        checkedIcon: <CheckboxCheckedIcon />,
        indeterminateIcon: <CheckboxIcon />,
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          color: '#2D3748',
          fontWeight: 500,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          whiteSpace: 'nowrap',
          fontWeight: 600,
          letterSpacing: '1.25%',
        },
        containedSecondary: {
          color: '#346FB0',
          backgroundColor: 'transparent',
          boxShadow: 'none',
          '&:hover': {
            color: '#346FB0',
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          boxShadow: '0px 5px 14px 0px #2D37480D',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          borderColor: '#E2E8F0',
          '&.Mui-error': {
            marginBottom: 3,
          },
        },
        input: {
          padding: '8px 16px',
        },
        notchedOutline: {
          borderColor: '#dee2e6',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '1.1rem',
          color: '#222222',
        },
        body: {
          fontSize: '1rem',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
          padding: '8px 16px',
        },
        head: {
          fontSize: '12px',
          fontWeight: 700,
          color: '#222222',
          lineHeight: 1.2,
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          '&:hover': {
            color: '#222222',
          },
          '&:focus': {
            color: '#222222',
          },
        },
        icon: {
          color: '#0ca2b0',
        },
        active: {
          '& path': {
            color: '#0ca2b0',
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          backgroundColor: '#fafafa',
        },
        input: {
          fontSize: '1rem',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: '#fafafa',
          '&$expanded': {
            margin: 0,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          border: '1px solid #E2E8F0',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.MuiOutlinedInput-root': {
            marginBottom: 0,
            '&.Mui-error': {
              marginBottom: 0,
            },
            '&.MuiInputBase-multiline': {
              padding: 0,
            },
            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
              display: 'none',
            },
            '& input[type=number]': {
              MozAppearance: 'textfield',
            },
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          position: 'absolute',
          whiteSpace: 'nowrap',
          bottom: -18,
          right: -14,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          transform: 'translate(14px, 8px) scale(1)',
        },
        shrink: {
          transform: 'translate(14px, -9px) scale(0.75)',
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        PopperProps: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -10],
              },
            },
          ],
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          fontSize: 14,
        },
        body: {
          fontFamily: '"Open Sans", sans-serif !important',
          lineHeight: '20px',
          backgroundColor: '#fff',
          '& ::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
            backgroundColor: '#F5F5F5',
          },
          '& ::-webkit-scrollbar': {
            width: 10,
            height: 10,
            backgroundColor: '#F5F5F5',
          },
          '& ::-webkit-scrollbar-thumb': {
            borderRadius: 5,
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,.3)',
            backgroundColor: '#55bec8',
          },
          [theme.breakpoints.down('sm')]: {
            overflowY: 'hidden',
          },
        },
        button: {
          fontFamily: '"Open Sans", sans-serif',
        },
        '.breakWords': {
          wordBreak: 'break-word',
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default theme;
