import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import ApiClient from 'services/apiClient';
import { tasks as tasksEndpoint } from 'constants/endpoints';
import { customDateFormat } from 'utils/date';
import { Platforms } from 'constants/platforms';

interface Props {
  onSuccess: () => void;
}

const NewJob: React.FC<Props> = ({ onSuccess }) => {
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState('');
  const [dateEnd, setDateEnd] = useState('');
  const [name, setName] = useState('');
  const [platform, setPlatform] = useState('');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    const res = await ApiClient.POST(tasksEndpoint, {
      body: {
        date: customDateFormat(date, 'YYYY-MM-DD'),
        dateEnd: customDateFormat(dateEnd, 'YYYY-MM-DD'),
        name,
        platform,
      },
    });
    onSuccess();
    console.log(res);
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleOpen}>
        Add job
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>New Job</DialogTitle>
        <DialogContent>
          <form>
            <TextField
              label="Date"
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Date End"
              type="date"
              value={dateEnd}
              onChange={(e) => setDateEnd(e.target.value)}
              fullWidth
              margin="normal"
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Name</InputLabel>
              <Select value={name} onChange={(e) => setName(e.target.value)} label="Name">
                {JOB_NAMES.map((name) => (
                  <MenuItem value={name}>{name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Platform</InputLabel>
              <Select value={platform} onChange={(e) => setPlatform(e.target.value)} label="Platform">
                {Object.keys(Platforms).map((platform) => (
                  <MenuItem value={platform}>{platform}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NewJob;

const JOB_NAMES = [
  'fetchAdvertisers',
  'fetchCampaigns',
  'fetchGroups',
  'fetchAds',
  'fetchAdvertiserReports',
  'fetchCampaignReports',
  'fetchGroupReports',
  'fetchAdReports',
];
