import React, { useEffect, useState } from 'react';
import { Box, Card, Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Loader from 'components/Common/Loader';

import { useCompetitiveRequest } from './hooks/useAnalyticsRequest';
import { styles } from './styles';
import CompetitiveTable from './CompetitiveTable';
import CompetitiveHeader from './CompetitiveHeader';

const CompetitiveDashboard = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { data, loading, setDate, date, level, setLevel } = useCompetitiveRequest();

  return (
    <Grid container>
      <Grid item xs={12} sx={styles.container}>
        <CompetitiveHeader updateDate={setDate} data={data} date={date} level={level} setLevel={setLevel as any} />
        {loading && !data && <Loader />}
        {data && (
          <Box className="full-screen">
            <CompetitiveTable list={[...data.BY.targetologs, { metrics: data.BY.total }]} country="BY" />
            <CompetitiveTable list={[...data.KZ.targetologs, { metrics: data.KZ.total }]} country="KZ" />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default CompetitiveDashboard;
