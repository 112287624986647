import React, { useContext, useEffect, useMemo, useState } from 'react';
import _noop from 'lodash/noop';

import PendingOperationsViewDialog from 'components/DashboardTable/PendingOperations/PendingOperationsView/PendingOperationsViewDialog';
import { usePendingOperationsRequest } from './usePendingOperationsRequest';
import { PendingOperation } from 'models/pendingOperations';
import { AdLevels } from 'constants/levels';
import { useDialog } from 'hooks/useDialog';

export interface CampaignData {
  id: string;
  level: AdLevels;
}

interface PendingOperationsData {
  toggleCampaignSelect: (data: CampaignData) => void;
  refetchPendingOperations: () => void;
  resetSelectedCampaigns: () => void;
  selectedCampaigns: CampaignData[];
  selectedCampaignsIds: string[];
  pendingOperations: PendingOperation[];
  pendingOperationEntitiesIds: string[];
  openPendingOperationsDialog: () => void;
}

const PendingOperations = React.createContext<PendingOperationsData>({
  refetchPendingOperations: _noop,
  resetSelectedCampaigns: _noop,
  toggleCampaignSelect: _noop,
  selectedCampaignsIds: [],
  pendingOperations: [],
  selectedCampaigns: [],
  pendingOperationEntitiesIds: [],
  openPendingOperationsDialog: () => {},
});

const PendingOperationsProvider: React.FC = ({ children }) => {
  const { pendingOperations, refetchPendingOperations, pendingOperationEntitiesIds } = usePendingOperationsRequest();
  const [selectedCampaigns, setSelectedCampaigns] = useState<CampaignData[]>([]);
  const selectedCampaignsIds = useMemo(() => selectedCampaigns.map(({ id }) => id), [selectedCampaigns]);

  const resetSelectedCampaigns = () => setSelectedCampaigns([]);

  const toggleCampaignSelect = (data: CampaignData) =>
    setSelectedCampaigns(
      selectedCampaignsIds.includes(data.id)
        ? selectedCampaigns.filter(({ id }) => id !== data.id)
        : [...selectedCampaigns, data]
    );

  const { isDialogOpen, closeDialog, openDialog } = useDialog();

  useEffect(() => {
    if (isDialogOpen) {
      refetchPendingOperations();
    }
  }, [isDialogOpen]);

  useEffect(() => {
    if (pendingOperations.length === 0) {
      closeDialog();
    }
  }, [closeDialog, pendingOperations.length]);

  return (
    <PendingOperations.Provider
      value={{
        selectedCampaigns,
        toggleCampaignSelect,
        resetSelectedCampaigns,
        selectedCampaignsIds,
        pendingOperations,
        refetchPendingOperations,
        pendingOperationEntitiesIds,
        openPendingOperationsDialog: openDialog,
      }}>
      {children}
      {isDialogOpen && <PendingOperationsViewDialog close={closeDialog} />}
    </PendingOperations.Provider>
  );
};

export const usePendingOperations = () => {
  const contextValue = useContext(PendingOperations);
  return contextValue;
};

export default PendingOperationsProvider;
