import React, { useCallback } from 'react';
import { Typography, TableCell as TableCellMui } from '@mui/material';
import _get from 'lodash.get';

import { Column, Params } from './models';

interface TableCellProps<T> {
  column: Column<T>;
  row: T;
}

const TableCell = <T extends Params<T>>({ row, column }: TableCellProps<T>) => {
  const renderContent = useCallback(() => {
    if (column.render) {
      return column.render(row);
    }

    return <Typography>{_get(row, column.key)}</Typography>;
  }, [row, column]);

  return <TableCellMui>{renderContent()}</TableCellMui>;
};

export default TableCell;
