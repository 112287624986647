import React from 'react';
import { Button, TableCell, TableRow } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { useCrudTableContext } from './CrudTableContext';
import { Column, Params } from './models';
import TableFilter from './TableFilter';

const styles = {
  addButton: {
    width: '100%',
  },
};

interface TableFiltersProps<T> {
  columns: Column<T>[];
  setFilters: (filters: any) => void;
}

const TableFilters = <T extends Params<T>>({ columns, setFilters }: TableFiltersProps<T>) => {
  const { onAdd } = useCrudTableContext();

  return (
    <TableRow>
      <TableCell>
        <Button variant="contained" sx={styles.addButton} onClick={onAdd}>
          <AddIcon />
        </Button>
      </TableCell>
      {columns.map((column) => (
        <TableFilter key={column.title} column={column} setFilters={setFilters} />
      ))}
    </TableRow>
  );
};

export default TableFilters;
