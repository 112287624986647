export const LINKS = [
  {
    label: 'competitive.title',
    link: '/table',
  },
  {
    label: 'analytics.title',
    link: '/analytics',
  },
];
