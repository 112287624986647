import { useCallback, useState } from 'react';

import { adRuleById } from 'constants/endpoints';
import ApiClient from 'services/apiClient';
import { Condition } from 'models/adRule';

export interface UpdateAdRuleData {
  conditions?: { [key: string]: Condition };
  isActive?: boolean;
  name?: string;
  level?: string;
}

export const useUpdateAdRuleRequest = (refetch?: () => void) => {
  const [loading, setLoading] = useState<boolean>(false);

  const updateAdRule = useCallback(
    async (id: string, data: UpdateAdRuleData) => {
      try {
        setLoading(true);
        await ApiClient.PUT(adRuleById(id), { body: data });
        setLoading(false);
        if (refetch) {
          await refetch();
        }
      } catch (err) {
        setLoading(false);
        if (!refetch) {
          throw err;
        } else {
          console.log(err);
          await refetch();
        }
      }
    },
    [refetch]
  );

  return {
    loading,
    updateAdRule,
  };
};
