import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, Grid, MenuItem, Select, Typography } from '@mui/material';

import CommonDateRangePicker from 'components/Common/CommonDateRangePicker';
import { useAuth } from 'context/Auth';

import { useAnalyticsFilters } from '../AnalyticsFiltersContext';
import { COUNTRY_OPTIONS, DEFAULT_OPTION } from '../constants';
import { styles } from '../styles';

interface DesktopAnalyticsFiltersProps {
  offerNames: string[];
}

const DesktopAnalyticsFilters: React.FC<DesktopAnalyticsFiltersProps> = ({ offerNames }) => {
  const { t } = useTranslation();
  const {
    authData: { user },
  } = useAuth();

  const {
    period,
    userId,
    country,
    platform,
    offerName,
    usersOptions,
    platformOptions,
    handleUserIdChange,
    handleCountryChange,
    handlePeriodChange,
    handlePlatformChange,
    handleOfferNameChange,
    errors,
  } = useAnalyticsFilters();

  return (
    <Grid container mb={2} columnSpacing={2}>
      <Grid item xs={3}>
        <CommonDateRangePicker
          value={period}
          onChange={handlePeriodChange}
          label={t('analytics.period')}
          maxDate={new Date()}
          error={errors['period']}
        />
      </Grid>
      <Grid item xs={2}>
        <FormControl fullWidth>
          <Typography variant="h5">{t('analytics.offerName')}</Typography>
          <Select sx={styles.select} value={offerName} onChange={handleOfferNameChange} variant="outlined">
            <MenuItem value={DEFAULT_OPTION.id}>{t(DEFAULT_OPTION.label)}</MenuItem>
            {offerNames?.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <FormControl fullWidth>
          <Typography variant="h5">{t('analytics.platform')}</Typography>
          <Select sx={styles.select} value={platform} onChange={handlePlatformChange} variant="outlined">
            <MenuItem value={DEFAULT_OPTION.id}>{t(DEFAULT_OPTION.label)}</MenuItem>
            {platformOptions.map((platform) => (
              <MenuItem key={platform} value={platform}>
                {t(`platforms.${platform as string}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <FormControl fullWidth>
          <Typography variant="h5">{t('analytics.country')}</Typography>
          <Select sx={styles.select} value={country} onChange={handleCountryChange} variant="outlined">
            <MenuItem value={DEFAULT_OPTION.id}>{t(DEFAULT_OPTION.label)}</MenuItem>
            {COUNTRY_OPTIONS.map(({ id, label }) => (
              <MenuItem key={id} value={id}>
                {t(label)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {user?.isAdmin && (
        <Grid item xs={3}>
          <FormControl fullWidth>
            <Typography variant="h5">{t('analytics.user')}</Typography>
            <Select sx={styles.select} value={userId} onChange={handleUserIdChange} variant="outlined">
              {usersOptions.map(({ label, id }) => (
                <MenuItem key={id} value={id}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
    </Grid>
  );
};

export default DesktopAnalyticsFilters;
