import React from 'react';
import i18n from 'i18next';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { PendingOperation } from 'models/pendingOperations';
import { formatDate } from 'utils/date';

interface ConfigColumn {
  key: string;
  title: string;
  render?: (pendingOperation: PendingOperation) => any;
}

interface getConfigColumnsProps {
  deletePendingOperation: (id: string) => void;
}

export const getConfigColumns = ({ deletePendingOperation }: getConfigColumnsProps): ConfigColumn[] => [
  {
    key: 'status',
    title: 'dashboard.status',
    render: ({ status }) => i18n.t(`dashboard.statuses.${String(status)}`),
  },
  {
    key: 'level',
    title: 'dashboard.level',
    render: ({ level }) => i18n.t(`adLevels.${String(level)}`),
  },
  {
    key: 'entity.name',
    title: 'dashboard.name',
  },
  {
    key: 'action',
    title: 'dashboard.action',
    render: ({ action }) => i18n.t(`dashboard.actions.${String(action)}`),
  },
  {
    key: 'time',
    title: 'dashboard.time',
    render: ({ eventTime }) => formatDate(new Date(eventTime), true),
  },
  {
    key: 'remove',
    title: '',
    render: ({ _id }) => (
      <IconButton onClick={() => deletePendingOperation(_id)}>
        <DeleteIcon />
      </IconButton>
    ),
  },
];
