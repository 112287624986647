import moment from 'moment';

export const formatDate = (date: Date | string | undefined, withTime = false) => {
  if (!date) return '';
  const format = withTime ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY';
  return moment(date).format(format);
};

export const customDateFormat = (date: Date | string | undefined, format: string) => {
  if (!date) return '';
  return moment(date).format(format);
};

export const getDaysInMonth = (date?: Date) => {
  const now = date || new Date();
  return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
};

const ONE_DAY = 86400000;
export const roundToNearestDay = (date: Date) => new Date(Math.round(date.valueOf() / ONE_DAY) * ONE_DAY);

export const addDays = (date = new Date(), days = 1) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);

  return newDate;
};

export const daysBetweenDates = (date1: Date, date2: Date) => {
  const diff = date1.getTime() - date2.getTime();
  return Math.ceil(diff / (1000 * 3600 * 24));
};
