import { AdLevels } from 'constants/levels';

export enum PendingOperationActions {
  turnOn = 'TURN_ON',
  turnOff = 'TURN_OFF',
}

export enum PendingOperationStatus {
  deleted = 'DELETED',
  pending = 'PENDING',
  completed = 'COMPLETED',
}

export interface PendingOperation {
  _id: string;
  levelId: string;
  eventTime: string;
  level: AdLevels;
  entity: {
    _id: string;
    name: string;
  };
  status: PendingOperationStatus;
  action: PendingOperationActions;
}
