import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DateRangePicker } from 'react-date-range';
import { DatePickerProps } from '@mui/x-date-pickers';
import { Box, Dialog, TextField, Typography } from '@mui/material';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { useDialog } from 'hooks/useDialog';
import { getStaticRanges } from './utils';
import { formatDate, roundToNearestDay } from 'utils/date';

export interface CommonDateRangePickerProps extends DatePickerProps<any> {
  value: any;
  onChange: any;
  width?: string;
  label?: string;
  name?: string;
  error?: string;
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  picker: {
    height: 36,
  },
  datePicker: {
    width: '50%',
    marginTop: 0,
    '& + .MuiFormControl-root': {
      mt: 0,
    },
  },
  startDatePicker: {
    '& .MuiOutlinedInput-root': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  endDatePicker: {
    '& .MuiOutlinedInput-root': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  pickerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  errorMessage: (theme: any) => ({
    color: theme.palette.warning.dark,
  }),
};
const CommonDateRangePicker: React.FC<CommonDateRangePickerProps> = ({
  value,
  onChange,
  width = '100%',
  label,
  maxDate,
  minDate,
  disabled,
  error,
}) => {
  const { t } = useTranslation();
  const { openDialog, isDialogOpen, closeDialog } = useDialog();
  const { startDate, endDate } = value;

  const defaultStaticRanges = useMemo(() => getStaticRanges(), [isDialogOpen]);

  const handleChange = ({ selection: { startDate, endDate } }: any) =>
    onChange({
      startDate: roundToNearestDay(startDate),
      endDate: roundToNearestDay(endDate),
    });

  const ranges = [
    {
      startDate,
      endDate,
      key: 'selection',
    },
  ];

  return (
    <Box width={width} sx={styles.container}>
      {label && <Typography variant="h5">{label}</Typography>}
      <Box sx={styles.pickerContainer}>
        <TextField
          value={`${formatDate(startDate)} - ${formatDate(endDate)}`}
          sx={{ input: { cursor: disabled ? 'default' : 'pointer' }, width: '100%' }}
          onClick={disabled ? undefined : openDialog}
          InputProps={{
            readOnly: true,
          }}
          disabled={disabled}
        />
        {error && (
          <Typography variant="subtitle2" sx={styles.errorMessage}>
            {t(error)}
          </Typography>
        )}
      </Box>
      {isDialogOpen && (
        <Dialog open={isDialogOpen} onClose={closeDialog}>
          <DateRangePicker
            onChange={handleChange}
            ranges={ranges}
            moveRangeOnFirstSelection={false}
            maxDate={maxDate}
            minDate={minDate}
            direction="horizontal"
            staticRanges={defaultStaticRanges}
            inputRanges={[]}
          />
        </Dialog>
      )}
    </Box>
  );
};

export default CommonDateRangePicker;
