const en = {
  common: {
    logout: 'Log out',
    placeholder: 'There is no data',
    save: 'Save',
    cancel: 'Cancel',
    yes: 'Yes',
    no: 'No',
    clear: 'Clear',
    exit: 'Exit',
    change: 'Change',
    confirm: 'Confirm',
    actions: 'Actions',
    name: 'Name',
    admin: 'Admin',
    export: 'Export',
    turnOn: 'Turn on',
    turnOff: 'Turn off',
  },
  adLevels: {
    campaign: 'Campaign',
    group: 'Group',
    ad: 'Ad',
  },
  login: {
    login: 'Login',
    password: 'Password',
    changePassword: 'Change password',
    enterPassword: 'Enter password',
    enterPasswordAgain: 'Enter password again',
    confirmPassword: 'Confirm password',
    enter: 'Login',
    enterLogin: 'Enter login',
    rememberPassword: 'Remembered password?',
  },
  permissions: {
    platforms: {
      'yandex-dashboard': 'Yandex',
      'tiktok-dashboard': 'Tiktok',
      'google-dashboard': 'Google',
      'facebook-dashboard': 'Facebook',
    },
    operations: {
      read: 'Read',
      edit: 'Edit',
      export: 'Export',
    },
  },
  table: {
    bid: 'Bid',
    conversion: 'Conv.',
    reach: 'Reach',
    frequency: 'Freq.',
    cost_per_conversion: 'CPA',
    cpm: 'CPM',
    crm: 'CRM',
    spend: 'Spend',
    clicks: 'Clicks',
    impressions: 'Impr.',
    conversion_rate: 'CR',
    ctr: 'CTR',
    cpc: 'CPC',
    balance: 'Balance',
    name: 'Account Name',
  },
  analytics: {
    title: 'Analytics',
    period: 'Period',
    offerName: 'Offer',
    platform: 'Platform',
    all: 'All',
    user: 'User',
    spend: 'Spend',
    conversion: 'Conversion',
    reach: 'Reach',
    frequency: 'Frequency',
    costPerConversion: 'CPA',
  },
  dashboard: {
    filter: 'Filter',
    period: 'Period',
    budget: 'Budget',
    budgetForAllTime: 'All time budget',
    allCountries: 'All countries',
    filterAccountsCount: 'Accounts - {{count}}',
    offerFilter: 'Offer',
    autoRules: 'Auto rules',
    forMonth: 'Last 30 days',
    bid: 'Bid',
    changeBid: 'Change bid',
    filterAccounts: 'Filter accounts',
    exportToExcel: 'Export to Excel',
    level: 'Level',
    advertisers: 'Advertisers',
    campaigns: 'Campaigns',
    groups: 'Groups',
    ads: 'Ads',
    country: 'Country',
    all: 'All',
    belarus: 'Belarus',
    kazakhstan: 'Kazakhstan',
    from: 'From',
    to: 'To',
    exportAllAccountsConfirm: 'Export all accounts?',
    pendingOperationCount: 'Affected objects: {{count}}',
    changeStatus: 'Change status',
    makePriority: 'Mark as priority',
    time: 'Time',
    today: 'Today',
    yesterday: 'Yesterday',
    last3days: 'Last 3 days',
    last7days: 'Last 7 days',
    last10days: 'Last 10 days',
    noData: 'No Data',
    chooseAccount: 'Choose account',
    name: 'Name',
    statuses: {
      PENDING: 'Pending',
      ERROR: 'Error',
      COMPLETED: 'Completed',
    },
    adRules: {
      title: 'Automated Rules',
      rule: 'Rule',
      name: 'Rule name',
      selectAccounts: 'Select accounts',
      accounts: 'Accounts',
      level: 'Level',
      conditions: 'Conditions',
      user: 'User',
      status: 'Status',
      conversions: 'Conversions',
      cpa: 'CPA',
      spend: 'Spend',
      accountsCount_one: '{{accountsCount}} Account',
      accountsCount: '{{accountsCount}} Accounts',
      editAdRule: 'Edit Ad Rule',
      newAdRule: 'New Ad Rule',
      add: 'Create Ad Rule',
      more: 'More',
      less: 'Less',
      equal: 'Equals',
      placeholder: 'No Rules Added',
      signs: {
        over: '>',
        below: '<',
        equal: '=',
      },
    },
  },
  admin: {
    users: {
      users: 'Users',
      editUser: 'Edit user',
      createUser: 'Create user',
      resetPassword: 'Reset password',
      userCreated: 'User successfully created! Temporary password: {{password}}. Save it.',
      passwordReset: 'Password reset successfully! New password: {{password}}. Save it.',
      deleteUser: 'Delete user',
      deleteUserConfirm: 'Are you sure you want to delete the user {{name}}',
      permissions: 'Permissions',
    },
  },
  errors: {
    latinChars: 'Field must contain only latin characters',
    emptyValue: 'Value cannot be empty',
    UserWithCodeAlreadyExists: 'User with this CRM code already exists',
    required: 'Required field',
    email: 'Email must be valid',
    passwordConfirmation: 'Passwords must match',
    url: 'Website must be a valid URL',
    minZero: 'Must be greater than zero',
    minOne: 'Must be greater than one',
    maxPercent: 'Must be less than or equal to 100',
    maxLength: 'The field must not contain more than 200 characters',
    number: 'The field does not match the number',
    maxNumber: 'The number must not be more than a million',
    integer: 'This number must not be a fraction',
    positive: 'This number must be positive',
    biggerThanZero: 'This number must be greater than 0',
    AccessDenied: 'Login or password entered incorrectly',
    networkError: 'Network error',
    'Internal server error': 'Internal server error',
    loginError: 'Login error',
    unknownError: 'Unknown error',
    notEmptyBid: 'Big cannot be empty',
  },
};

export default en;
