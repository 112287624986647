export enum ResourceOperationEnum {
  read = 'read',
  edit = 'edit',
  export = 'export',
}

export interface Permission {
  _id: string;
  resource: string;
  operation: ResourceOperationEnum;
}
