const styles = {
  placeholder: {
    color: (theme: any) => theme.otherColors.lightGray,
  },
  placeholderCell: {
    border: 'none',
    textAlign: 'center',
  },
  errorCell: {
    borderBottom: 'none',
    textAlign: 'center',
  },
  error: {
    color: (theme: any) => theme.palette.warning.dark,
  },
  tableContainer: (theme: any) => ({
    boxSizing: 'border-box',
    minWidth: '1000px',
    [theme.breakpoints.down('sm')]: {
      minWidth: 'unset',
    },
  }),
  tableHead: (theme: any) => ({
    backgroundColor: '#fff',
    height: '48px',
    [theme.breakpoints.down('sm')]: {
      height: '32px',
    },
  }),
  tableHeadCell: (theme: any) => ({
    fontSize: '12px',
    color: theme.otherColors.darkGray,
    textTransform: 'uppercase',
    backgroundColor: '#fff',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
      maxWidth: '80px',
    },
  }),
  tableHeadCellContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  clickable: {
    cursor: 'pointer',
  },
  table: (theme: any) => ({
    height: '100%',
    padding: '24px',
    [theme.breakpoints.down('sm')]: {
      padding: '8px 16px 10px',
    },
    '& td': {
      fontSize: '12px',
      padding: '8px 16px',
      '& + td': {
        borderLeft: '1px solid #E2E8F0',
        backgroundColor: 'black',
      },
      '&:not(:nth-of-type(2))': {
        whiteSpace: 'nowrap',
        fontWeight: 400,
      },
      '&:nth-of-type(2)': {
        borderRight: `1px solid ${theme.otherColors.lightGray}`,
        position: 'sticky',
        left: 0,
        zIndex: 1,
      },
      '&:nth-of-type(3)': {
        borderLeft: `none`,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '10px',
        padding: '5px 6px',
        '&:not(:nth-of-type(1))': {
          whiteSpace: 'nowrap',
          fontWeight: 400,
        },
        '&:nth-of-type(1)': {
          borderRight: `1px solid ${theme.otherColors.lightGray}`,
          position: 'sticky',
          left: 0,
          zIndex: 1,
          whiteSpace: 'unset',
        },
        '&:nth-of-type(2)': {
          borderLeft: `none`,
          position: 'relative',
          zIndex: 0,
        },
      },
    },
    '& th': {
      padding: '8px 16px',
      '& + th': {
        borderLeft: `1px solid ${theme.otherColors.lightGray}`,
      },
      '&:nth-of-type(2)': {
        width: '350px',
        position: 'sticky',
        left: 0,
        backgroundColor: '#fff',
        borderRight: `1px solid ${theme.otherColors.lightGray}`,
        zIndex: 3,
      },
      '&:nth-of-type(3)': {
        borderLeft: 'none',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '5px 6px',
        '&:nth-of-type(1)': {
          position: 'sticky',
          left: 0,
          backgroundColor: '#fff',
          borderRight: `1px solid ${theme.otherColors.lightGray}`,
          zIndex: 3,
        },
        '&:nth-of-type(2)': {
          maxWidth: '80px',
          position: 'sticky',
          borderLeft: 'none',
          background: '#fff',
          zIndex: 2,
        },
      },
    },
  }),
  customTableContainer: (theme: any) => ({
    border: `1px solid ${theme.otherColors.lightGray}`,
    height: 'calc(98% - 60px - 20px)',
    overflow: 'auto',
    overflowX: 'initial',
    [theme.breakpoints.down('sm')]: {
      '&::-webkit-scrollbar': { display: 'none' },
    },
  }),
  bold: {
    fontWeight: 'bold',
  },
  row: {
    cursor: 'pointer',
  },
  footer: {
    width: '100%',
    backgroundColor: '#fff',
    position: 'fixed',
    top: 'auto',
    bottom: 0,
  },
  checkboxCell: {
    width: '2%',
  },
  checkbox: {
    margin: -5,
  },
  input: {
    margin: '1rem',
    '& input': {
      padding: '0.5rem',
    },
  },
  nameWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
};

export default styles;
