import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { getStatusData } from '../../../utils';
import { useUpdateGroupBudgetAndRate } from '../../hooks/useUpdateGroupBudgetAndRate';
import { FacebookGroupSettingsFormFields, FacebookGroupSettingsFormValues, VALIDATION_SCHEMA } from './form';
import { StatsItem } from 'models/stats';

interface Props {
  data: StatsItem;
  close: () => void;
  refetchData: () => void;
}

export const useFacebookGroupSettingsForm = ({ data, close, refetchData }: Props) => {
  const { isActiveDefault, canBeChanged } = getStatusData(data);
  const [isActive, setIsActive] = useState<boolean>(isActiveDefault);
  const [loading, setLoading] = useState<boolean>(false);
  const { updateGroupBudgetAndRate } = useUpdateGroupBudgetAndRate();
  const defaultValues = { lifetimeBudget: data.lifetimeBudget || 0, budget: data.budget || 0 };
  const { handleSubmit, control, watch } = useForm<FacebookGroupSettingsFormValues>({
    mode: 'onBlur',
    defaultValues,
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const [currentBudget, currentLifetimeBudget] = watch([
    FacebookGroupSettingsFormFields.budget,
    FacebookGroupSettingsFormFields.lifetimeBudget,
  ]);

  const onSubmit = handleSubmit(
    async ({ budget, lifetimeBudget }) => {
      setLoading(true);
      if (budget !== defaultValues.budget || lifetimeBudget !== defaultValues.lifetimeBudget) {
        await updateGroupBudgetAndRate({ id: data.id, data: { budget, lifetimeBudget } });
        refetchData();
      }
      setLoading(false);
      close();
    },
    (e) => console.log(e)
  );

  return {
    control,
    onSubmit,
    loading,
    isActive,
    setIsActive,
    canBeChanged,
    disabled:
      Number(currentBudget) === defaultValues.budget &&
      Number(currentLifetimeBudget) === defaultValues.lifetimeBudget &&
      isActive === isActiveDefault,
  };
};
