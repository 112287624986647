import { useCallback, useState } from 'react';

import { User } from 'models/user';
import { useDialog } from 'hooks/useDialog';
import { userById } from 'constants/endpoints';
import ApiClient from 'services/apiClient';

export const useDeleteUserDialog = (refertchUsers: () => void) => {
  const [deletedUser, setDeletedUser] = useState<User | null>(null);
  const { openDialog, closeDialog, isDialogOpen } = useDialog();

  const deleteUser = useCallback(async () => {
    try {
      if (!deletedUser) return;
      const res = await ApiClient.DELETE(userById(deletedUser?._id));
      refertchUsers();
    } catch (e) {
      console.log((e as any).statusText);
    }
  }, [deletedUser, refertchUsers]);

  const onDelete = useCallback((user: User) => {
    setDeletedUser(user);
    openDialog();
  }, []);

  const onSuccessDelete = useCallback(() => {
    closeDialog();
    setDeletedUser(null);
  }, [closeDialog]);

  const handleDelete = useCallback(() => {
    if (!deletedUser) return;
    deleteUser();
    onSuccessDelete();
  }, [deletedUser]);

  return {
    onDelete,
    deletedUser,
    onSuccessDelete,
    isDeleting: isDialogOpen,
    handleDelete,
  };
};
