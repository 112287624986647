import React, { useContext, useEffect, useMemo, useState } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { noop } from 'lodash';

import { getAvailablePlatforms } from 'hooks/usePermissionCheck';
import { useUsers } from 'services/users/useUsers';
import { daysBetweenDates, formatDate } from 'utils/date';
import { DEFAULT_OPTION } from './constants';
import { useAuth } from 'context/Auth';

interface AnalyticsFiltersData {
  platform: string;
  country: string;
  offerName: string;
  userId: string;
  period: { startDate: Date; endDate: Date };
  usersOptions: any[];
  platformOptions: any[];
  handleOfferNameChange: (e: SelectChangeEvent) => void;
  handleUserIdChange: (e: SelectChangeEvent) => void;
  handlePlatformChange: (e: SelectChangeEvent) => void;
  handleCountryChange: (e: SelectChangeEvent) => void;
  handlePeriodChange: (period: { startDate: Date; endDate: Date }) => void;
  errors: { [key: string]: string };
}

const AnalyticsFilters = React.createContext<AnalyticsFiltersData>({
  handleOfferNameChange: noop,
  handleUserIdChange: noop,
  handlePlatformChange: noop,
  handleCountryChange: noop,
  country: '',
  offerName: '',
  userId: '',
  period: { startDate: new Date(), endDate: new Date() },
  platformOptions: [],
  usersOptions: [],
  platform: '',
  handlePeriodChange: noop,
  errors: {},
});

const AnalyticsFiltersProvider: React.FC = ({ children }) => {
  const {
    authData: { user },
  } = useAuth();
  const { usersOptions } = useUsers({ skip: !user?.isAdmin });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [country, setCountry] = useState<string>(DEFAULT_OPTION.id);
  const [platform, setPlatform] = useState<string>(DEFAULT_OPTION.id);
  const [offerName, setOfferName] = useState<string>(DEFAULT_OPTION.id);
  const [userId, setUserId] = useState<string>(DEFAULT_OPTION.id);
  const [period, setPeriod] = useState<{ startDate: Date; endDate: Date }>(() => {
    const today = new Date();
    return {
      startDate: today,
      endDate: today,
    };
  });

  useEffect(() => {
    if (!user?._id) return;
    setUserId(user._id);
  }, [usersOptions]);

  const platformOptions = useMemo(() => getAvailablePlatforms(user?.normalizedPermissions), [user]);

  const handleOfferNameChange = (e: SelectChangeEvent) => setOfferName(e.target.value);

  const handleUserIdChange = (e: SelectChangeEvent) => setUserId(e.target.value);

  const handlePlatformChange = (e: SelectChangeEvent) => setPlatform(e.target.value as string);

  const handleCountryChange = (e: SelectChangeEvent) => setCountry(e.target.value);

  const handlePeriodChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
    const daysDiff = daysBetweenDates(endDate, startDate);
    const dateRangeError = daysDiff > 62;
    setErrors((prev) => {
      const { period, ...otherErrors } = prev;
      return {
        ...otherErrors,
        ...(dateRangeError && { ['period']: 'errors.analyticsDatesRange' }),
      };
    });
    setPeriod({ startDate, endDate });
  };

  return (
    <AnalyticsFilters.Provider
      value={{
        period,
        userId,
        country,
        offerName,
        usersOptions,
        platformOptions,
        handlePeriodChange,
        handleUserIdChange,
        handleCountryChange,
        handlePlatformChange,
        handleOfferNameChange,
        platform,
        errors,
      }}>
      {children}
    </AnalyticsFilters.Provider>
  );
};

export const useAnalyticsFilters = () => {
  const contextValue = useContext(AnalyticsFilters);
  return contextValue;
};

export default AnalyticsFiltersProvider;
