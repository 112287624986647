import { useCallback, useEffect, useState } from 'react';

import { history as historyEndpoint } from 'constants/endpoints';
import { History, HistoryInitiators } from 'models/history';
import ApiClient from 'services/apiClient';

export const useHistoryRequest = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [history, setHistory] = useState<History[]>([]);

  const getHistory = useCallback(async () => {
    try {
      setLoading(true);
      const res = await ApiClient.POST(historyEndpoint, {
        body: {
          initiatorName: [HistoryInitiators.eventSchedule, HistoryInitiators.autoRule],
        },
      });
      setHistory(res);
    } catch (e) {
      console.log((e as any).statusText);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getHistory();
  }, [getHistory]);

  return {
    loading,
    history,
    refetchHistory: getHistory,
  };
};
