import _get from 'lodash.get';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { AdRule } from 'models/adRule';
import { useAuth } from 'context/Auth';
import { useDialog } from 'hooks/useDialog';
import { usePlatform } from 'hooks/usePlatform';
import ConfirmationModal from 'components/Common/ConfirmationModal';
import Loader from 'components/Common/Loader/Loader';

import { useAdRulesRequest } from './hooks/useAdRules';
import NewAdRuleDialog from './NewAdRuleDialog';
import { getConfigColumns } from './config';
import { useDeleteAdRuleDialog } from './hooks/useDeleteAdRuleDialog';
import { useAdvertisers } from '../../services/advertisers/useAdvertisers';

const styles = {
  container: {
    flexDirection: 'column',
    px: '32px',
    height: 'calc(100% - 64px)',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    my: '30px',
  },
  headerTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  headerTitle: {
    m: 0,
    color: (theme: any) => theme.otherColors.text,
  },
  backIcon: {
    width: '24px',
    height: '24px',
    color: (theme: any) => theme.otherColors.text,
  },
  contentContainer: {
    flexDirection: 'column',
    height: 'calc(100% - 150px)',
    marginBottom: '50px',
  },
  contentHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '20px',
    marginTop: '24px',
  },
  tableContainer: {
    px: '31px',
    paddingBottom: '20px',
    maxHeight: '450px',
    overflow: 'auto',
    height: '100%',
  },
  headCell: (theme: any) => ({
    fontSize: '12px',
    color: theme.otherColors.darkGray,
    textTransform: 'uppercase',
    borderTop: `1px solid ${theme.otherColors.lightGray}`,
    borderLeft: `1px solid ${theme.otherColors.lightGray}`,
    position: 'sticky',
    top: 0,
    zIndex: 1,
    background: 'white',
    '&:last-of-type': {
      borderRight: `1px solid ${theme.otherColors.lightGray}`,
    },
  }),
  bodyCell: (theme: any) => ({
    fontSize: '14px',
    borderLeft: `1px solid ${theme.otherColors.lightGray}`,
    borderBottom: `1px solid ${theme.otherColors.lightGray}`,
    '&:last-of-type': {
      borderRight: `1px solid ${theme.otherColors.lightGray}`,
    },
  }),
  bodyRow: {
    height: '50px',
  },
  table: {
    borderCollapse: 'separate',
  },
  placeholderContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  placeholderIcon: {
    width: '30px',
    height: '30px',
    color: (theme: any) => theme.otherColors.lightGray,
    mb: 1,
  },
  placeholder: {
    color: (theme: any) => theme.otherColors.lightGray,
  },
};

const AdRulesTable = () => {
  const platform = usePlatform();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    authData: { user },
  } = useAuth();
  const { advertisers, loading: loadingAdvertisers } = useAdvertisers();
  const { adRules, refetchAdRules, loading: loadingAdRules } = useAdRulesRequest();
  const {
    deleteAdRule,
    isDeleteAdRuleDialogOpen,
    closeDeleteAdRuleDialog,
    openDeleteAdRuleDialog,
    loading: deleteLoading,
  } = useDeleteAdRuleDialog(refetchAdRules);
  const {
    openDialog: openNewAdRuleDialog,
    closeDialog: closeNewAdRuleDialog,
    isDialogOpen: isNewAdRuleDialogOpen,
    openDialogWithParams: openNewAdRuleDialogWithParams,
    dialogParams,
  } = useDialog();

  const goBack = () => navigate(`/${platform}`);

  const onEdit = useCallback(
    (adRule: AdRule) => openNewAdRuleDialogWithParams({ adRule }),
    [openNewAdRuleDialogWithParams]
  );

  const configColumns = useMemo(
    () =>
      getConfigColumns({
        refetchAdRules,
        onDelete: openDeleteAdRuleDialog,
        onEdit,
        user,
        advertisers,
      }),
    [refetchAdRules, openDeleteAdRuleDialog, onEdit, user, advertisers]
  );

  return (
    <>
      {isNewAdRuleDialogOpen && (
        <NewAdRuleDialog
          advertisers={advertisers}
          loadingAdvertisers={loadingAdvertisers}
          close={closeNewAdRuleDialog}
          refetch={refetchAdRules}
          {...dialogParams}
        />
      )}
      {isDeleteAdRuleDialogOpen && (
        <ConfirmationModal
          title={t('dashboard.adRules.deleteAdRuleTitle')}
          subtitle={t('dashboard.adRules.deleteAdRuleSubtitle')}
          onCancel={closeDeleteAdRuleDialog}
          onConfirm={deleteAdRule}
          open={isDeleteAdRuleDialogOpen}
          isLoading={deleteLoading}
        />
      )}
      <Grid container sx={styles.container}>
        <Grid container sx={styles.header}>
          <Grid item sx={styles.headerTitleContainer}>
            <IconButton onClick={goBack}>
              <ArrowBackIcon sx={styles.backIcon} />
            </IconButton>
            <Typography variant="h1" sx={styles.headerTitle}>
              {t('dashboard.adRules.title')}
            </Typography>
          </Grid>
          <Grid item></Grid>
        </Grid>
        <Card sx={styles.contentContainer}>
          <Grid item sx={styles.contentHeader}>
            <Button variant="contained" onClick={openNewAdRuleDialog}>
              {t('dashboard.adRules.add')}
            </Button>
          </Grid>
          <Grid item sx={styles.tableContainer}>
            {adRules.length > 0 ? (
              <Table sx={styles.table}>
                <TableHead>
                  <TableRow>
                    {configColumns.map(({ title, key }) => (
                      <TableCell key={key} sx={styles.headCell}>
                        {title ? t(title) : ''}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {adRules.map((adRule) => (
                    <TableRow sx={styles.bodyRow} key={adRule._id}>
                      {configColumns.map(({ render, key, cellStyles }) => (
                        <TableCell key={key} sx={styles.bodyCell} style={cellStyles}>
                          {render ? render(adRule) : _get(adRule, key, '')}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : loadingAdRules ? (
              <Loader />
            ) : (
              <Box sx={styles.placeholderContainer}>
                <InfoIcon sx={styles.placeholderIcon} />
                <Typography sx={styles.placeholder}>{t('dashboard.adRules.placeholder')}</Typography>
              </Box>
            )}
          </Grid>
        </Card>
      </Grid>
    </>
  );
};

export default AdRulesTable;
