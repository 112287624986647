import { ResourceOperationEnum } from 'models/permission';
import { Platforms } from 'constants/platforms';
import { useAuth } from 'context/Auth';

export const getAvailablePlatform = (userPermissions: string[] = []) =>
  Object.values(Platforms).find((platform) => checkPermission(userPermissions, platform));

export const getAvailablePlatforms = (userPermissions: string[] = []) =>
  Object.values(Platforms).filter((platform) => checkPermission(userPermissions, platform));

export const checkPermission = (
  userPermissions: string[],
  platform: Platforms,
  permission: string = 'any'
): boolean => {
  if (permission === 'any') {
    return Object.values(ResourceOperationEnum).some((operation) => {
      return userPermissions?.includes(`${platform}-dashboard:${operation}`);
    });
  }

  return !!userPermissions?.includes(`${platform}-dashboard:${permission}`);
};

export const usePermissionCheck = (platform: Platforms, permission: string = 'any') => {
  const {
    authData: { user },
  } = useAuth();
  const { normalizedPermissions = [] } = user || {};

  return checkPermission(normalizedPermissions, platform, permission);
};
