import { useCallback, useState } from 'react';

import ApiClient from 'services/apiClient';
import { users } from 'constants/endpoints';
import { NewUserFormValues } from '../form';

export const useCreateUser = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const createUser = useCallback(async (data: NewUserFormValues, refetch?: () => void) => {
    try {
      setLoading(true);
      const res = await ApiClient.POST(users, { body: data });
      refetch && refetch();
      return res;
    } catch (e) {
      console.log((e as any).statusText);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    createUser,
    loading,
  };
};
