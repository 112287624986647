import React from 'react';
import { FormControl, FormHelperText, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';
import { DateTimePicker } from '@mui/x-date-pickers';
import _get from 'lodash.get';

import { CommonDateTimePickerProps } from './models';

const CommonDateTimePicker: React.FC<CommonDateTimePickerProps> = ({
  name,
  control,
  label,
  width = '100%',
  disabled = false,
  minDate,
}) => {
  const { t } = useTranslation();
  const {
    field: { value, ...field },
    formState: { errors },
  } = useController({
    name,
    control,
  });
  const error = _get(errors, name);
  const errorMessage = error?.['message'];

  return (
    <FormControl error={!!error} sx={{ width }}>
      <Typography variant="h5" sx={{ display: 'flex', justifyContent: 'flex-start' }}>
        {label}
      </Typography>
      <DateTimePicker
        value={typeof value === 'string' ? new Date(value) : value}
        disabled={disabled}
        format="dd.MM.yyyy HH:mm"
        minDateTime={minDate}
        ampm={false}
        {...field}
      />
      {error && <FormHelperText>{t(`${errorMessage}`)}</FormHelperText>}
    </FormControl>
  );
};

export default CommonDateTimePicker;
