const ru = {
  common: {
    logout: 'Выйти',
    placeholder: 'Нет данных',
    save: 'Сохранить',
    cancel: 'Отменить',
    yes: 'Да',
    no: 'Нет',
    clear: 'Очистить',
    exit: 'Выход',
    change: 'Изменить',
    confirm: 'Подтвердить',
    actions: 'Действия',
    name: 'Имя',
    admin: 'Администратор',
    export: 'Экспорт',
    TURN_ON: 'Включить',
    TURN_OFF: 'Выключить',
  },
  notifications: {
    title: 'События',
    notificationsCount: 'Новых событий: {{count}}',
    notificationsPlaceholder: 'У вас 0 новых событий',
    entities: {
      group: 'Группа',
      campaign: 'Кампания',
      ad: 'Объявление',
    },
    messages: {
      offByAutoRule: '{{entity}} <i>{{entityName}}</i> выключена по автоправилу <i>{{autoRuleName}}</i>',
      offByScheduled: '{{entity}} <i>{{entityName}}</i> выключена отложенной операцией',
      onByScheduled: '{{entity}} <i>{{entityName}}</i> включена отложенной операцией',
      updateBidByAutoRule: '{{entity}} <i>{{entityName}}</i> обновлена ставка по автоправилу',
    },
  },
  login: {
    login: 'Логин',
    password: 'Пароль',
    changePassword: 'Сменить пароль',
    enterPassword: 'Введите пароль',
    enterPasswordAgain: 'Введите пароль повторно',
    confirmPassword: 'Подтвердите пароль',
    enter: 'Войти',
    enterLogin: 'Введите логин',
    rememberPassword: 'Вспомнили пароль?',
  },
  permissions: {
    platforms: {
      'yandex-dashboard': 'Yandex',
      'tiktok-dashboard': 'Tiktok',
      'google-dashboard': 'Google',
      'facebook-dashboard': 'Facebook',
    },
    operations: {
      read: 'Просмотр',
      edit: 'Редактирование',
      export: 'Экспорт',
    },
  },
  table: {
    bid: 'Ставка',
    conversion: 'Конв.',
    reach: 'Охват',
    frequency: 'Част.',
    crmCount: 'CRM',
    cost_per_conversion: 'CPA',
    crm: 'CRM',
    cpm: 'CPM',
    spend: 'Затраты',
    clicks: 'Клики',
    impressions: 'Показы',
    conversion_rate: 'CR',
    ctr: 'CTR',
    cpc: 'CPC',
    cpl: 'CPL',
    vp2: 'ВП2',
    vp2Single: 'ВП2, Выкуп',
    romi: 'ROMI',
    balance: 'Баланс',
    name: 'Название',
    targetolog: 'Таргетолог',
    month: 'Месяц',
    day: 'День',
    BY: 'Беларусь',
    KZ: 'Казахстан',
    updated: 'Обновлено',
    noData: 'Нет данных',
  },
  competitive: {
    title: 'Таргетологи',
  },
  adLevels: {
    campaign: 'Кампания',
    group: 'Группа',
    ad: 'Объявление',
  },
  analytics: {
    country: 'Страна',
    period: 'Период',
    title: 'Аналитика',
    tooltip: 'Учитываются данные по отфильтрованным аккаунтам за последние 30 дней',
    spend: 'Затраты',
    conversion: 'Конверсия',
    reach: 'Охват',
    frequency: 'Частота',
    costPerConversion: 'CPA',
    offerName: 'Оффер',
    all: 'Все',
    user: 'Пользователь',
    platform: 'Площадка',
    BY: 'Беларусь',
    KZ: 'Казахстан',
    updated: 'Обновлено',
    noData: 'Нет данных',
  },
  platforms: {
    yandex: 'Yandex',
    facebook: 'Facebook',
    google: 'Google',
    tiktok: 'Tiktok',
  },
  dashboard: {
    errors: {
      fetchDataError: 'Ошибка при загрузке данных',
    },
    averageCheck: 'Средний чек: {{averageCheck}}',
    lastUpdate: 'Обновлено: {{updatedAt}}',
    leads: 'Лиды: {{leads}}',
    sales: 'Продажи: {{sales}}',
    autoRules: 'Автоправила',
    offerFilter: 'Оффер',
    filter: 'Фильтр',
    period: 'Период',
    unit: 'Разбивка',
    today: 'Сегодня',
    yesterday: 'Вчера',
    budget: 'Бюджет',
    last3days: 'За 3 дня',
    last7days: 'За 7 дней',
    last10days: 'За 10 дней',
    for3days: 'За 3 дня',
    forWeek: 'За неделю',
    forMonth: 'За месяц',
    budgetForAllTime: 'Бюджет на все время',
    bid: 'Ставка',
    changeBid: 'Изменить ставку',
    country: 'Страна',
    filterAccounts: 'Выберите аккаунты',
    filterAccountsCount: 'Аккаунты - {{count}}',
    exportToExcel: 'Экспортировать в Excel',
    level: 'Уровень',
    advertisers: 'Аккаунты',
    campaigns: 'Кампании',
    groups: 'Группы',
    ads: 'Объявления',
    all: 'Все',
    allCountries: 'Все страны',
    belarus: 'Беларусь',
    kazakhstan: 'Казахстан',
    belarusShort: 'Беларусь',
    kazakhstanShort: 'Казахстан',
    from: 'С',
    to: 'По',
    exportAllAccountsConfirm: 'Экспортировать все аккаунты?',
    changeStatus: 'Сменить статус',
    makePriority: 'Отметить приоритетным',
    time: 'Время',
    noData: 'Нет данных',
    chooseAccount: 'Выберите аккаунт',
    reset: 'Сбросить',
    pendingOperation: 'Отложенная операция',
    pendingOperationCount: 'Выбрано объектов: {{count}}',
    pendingOperations: 'Отложенные операции',
    action: 'Действие',
    date: 'Дата',
    name: 'Название',
    status: 'Статус',
    delete: 'Удалить',
    deleteOperation: 'Удалить операцию',
    deleteOperationSubtitle: 'Вы уверены, что хотите удалить операцию?',
    units: {
      day: 'День',
      week: 'Неделя (пн-вс)',
      month: 'Месяц (1-31)',
      no: 'Без разбивки',
    },
    adRules: {
      title: 'Авто правила',
      add: 'Добавить',
      rule: 'Название',
      conditions: 'Условия',
      level: 'Уровень',
      user: 'Автор',
      status: 'Статус',
      placeholder: 'Нет автоправил',
      newAdRule: 'Новое автоправило',
      editAdRule: 'Редактирование автоправила',
      name: 'Название',
      conversions: 'Конверсии',
      spend: 'Затраты',
      cpa: 'CPA',
      more: 'Больше',
      less: 'Меньше',
      active: 'Активен',
      deleteAdRuleTitle: 'Удалить автоправило',
      deleteAdRuleSubtitle: 'Вы уверены, что хотите удалить автоправило?',
      accountsCountDefault: 'Аккаунты - {{count}}',
      selectAccounts: 'Выберите аккаунты',
      accountsCount_one: '{{accountsCount}} Аккаунт',
      accountsCount_few: '{{accountsCount}} Аккаунта',
      accountsCount_many: '{{accountsCount}} Аккаунтов',
      accounts: 'Аккаунты',
      signs: {
        over: '>',
        below: '<',
      },
      action: 'Действие',
      pause: 'Пауза',
      updateBid: 'Обновить ставку',
      updateBidType: 'Тип обновления',
      timeMode: 'Режим',
      now: 'В реальном времени',
      daily: 'Ежедневно',
      percent: 'Процент',
      value: 'Значение',
      updateBidValuePercent: 'Увеличить ставку на',
      updateBidValue: 'Новая ставка',
    },
    actions: {
      TURN_ON: 'Включить',
      TURN_OFF: 'Выключить',
      turnOn: 'Включить',
      turnOff: 'Выключить',
    },
    statuses: {
      PENDING: 'В ожидании',
      ERROR: 'Ошибка',
      COMPLETED: 'Завершено',
    },
  },
  admin: {
    users: {
      users: 'Пользователи',
      editUser: 'Редактирование пользователя',
      createUser: 'Создание пользователя',
      resetPassword: 'Сбросить пароль',
      userCreated: 'Пользователь успешно создан! Временный пароль: {{password}}. Сохраните его.',
      passwordReset: 'Пароль успешно сброшен! Новый пароль: {{password}}. Сохраните его.',
      deleteUser: 'Удалить пользователя',
      deleteUserConfirm: 'Вы уверены что хотите удалить пользователя {{name}}',
      permissions: 'Права доступа',
    },
    jobs: {
      jobs: 'Джобы',
      successJobs: 'Успешные',
      failedJobs: 'Неуспешные',
      warningJobs: 'Частично успешные',
      time: 'Время',
      date: 'Дата',
      status: 'Cтатус',
      name: 'Название',
      platform: 'Платформа',
      errorMessage: 'Текст ошибки',
      interval: 'Продолжительность, мин',
      errorsPlaceholder: 'Нет ошибок',
      errorLogs: 'Логи',
    },
    table: {
      title: 'Настройки таблицы',
      cancelRate: 'Отказы',
      finishRate: 'Выкупы',
      deliveryFee: 'Стоимость доставки',
      cancelFee: 'Стоимость невыкупа',
      vat: 'НДС',
      save: 'Сохранить',
    },
  },
  errors: {
    analyticsDatesRange: 'Период не может быть больше двух месяцев',
    latinChars: 'Поле должно содержать только латинские символы',
    emptyValue: 'Значение не может быть пустым',
    UserWithCodeAlreadyExists: 'Пользователь с таким CRM кодом уже существует',
    required: 'Поле обязательно для заполнения',
    email: 'Почта должна быть валидной',
    passwordConfirmation: 'Пароли должны совпадать',
    url: 'Веб-сайт должен быть действительным URL',
    minZero: 'Должно быть больше нуля',
    minOne: 'Должно быть больше единицы',
    maxPercent: 'Должно быть меньше либо равно 100',
    maxLength: 'Поле не должно содержать более 200 символов',
    number: 'Поле не соответствует числу',
    maxNumber: 'Число не должно содержать более милиона',
    integer: 'Это число не должно быть дробью',
    positive: 'Это число должно быть положительным',
    biggerThanZero: 'Это число должно быть больше 0',
    AccessDenied: 'Неправильно введен логин или пароль',
    networkError: 'Ошибка сети',
    'Internal server error': 'Ошибка на сервере',
    loginError: 'Ошибка входа',
    unknownError: 'Неизвестная ошибка',
    notEmptyBid: 'Ставка не может быть пустой',
    requiredShort: 'Обязательное поле',
  },
};

export default ru;
