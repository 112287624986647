import { Grid, Switch, Typography } from '@mui/material';
import React, { useState } from 'react';

import { DashboardModalProps } from '../models';
import { getStatusData } from '../../utils';
import { styles } from 'components/Common/Shared/dialogStyles';
import Footer from '../Footer';

const DefaultGroupSettings: React.FC<DashboardModalProps> = ({ data, close, changeStatus, refetchData }) => {
  const { isActiveDefault, canBeChanged } = getStatusData(data);
  const [isActive, setIsActive] = useState<boolean>(isActiveDefault);
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async () => {
    if (isActiveDefault !== isActive) {
      setLoading(true);
      await changeStatus(data.id, isActive);
      setLoading(false);
      refetchData();
    }
    close();
  };

  return (
    <>
      <Grid container sx={styles.contentContainer}>
        <Grid container sx={styles.switchContainer}>
          <Typography>{data.name}</Typography>
          <Switch
            value={isActive}
            onChange={() => setIsActive((prev) => !prev)}
            defaultChecked={isActive}
            disabled={!canBeChanged}
          />
        </Grid>
      </Grid>
      <Footer
        onSubmit={onSubmit}
        close={close}
        loading={loading}
        disabled={isActiveDefault === isActive || canBeChanged}
      />
    </>
  );
};

export default DefaultGroupSettings;
