import i18n from 'i18n';

export const AD_LEVELS_OPTIONS = [
  {
    id: 'campaign',
    label: i18n.t('adLevels.campaign'),
  },
  {
    id: 'group',
    label: i18n.t('adLevels.group'),
  },
  {
    id: 'ad',
    label: i18n.t('adLevels.ad'),
  },
];

export const TIME_MODE_OPTIONS = [
  {
    id: 'now',
    label: i18n.t('dashboard.adRules.now'),
  },
  {
    id: 'daily',
    label: i18n.t('dashboard.adRules.daily'),
  },
];

export const UPDATE_BID_TYPE_OPTIONS = [
  {
    id: 'percent',
    label: i18n.t('dashboard.adRules.percent'),
  },
  {
    id: 'value',
    label: i18n.t('dashboard.adRules.value'),
  },
];

export const ACTION_OPTIONS = [
  {
    id: 'pause',
    label: i18n.t('dashboard.adRules.pause'),
  },
  {
    id: 'updateBid',
    label: i18n.t('dashboard.adRules.updateBid'),
  },
];

export const CONDITIONS = ['conversions', 'spend', 'cpa'];

export const CONDITIONS_OPERATION_OPTIONS = [
  {
    id: 'over',
    label: i18n.t('dashboard.adRules.more'),
  },
  {
    id: 'below',
    label: i18n.t('dashboard.adRules.less'),
  },
];
