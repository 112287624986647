export const todayStats = (platformId: string) => `/platform/${platformId}/stats`;
export const login = '/auth/login';
export const refresh = '/auth/refresh';
export const resetPassword = '/auth/reset-password';

export const changePassword = '/auth/change-password';
export const adsByGroup = (id: string, platformId: string) => `/platform/${platformId}/stats/group/${id}/ads`;
export const groupsByCampaign = (id: string, platformId: string) =>
  `/platform/${platformId}/stats/campaign/${id}/groups`;
export const campaignsByAdvertiser = (id: string, platformId: string) =>
  `/platform/${platformId}/stats/${id}/campaigns`;
export const changeCampaignStatus = (id: string, platformId: string) =>
  `/platform/${platformId}/campaigns/${id}/status`;
export const changeGroupStatus = (id: string, platformId: string) => `/platform/${platformId}/groups/${id}/status`;
export const changeAdStatus = (id: string, platformId: string) => `/platform/${platformId}/ads/${id}/status`;
export const changeGroupBidAndBudget = (id: string, platformId: string) =>
  `/platform/${platformId}/groups/${id}/bid-and-budget`;
export const changeCampaignBidAndBudget = (id: string, platformId: string) =>
  `/platform/${platformId}/campaigns/${id}/bid-and-budget`;
export const advertisers = (platformId: string) => `/platform/${platformId}/advertisers`;
export const todayStatsExcel = (platformId: string) => `/platform/${platformId}/stats/excel`;
export const fastStatsByAdvertiser = (id: string) => `/fast-stats/${id}/campaigns`;
export const userById = (id: string) => `/users/${id}`;
export const currentUser = '/users/me';
export const users = `/users`;
export const changeTrackedAccount = (id: string) => `/users/${id}/tracked-accounts`;
export const permissions = `/permissions`;

export const pendingOperations = '/event-schedule';
export const pendingOperationsBulk = '/event-schedule/bulk';
export const pendingOperationById = (id: string) => `/event-schedule/${id}`;

export const adRules = '/auto-rules';
export const adRuleById = (id: string) => `/auto-rules/${id}`;
export const history = '/histories';
export const analytics = '/analytics/advertisers';
export const targetologAnalytics = '/analytics/targetologs';

export const tasksCount = '/tasks/count';
export const tasks = '/tasks';
export const tableSettings = '/table-settings';
