import _get from 'lodash.get';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement } from 'chart.js';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers';
import React, { useMemo, useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Card, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from '@mui/material';

import Loader from 'components/Common/Loader';
import { useJobErrorsRequest } from './useJobsErrorsRequest';
import { useJobCountRequest } from './useJobsCountRequest';
import { TABLE_COLUMNS } from './constants';
import { styles } from './styles';
import NewJob from './NewJob/NewJob';

Chart.register(ArcElement);

const Jobs = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const today = new Date();
  const [date, setDate] = useState<Date>(today);

  const { jobCount, loading: loadingJobCount } = useJobCountRequest(date);
  const { jobErrors, loading: jobErrorsLoading, refetchJobErrors } = useJobErrorsRequest(date);

  const data = useMemo(
    () => ({
      labels: [t('admin.jobs.successJobs'), t('admin.jobs.failedJobs'), t('admin.jobs.warningJobs')],
      datasets: [
        {
          data: [jobCount.successNumber, jobCount.errorsNumber, jobCount.warningsNumber],
          backgroundColor: [theme.palette.primary.main, theme.palette.error.main, theme.otherColors.warning],
          hoverOffset: 4,
        },
      ],
    }),
    [t, theme, jobCount]
  );

  return (
    <Box>
      <Grid item>
        <Typography variant="h1">{t('admin.jobs.jobs')}</Typography>
      </Grid>
      <Card sx={styles.card}>
        <Box justifyContent={'space-between'} display={'flex'} alignItems={'center'}>
          <Box sx={styles.datePickerContainer}>
            <DatePicker value={date} onChange={(date: any) => setDate(date)} format="dd.MM.yyyy" maxDate={today} />
          </Box>
          <Box mr={2}>
            <NewJob onSuccess={refetchJobErrors} />
          </Box>
        </Box>
        <Box sx={styles.chartContainer}>{loadingJobCount ? <Loader /> : <Doughnut data={data} />}</Box>
        <Typography variant="h3" mb={2} sx={{ px: '20px' }}>
          {t('admin.jobs.errorLogs')}
        </Typography>
        <Box sx={styles.tableContainer}>
          <Table sx={styles.table}>
            <TableHead>
              <TableRow>
                {TABLE_COLUMNS.map(({ title, key }) => (
                  <TableCell key={key} sx={styles.headCell}>
                    {t(title)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {jobErrors.length > 0 ? (
                jobErrors.map((errorLog) => (
                  <TableRow sx={styles.bodyRow} key={errorLog._id}>
                    {TABLE_COLUMNS.map(({ render, key, cellStyles }) => (
                      <TableCell key={key} sx={styles.bodyCell} style={cellStyles}>
                        {render ? render(errorLog) : _get(errorLog, key, '')}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={TABLE_COLUMNS.length}>
                    {jobErrorsLoading ? (
                      <Loader />
                    ) : (
                      <Box sx={styles.placeholderContainer}>
                        <InfoIcon sx={styles.placeholderIcon} />
                        <Typography sx={styles.placeholder}>{t('admin.jobs.errorsPlaceholder')}</Typography>
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </Card>
    </Box>
  );
};

export default Jobs;
