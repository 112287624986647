import { useState } from 'react';

import { resetPassword as resetPasswordEndpoint } from 'constants/endpoints';
import ApiClient from 'services/apiClient';

interface ResetPasswordData {
  userId: string;
  email: string;
}

export const useResetPassword = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const resetPassword = async ({ userId, email }: ResetPasswordData) => {
    try {
      setLoading(true);
      const res = await ApiClient.POST(resetPasswordEndpoint, { body: { email, userId } });
      return res;
    } catch (e) {
      console.log((e as any).statusText);
    } finally {
      setLoading(false);
    }
  };

  return {
    resetPassword,
    loading,
  };
};
