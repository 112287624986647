import { useCallback, useEffect } from 'react';
import { useLocalStorage } from 'react-use';
import React, { createContext, Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';

import { getDataFromLocalStorage, setDataToLocalStorage } from 'utils/localStorage';
import { Countries } from 'components/CountrySelect/models';
import { useAccountFilter } from 'context/AccountFilter';
import { usePlatform } from 'hooks/usePlatform';
import { roundToNearestDay } from 'utils/date';
import startOfDay from 'date-fns/startOfDay';

type OrderBy = 1 | -1;

export interface SortField {
  field: string;
  order: OrderBy;
}

interface IDashboardFilterContext {
  onSort: (key: string) => void;
  sort: SortField | null;
  country: Countries;
  setCountry: Dispatch<SetStateAction<Countries>>;
  setDashboardError: Dispatch<SetStateAction<string>>;
  dashboardError: string;
  currentDate: { startDate: Date; endDate: Date };
  onDateChange: Dispatch<SetStateAction<{ startDate: Date; endDate: Date }>>;
  activeRows: string[];
  isFilterActive: boolean;
  toggleFilter: () => void;
  setFilter: (newIds: string[]) => void;
  offerNameFilter: string;
  setOfferNameFilter: Dispatch<SetStateAction<string>>;
  offerFilterOptions: string[];
  setOfferFilterOptions: Dispatch<SetStateAction<string[]>>;
}

const DashboardFilterContext = createContext<IDashboardFilterContext>({} as IDashboardFilterContext);
const DashboardFilterProvider: React.FC = ({ children }) => {
  const platform = usePlatform();
  const [dashboardError, setDashboardError] = useState<string>('');
  const { toggleFilter, activeRows, setFilter, isFilterActive } = useAccountFilter();
  const [offerNameFilter, setOfferNameFilter] = useState<string>('all');
  const [offerFilterOptions, setOfferFilterOptions] = useState<string[]>([]);
  const [currentDate, setCurrentDate] = React.useState(() => {
    const savedDate = getDataFromLocalStorage('dashboardPeriod');
    return {
      startDate: roundToNearestDay(savedDate ? new Date(savedDate.startDate) : startOfDay(new Date())),
      endDate: roundToNearestDay(savedDate ? new Date(savedDate.endDate) : startOfDay(new Date())),
    };
  });
  const [sort, setSort] = useState<SortField | null>({ field: 'spend', order: -1 });
  const [country, setCountry] = useLocalStorage<Countries>('dashboardCountry', Countries.RB);

  const handleDateChange = useCallback(({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
    setDataToLocalStorage('dashboardPeriod', { startDate: startDate.toISOString(), endDate: endDate.toISOString() });
    setCurrentDate({ startDate, endDate });
  }, []);

  const handleSortChange = useCallback(
    (columnName: string) => {
      if (columnName !== sort?.field) {
        setSort({ field: columnName, order: -1 });
        return;
      }

      setSort((prev) => ({
        field: columnName,
        order: prev && prev.order === 1 ? -1 : 1,
      }));
    },
    [sort?.field]
  );

  useEffect(() => {
    setOfferNameFilter('all');
  }, [platform]);

  const memoValue = useMemo(
    () => ({
      sort,
      country,
      setCountry,
      currentDate,
      onDateChange: handleDateChange,
      onSort: handleSortChange,
      activeRows,
      isFilterActive,
      toggleFilter,
      setFilter,
      offerNameFilter,
      setOfferNameFilter,
      offerFilterOptions,
      setOfferFilterOptions,
      setDashboardError,
      dashboardError,
    }),
    [
      activeRows,
      country,
      dashboardError,
      setDashboardError,
      currentDate,
      handleDateChange,
      handleSortChange,
      isFilterActive,
      offerNameFilter,
      offerFilterOptions,
      setCountry,
      setFilter,
      setOfferNameFilter,
      sort,
      toggleFilter,
    ]
  );

  return (
    // @ts-ignore
    <DashboardFilterContext.Provider value={memoValue}>{children}</DashboardFilterContext.Provider>
  );
};

export const useDashboardFilterContext = () => {
  const contextValue = useContext(DashboardFilterContext);
  return contextValue;
};

export default DashboardFilterProvider;
