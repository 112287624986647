export interface Analytic {
  key: AnalyticMetrics;
  body: {
    value: number;
    monthValues: MonthValue[];
  };
}

export interface MonthValue {
  value: number;
  day: string;
}

export enum AnalyticMetrics {
  spend = 'spend',
  conversion = 'conversion',
  costPerConversion = 'cost_per_conversion',
  vp2 = 'vp2',
  vp2Single = 'vp2Single',
  romi = 'romi',
  cpl = 'cpl',
}
