import { useEffect, useState } from 'react';

import { permissions as permissionsEndpoint } from 'constants/endpoints';
import { Permission } from 'models/permission';
import ApiClient from 'services/apiClient';

export const usePermissions = () => {
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getUsers = async () => {
    try {
      setLoading(true);
      const res = await ApiClient.GET(permissionsEndpoint);
      setPermissions(res);
    } catch (e) {
      console.log((e as any).statusText);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  return {
    permissions,
    loading,
    refetch: getUsers,
  };
};
