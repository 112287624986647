import { useCallback, useEffect, useState } from 'react';

import { tasks as tasksEndpoint } from 'constants/endpoints';
import { customDateFormat } from 'utils/date';
import ApiClient from 'services/apiClient';
import { Task } from './models';

export const useJobErrorsRequest = (date: Date) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [jobErrors, setJobErrors] = useState<Task[]>([]);

  const getJobErrors = useCallback(async () => {
    try {
      setLoading(true);
      const res = await ApiClient.GET(tasksEndpoint, {
        params: {
          date: customDateFormat(date, 'YYYY-MM-DD'),
        },
      });
      setJobErrors(res);
    } catch (e) {
      console.log((e as any).statusText);
    } finally {
      setLoading(false);
    }
  }, [date]);

  useEffect(() => {
    getJobErrors();
  }, [getJobErrors]);

  return {
    loading,
    jobErrors,
    refetchJobErrors: getJobErrors,
  };
};
