import { useEffect, useState } from 'react';
import { targetologAnalytics } from 'constants/endpoints';
import ApiClient from 'services/apiClient';

export const useCompetitiveRequest = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [analytics, setAnalytics] = useState<any>(null);
  const [date, setDate] = useState<Date | null>(new Date());
  const [level, setLevel] = useState<'day' | 'month'>('day');

  const getAnalytics = async () => {
    try {
      if (!date) {
        return;
      }
      setLoading(true);
      const res = await ApiClient.GET(targetologAnalytics, {
        params: {
          startDate: date.toISOString(),
          endDate: date.toISOString(),
          level,
        },
      });

      setAnalytics({ ...res.payload, updatedAt: res.updatedAt });
    } catch (e) {
      console.log((e as any).statusText);
      setAnalytics(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAnalytics();
    const inteval = setInterval(getAnalytics, 60000);
    return () => {
      clearInterval(inteval);
    };
  }, [date, level]);

  return {
    loading,
    data: analytics,
    fetchAnalytics: getAnalytics,
    setDate,
    date,
    setLevel,
    level,
  };
};
