import React from 'react';
import { useMediaQuery } from 'hooks/useMediaQuery';
import MobileDashboardHeader from './MobileDashboardHeader';
import DesktopDashboardHeader from './DesktopDashboardHeader';

const DashboardHeader: React.FC = () => {
  const { isMobile, isDesktop } = useMediaQuery();

  return (
    <>
      {isMobile && <MobileDashboardHeader />}
      {isDesktop && <DesktopDashboardHeader />}
    </>
  );
};

export default DashboardHeader;
