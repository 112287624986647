import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Users from 'components/Admin/Users';
import Jobs from 'components/Admin/Jobs';
import Variables from 'components/Admin/Variables';

import AdminWrapper from './AdminWrapper';

const AdminRouter = () => (
  <Routes>
    <Route index element={<Navigate to="/admin/users" replace />} />
    <Route path="" element={<AdminWrapper />}>
      <Route path="users" element={<Users />} />
      <Route path="jobs" element={<Jobs />} />
      <Route path="variables" element={<Variables />} />
      <Route path="*" element={<Navigate to="/admin/users" replace />} />
    </Route>
  </Routes>
);

export default AdminRouter;
