import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { useLocalStorage } from 'react-use';

interface IPriorityContext {
  changePriority: (id: string, priority: boolean) => void;
  checkPriority: (id: string) => boolean;
}

const PriorityContext = createContext<IPriorityContext>({} as IPriorityContext);
const PriorityProvider: React.FC = ({ children }) => {
  const [priorityRows, setPriorityRows] = useLocalStorage<string[]>('priorityRows', []);

  const changePriority = useCallback(
    (id: string, priority: boolean) => {
      if (priority && priorityRows?.includes(id)) return;
      if (!priority && !priorityRows?.includes(id)) return;
      const getNewPriorityRows = () => {
        if (!priorityRows && priority) return [id];
        if (priorityRows && priority) return [...priorityRows, id];
        if (priorityRows && !priority) return priorityRows.filter((prevId) => prevId !== id);
      };
      const newPriorityRows = getNewPriorityRows();
      setPriorityRows(newPriorityRows);
    },
    [priorityRows, setPriorityRows]
  );

  const checkPriority = useCallback((id: string) => !!priorityRows?.includes(id), [priorityRows]);

  const memoValue = useMemo(
    () => ({
      changePriority,
      checkPriority,
    }),
    [changePriority, checkPriority]
  );

  return <PriorityContext.Provider value={memoValue}>{children}</PriorityContext.Provider>;
};

export const usePriorityContext = () => {
  const contextValue = useContext(PriorityContext);
  return contextValue;
};

export default PriorityProvider;
