import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { TiktokGroupSettingsFormFields, TiktokGroupSettingsFormValues, VALIDATION_SCHEMA } from './form';
import { useUpdateGroupBudgetAndRate } from '../../hooks/useUpdateGroupBudgetAndRate';
import { StatsItem } from 'models/stats';

interface Props {
  data: StatsItem;
  close: () => void;
  refetchData: () => void;
}

export const useTiktokGroupSettingsForm = ({ data, close, refetchData }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { updateGroupBudgetAndRate } = useUpdateGroupBudgetAndRate();
  const defaultValues = { budget: data.budget || 0, rate: data.bid || 0 };
  const { handleSubmit, control, watch } = useForm<TiktokGroupSettingsFormValues>({
    mode: 'onBlur',
    defaultValues,
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const [currentBudget, currentRate] = watch([
    TiktokGroupSettingsFormFields.budget,
    TiktokGroupSettingsFormFields.rate,
  ]);

  const onSubmit = handleSubmit(
    async ({ budget, rate }) => {
      if (!data.id) return;
      setLoading(true);
      if (budget !== defaultValues.budget || rate !== defaultValues.rate) {
        await updateGroupBudgetAndRate({ id: data.id, data: { budget, bid: rate } });
        refetchData();
      }
      setLoading(false);
      close();
    },
    (e) => console.log(e)
  );

  return {
    control,
    onSubmit,
    loading,
    disabled: Number(currentBudget) === defaultValues.budget && Number(currentRate) === defaultValues.rate,
  };
};
