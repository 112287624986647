import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { IconButton, Tooltip } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';

import { useDashboardFilterContext } from 'context/DashboardFilter';
import { todayStatsExcel } from 'constants/endpoints';
import { usePlatform } from 'hooks/usePlatform';
import ApiClient from 'services/apiClient';
import { useDialog } from 'hooks/useDialog';
import { Countries } from 'components/CountrySelect/models';
import ExcelDialog from './ExcelDialog';
import { Levels } from './models';

const styles = {
  icon: {
    color: (theme: any) => theme.palette.primary.main,
  },
};

const Excel: React.FC = () => {
  const { t } = useTranslation();
  const platform = usePlatform();
  const { activeRows } = useDashboardFilterContext();
  const { openDialog, closeDialog, isDialogOpen } = useDialog();

  const handleExport = async ({
    level,
    dateFrom,
    dateTo,
    country,
    unit,
  }: {
    level: Levels;
    dateFrom: string;
    dateTo: string;
    country: Countries;
    unit?: string;
  }) => {
    try {
      const res = await ApiClient.POST(todayStatsExcel(platform), {
        body: {
          level,
          ids: activeRows,
          dateFrom,
          dateTo,
          unit,
          ...(country && country !== Countries.all ? { country } : null),
        },
        responseType: 'blob',
      });
      const blob = await res.blob();
      const file = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      const dateFromFormatted = moment(dateFrom).format('DD/MM/YYYY');
      const dateToFormatted = moment(dateTo).format('DD/MM/YYYY');
      const fileNameDate =
        dateFromFormatted === dateToFormatted ? dateFromFormatted : `${dateFromFormatted}-${dateToFormatted}`;
      a.href = file;
      a.download = `${fileNameDate}_${level}_${country ? country : ''}.xlsx`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Tooltip title={t('common.export')}>
        <IconButton onClick={openDialog}>
          <GetAppIcon sx={styles.icon} />
        </IconButton>
      </Tooltip>
      {isDialogOpen && <ExcelDialog onClose={closeDialog} onSubmit={handleExport} />}
    </>
  );
};

export default Excel;
