import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { TableCell, TextField } from '@mui/material';

import { useDebounce } from 'hooks/useDebounce';
import { Column, Params } from './models';

interface TableFilterProps<T> {
  column: Column<T>;
  setFilters: (filters: any) => void;
}

const TableFilter = <T extends Params<T>>({ column, setFilters }: TableFilterProps<T>) => {
  const [value, setValue] = useState<string>('');
  const debouncedValue = useDebounce(value, 500);

  const handleFilterChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.currentTarget.value);
  }, []);

  useEffect(() => {
    const { key, compareFunction } = column;
    setFilters((prevState: any) => ({
      ...prevState,
      [key]: { value, compareFunction },
    }));
  }, [debouncedValue]);

  return (
    <TableCell key={column.title}>
      <TextField value={value} onChange={handleFilterChange} />
    </TableCell>
  );
};

export default TableFilter;
