import React from 'react';
import { TextField, Typography } from '@mui/material';
import { useController, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import _get from 'lodash.get';

import { CommonTextFieldProps } from './models';
import { useField } from '@mui/x-date-pickers/internals';

const CommonTextField: React.FC<CommonTextFieldProps> = ({
  control,
  name,
  label,
  width = '100%',
  defaultValue,
  labelAbove = false,
  placeholder = '',
  description = '',
  withTooltip,
  ...props
}) => {
  const { t } = useTranslation();
  const {
    field: { value, ...field },
    formState: { errors },
  } = useController({
    name,
    control,
    defaultValue,
  });

  const error = _get(errors, name);
  const errorMessage = error ? t(`${_get(error, 'message')}`) : '';

  return (
    <>
      <Typography variant="h5" sx={{ display: 'flex', justifyContent: 'flex-start' }}>
        {label}
      </Typography>
      <TextField
        placeholder={placeholder}
        error={!!error}
        label={''}
        inputRef={field.ref}
        helperText={errorMessage}
        value={value}
        {...field}
        {...props}
        sx={{ width, ...props.sx }}
      />
    </>
  );
};
export default CommonTextField;
