import { Platforms } from 'constants/platforms';
import { Countries } from './models';

const DEFAULT_COUNTRY_OPTIONS = [
  {
    id: Countries.all,
    label: 'dashboard.allCountries',
  },
];

const ALL_COUNTRY_OPTIONS = [
  ...DEFAULT_COUNTRY_OPTIONS,
  {
    id: Countries.RB,
    label: 'dashboard.belarus',
  },
  {
    id: Countries.KZ,
    label: 'dashboard.kazakhstan',
  },
];

export const COUNTRY_OPTIONS_BY_PLATFORM: { [key in Platforms]: { id: Countries; label: string }[] } = {
  [Platforms.yandex]: ALL_COUNTRY_OPTIONS,
  [Platforms.google]: ALL_COUNTRY_OPTIONS,
  [Platforms.facebook]: ALL_COUNTRY_OPTIONS,
  [Platforms.tiktok]: ALL_COUNTRY_OPTIONS,
};
