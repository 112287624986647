import React from 'react';
import _isempty from 'lodash.isempty';
import { useTranslation } from 'react-i18next';
import { Button, Drawer, FormControl, Grid, IconButton, MenuItem, Select, Typography, useTheme } from '@mui/material';

import { ReactComponent as FilterAccountsIcon } from 'assets/svg/filterAccounts.svg';
import MobileDatePicker from 'components/Common/MobileDatePicker';
import { useDialog } from 'hooks/useDialog';
import { useAuth } from 'context/Auth';

import { useAnalyticsFilters } from '../AnalyticsFiltersContext';
import { COUNTRY_OPTIONS, DEFAULT_OPTION } from '../constants';
import { specificStyles } from './styles';
import { styles } from '../styles';

interface MobileAnalyticsFiltersProps {
  offerNames: string[];
  fetchAnalytics: () => void;
}

const xs = 8;
const mt = 1;

const MobileAnalyticsFilters: React.FC<MobileAnalyticsFiltersProps> = ({ offerNames, fetchAnalytics }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const today = new Date();
  const twoMonthFromToday = new Date();
  twoMonthFromToday.setMonth(today.getMonth() - 2);
  const {
    authData: { user },
  } = useAuth();

  const {
    period,
    userId,
    country,
    platform,
    offerName,
    usersOptions,
    platformOptions,
    handleUserIdChange,
    handleCountryChange,
    handlePeriodChange,
    handlePlatformChange,
    handleOfferNameChange,
    errors,
  } = useAnalyticsFilters();

  const { isDialogOpen, openDialog, closeDialog } = useDialog();

  return (
    <>
      <IconButton onClick={openDialog} sx={specificStyles.filterButton}>
        <FilterAccountsIcon fill={theme.palette.primary.main} />
      </IconButton>
      <Drawer
        open={isDialogOpen}
        onClose={closeDialog}
        anchor="bottom"
        PaperProps={{
          sx: specificStyles.drawer,
        }}>
        <Grid container mb={2} columnSpacing={2} flexDirection="column" sx={specificStyles.container}>
          <Grid item xs={xs} mt={mt}>
            <MobileDatePicker
              name="period"
              value={period}
              onChange={handlePeriodChange}
              maxDate={new Date()}
              label={t('analytics.period')}
              error={errors['period']}
            />
          </Grid>
          <Grid item xs={xs} mt={mt}>
            <FormControl fullWidth>
              <Typography variant="h5">{t('analytics.offerName')}</Typography>
              <Select sx={styles.select} value={offerName} onChange={handleOfferNameChange} variant="outlined">
                <MenuItem value={DEFAULT_OPTION.id}>{t(DEFAULT_OPTION.label)}</MenuItem>
                {offerNames?.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={xs} mt={mt}>
            <FormControl fullWidth>
              <Typography variant="h5">{t('analytics.platform')}</Typography>
              <Select sx={styles.select} value={platform} onChange={handlePlatformChange} variant="outlined">
                <MenuItem value={DEFAULT_OPTION.id}>{t(DEFAULT_OPTION.label)}</MenuItem>
                {platformOptions.map((platform) => (
                  <MenuItem key={platform} value={platform}>
                    {t(`platforms.${platform as string}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={xs} mt={mt}>
            <FormControl fullWidth>
              <Typography variant="h5">{t('analytics.country')}</Typography>
              <Select sx={styles.select} value={country} onChange={handleCountryChange} variant="outlined">
                <MenuItem value={DEFAULT_OPTION.id}>{t(DEFAULT_OPTION.label)}</MenuItem>
                {COUNTRY_OPTIONS.map(({ id, label }) => (
                  <MenuItem key={id} value={id}>
                    {t(label)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {user?.isAdmin && (
            <Grid item xs={xs} mt={mt}>
              <FormControl fullWidth>
                <Typography variant="h5">{t('analytics.user')}</Typography>
                <Select sx={styles.select} value={userId} onChange={handleUserIdChange} variant="outlined">
                  {usersOptions.map(({ label, id }) => (
                    <MenuItem key={id} value={id}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
        <Grid sx={specificStyles.footer}>
          <Button variant="contained" color={'primary'} onClick={fetchAnalytics} disabled={!_isempty(errors)}>
            {t('common.save')}
          </Button>
          <Button onClick={closeDialog} color={'primary'}>
            {t('common.cancel')}
          </Button>
        </Grid>
      </Drawer>
    </>
  );
};

export default MobileAnalyticsFilters;
