export const styles = {
  card: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    alignItems: 'center',
  },
  backArrowContainer: {
    width: '100%',
    paddingTop: '20px',
    paddingLeft: '30px',
    boxSizing: 'border-box',
  },
  title: {
    textAlign: 'center',
    color: (theme: any) => theme.palette.primary.main,
    marginBottom: '16px',
    marginTop: '40px',
  },
  userContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  platformsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '30px',
    flexDirection: 'column',
  },
  link: {
    marginTop: '16px',
    textDecoration: 'none',
  },
  platform: {
    display: 'flex',
    borderRadius: '4px',
    boxShadow: '0px 5px 14px rgba(45, 55, 72, 0.1)',
    color: (theme: any) => theme.palette.primary.main,
    textTransform: 'capitalize',
    alignItems: 'center',
    padding: '0 16px',
    width: 200,
  },
  platformIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
  },
  logoutContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    left: '30px',
    bottom: '50px',
    cursor: 'pointer',
  },
  exitText: {
    color: (theme: any) => theme.palette.warning.main,
  },
  filterAccountsButton: {
    marginTop: '24px',
    width: '250px',
  },
  backButton: {
    padding: 0,
  },
  icon: {
    marginRight: '10px',
  },
};
