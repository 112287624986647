export const styles = {
  datePickerContainer: {
    py: 2,
    px: '20px',
  },
  card: {
    width: '100%',
  },
  chartContainer: {
    width: '300px',
    height: '300px',
  },
  tableContainer: {
    mt: 2,
    px: '20px',
    paddingBottom: '20px',
  },
  placeholderContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  placeholderIcon: {
    width: '30px',
    height: '30px',
    color: (theme: any) => theme.otherColors.lightGray,
    mb: 1,
  },
  placeholder: {
    color: (theme: any) => theme.otherColors.lightGray,
  },
  headCell: (theme: any) => ({
    fontSize: '12px',
    color: theme.otherColors.darkGray,
    textTransform: 'uppercase',
    borderTop: `1px solid ${theme.otherColors.lightGray}`,
    borderLeft: `1px solid ${theme.otherColors.lightGray}`,
    position: 'sticky',
    top: 0,
    zIndex: 1,
    background: 'white',
    '&:last-of-type': {
      borderRight: `1px solid ${theme.otherColors.lightGray}`,
    },
  }),
  bodyCell: (theme: any) => ({
    fontSize: '14px',
    borderLeft: `1px solid ${theme.otherColors.lightGray}`,
    borderBottom: `1px solid ${theme.otherColors.lightGray}`,
    '&:last-of-type': {
      borderRight: `1px solid ${theme.otherColors.lightGray}`,
    },
  }),
  bodyRow: {
    height: '50px',
  },
  table: {
    borderCollapse: 'separate',
  },
};
