import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import _groupBy from 'lodash.groupby';
import _sortBy from 'lodash.sortby';

import { Permission } from 'models/permission';
import { User } from 'models/user';

import { useEditUser } from './useEditUser';
import { useCreateUser } from './useCreateUser';
import { useResetPassword } from './useResetPassword';
import { getSelectedPermissions } from '../utils';
import { getNewUserFormSchema, NewUserFormValues, VALIDATION_SCHEMA } from '../form';

const getInitialValues = (user: User | null, permissions: Permission[]) => {
  const defaultValues: { [key: string]: any } = {
    name: user?.name || '',
    email: user?.email || '',
    isAdmin: user?.isAdmin,
  };
  const userPermissions = user?.permissions?.map(({ _id }) => _id);
  permissions.forEach(({ _id }) => {
    defaultValues[_id] = userPermissions?.includes(_id);
  });
  return defaultValues;
};

interface Props {
  user: User | null;
  onSuccess: () => void;
  onCreate: (user: User) => void;
  onSuccessPasswordReset: (user: User) => void;
  refetch: () => void;
  permissions: Permission[];
}

export const useNewUserForm = ({ user, onSuccess, onCreate, onSuccessPasswordReset, refetch, permissions }: Props) => {
  const { createUser, loading: createLoading } = useCreateUser();
  const { editUser, loading: editLoading } = useEditUser();
  const { resetPassword, loading: generatePasswordLoading } = useResetPassword();

  const permissionsByDashboard = useMemo(() => _groupBy(_sortBy(permissions, 'operation'), 'resource'), [permissions]);
  const operations = useMemo(() => Object.keys(_groupBy(permissions, 'operation')).sort(), [permissions]);

  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { isValid },
  } = useForm<NewUserFormValues>({
    mode: 'onBlur',
    defaultValues: getInitialValues(user, permissions),
    // @ts-ignore
    resolver: yupResolver(getNewUserFormSchema(permissions)),
  });

  const onSubmit = handleSubmit(async (formValues: NewUserFormValues) => {
    const { email, isAdmin, name, code, ...permissions } = formValues;
    const selectedPermissions = getSelectedPermissions(permissions);
    const values = { email, isAdmin, name, permissions: selectedPermissions, code };
    if (!user) {
      const data = await createUser(values, refetch);
      if (!data) return;
      onCreate(data);
      return;
    }

    const data = await editUser(
      {
        id: user._id,
        data: values,
      },
      refetch
    );
    if (!data) return;
    onSuccess();
    return;
  });

  const onGenerateNewPassword = async () => {
    if (!user) return;
    const data = await resetPassword({ email: user.email, userId: user._id });
    if (!data) return;
    onSuccessPasswordReset(data);
    return;
  };

  return {
    onSubmit,
    control,
    reset,
    getValues,
    isValid,
    onGenerateNewPassword,
    isLoading: createLoading || editLoading || generatePasswordLoading,
    permissionsByDashboard,
    operations,
  };
};
