import React, { useState } from 'react';
import { Box, IconButton } from '@mui/material';

import { ReactComponent as NotificationBellIcon } from 'assets/svg/notification-bell.svg';

import NotificationsProvider from './NotificationsContext';
import NotificationsSidebar from './NotificationsSidebar';
import { styles } from './styles';

const Notifications: React.FC = () => {
  const [isOpen, setOpen] = useState<boolean>(false);

  const open = () => setOpen(true);
  const close = () => setOpen(false);

  return (
    <>
      <NotificationsSidebar close={close} isOpen={isOpen} />
      <IconButton sx={styles.iconButton} onClick={open}>
        <Box sx={styles.buttonContainer}>
          <NotificationBellIcon />
        </Box>
      </IconButton>
    </>
  );
};

const NotificationsWrapper: React.FC = () => (
  <NotificationsProvider>
    <Notifications />
  </NotificationsProvider>
);

export default NotificationsWrapper;
