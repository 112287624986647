import { useEffect, useMemo, useState } from "react";

import { users as usersEndpoint } from "constants/endpoints";
import ApiClient from "services/apiClient";
import { User } from "models/user";

interface GetUsersOptions {
  skip?: boolean;
}

export const useUsers = ({ skip }: GetUsersOptions = {}) => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getUsers = async () => {
    try {
      setLoading(true);
      const res = await ApiClient.GET(usersEndpoint);
      setUsers(res);
    } catch (e) {
      console.log((e as any).statusText);
    } finally {
      setLoading(false);
    }
  };

  const usersOptions = useMemo(
    () =>
      users.map(({ _id, name }) => ({
        id: _id,
        label: name,
      })),
    [users],
  );

  useEffect(() => {
    if (skip) return;
    getUsers();
  }, []);

  return {
    users,
    loading,
    usersOptions,
    refetch: getUsers,
  };
};
