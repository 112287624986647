import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, Grid, IconButton, InputAdornment, Typography } from '@mui/material';

import { dialogStyles, styles } from 'components/Common/Shared/dialogStyles';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import CommonTextField from 'components/Common/CommonTextField';
import CommonCheckbox from 'components/Common/CommonCheckbox';
import LoadingButton from 'components/Common/LoadingButton';
import CommonSelect from 'components/Common/CommonSelect';

import { AdRule } from 'models/adRule';
import { useDialog } from 'hooks/useDialog';
import { Advertiser } from 'models/advertiser';
import {
  ACTION_OPTIONS,
  AD_LEVELS_OPTIONS,
  CONDITIONS,
  CONDITIONS_OPERATION_OPTIONS,
  TIME_MODE_OPTIONS,
  UPDATE_BID_TYPE_OPTIONS,
} from './constants';
import AdvertiserFilter from './AdvertiserFilter';
import { NewAdRuleFormFields } from './form';
import { useNewAdRuleForm } from './hook';

interface NewAdRuleDialogProps {
  close: () => void;
  refetch: () => void;
  adRule?: AdRule;
  advertisers: Advertiser[];
  advertisersLoading: boolean;
}

const specificStyles = {
  secondaryContainer: (theme: any) => ({
    mt: 2,
    flexDirection: 'column',
    padding: '0 31px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 26px',
    },
  }),
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
};

const NewAdRuleDialog: React.FC<NewAdRuleDialogProps> = ({
  close,
  refetch,
  adRule,
  advertisersLoading,
  advertisers,
}) => {
  const { t } = useTranslation();
  const { control, onSubmit, conditionFields, loading, values, selectedAccounts, setSelectedAccounts, disabled } =
    useNewAdRuleForm({ close, refetch, adRule });
  const { openDialog, closeDialog, isDialogOpen } = useDialog();

  return (
    <>
      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <AdvertiserFilter
          close={closeDialog}
          onSave={setSelectedAccounts}
          selectedAccounts={selectedAccounts}
          advertisers={advertisers}
          advertisersLoading={advertisersLoading}
        />
      </Dialog>
      <Dialog open onClose={close} PaperProps={{ sx: dialogStyles.primaryContainer }}>
        <Grid container sx={styles.headerContainer}>
          <Grid item>
            <Typography variant="h3">
              {adRule ? t('dashboard.adRules.editAdRule') : t('dashboard.adRules.newAdRule')}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={close} sx={styles.closeButton} size="large">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container sx={styles.contentContainer}>
          <Grid container spacing={4}>
            <Grid item xs={7}>
              <CommonTextField name={NewAdRuleFormFields.name} control={control} label={t('dashboard.adRules.name')} />
            </Grid>
            <Grid item xs={5}>
              <CommonSelect
                options={AD_LEVELS_OPTIONS}
                name={NewAdRuleFormFields.level}
                control={control}
                label={t('dashboard.adRules.level')}
              />
            </Grid>
            <Grid item xs={6}>
              <CommonSelect
                options={ACTION_OPTIONS}
                name={NewAdRuleFormFields.action}
                control={control}
                label={t('dashboard.adRules.action')}
              />
            </Grid>
            <Grid item xs={6}>
              <CommonSelect
                options={TIME_MODE_OPTIONS}
                name={NewAdRuleFormFields.timeMode}
                control={control}
                label={t('dashboard.adRules.timeMode')}
              />
            </Grid>
            {values.action === 'updateBid' && (
              <>
                <Grid item xs={6}>
                  <CommonSelect
                    options={UPDATE_BID_TYPE_OPTIONS}
                    name={NewAdRuleFormFields.updateBidType}
                    control={control}
                    label={t('dashboard.adRules.updateBidType')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CommonTextField
                    name={NewAdRuleFormFields.updateBidValue}
                    control={control}
                    label={t(
                      values.updateBidType === 'percent'
                        ? 'dashboard.adRules.updateBidValuePercent'
                        : 'dashboard.adRules.updateBidValue'
                    )}
                    InputProps={{
                      endAdornment: values.updateBidType === 'percent' && (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                      startAdornment: values.updateBidType === 'value' && (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Grid item xs={4} sx={specificStyles.secondaryContainer}>
          <Button variant="contained" onClick={openDialog}>
            {t(
              selectedAccounts.length > 0
                ? 'dashboard.adRules.accountsCountDefault'
                : 'dashboard.adRules.selectAccounts',
              {
                count: selectedAccounts.length,
              }
            )}
          </Button>
        </Grid>
        <Grid container sx={specificStyles.secondaryContainer}>
          <Typography variant="h4">{t('dashboard.adRules.conditions')}</Typography>
          {conditionFields.map((field, fieldIndex) => (
            <Grid container key={field.id} spacing={2} my={0.5}>
              <Grid item xs={1} sx={specificStyles.inputContainer}>
                <CommonCheckbox
                  key={field.id}
                  name={`${NewAdRuleFormFields.conditions}[${fieldIndex}].selected`}
                  control={control}
                />
              </Grid>
              <Grid item xs={3} sx={specificStyles.inputContainer}>
                <Typography>{t(`dashboard.adRules.${CONDITIONS[fieldIndex]}`)}</Typography>
              </Grid>
              <Grid item xs={4} sx={specificStyles.inputContainer}>
                <CommonSelect
                  disabled={!values.conditions[fieldIndex].selected}
                  options={CONDITIONS_OPERATION_OPTIONS}
                  name={`${NewAdRuleFormFields.conditions}[${fieldIndex}].operation`}
                  control={control}
                />
              </Grid>
              <Grid item xs={4} sx={specificStyles.inputContainer}>
                <CommonTextField
                  disabled={!values.conditions[fieldIndex].selected}
                  type="number"
                  name={`${NewAdRuleFormFields.conditions}[${fieldIndex}].value`}
                  control={control}
                  InputProps={{
                    startAdornment: CONDITIONS[fieldIndex] !== 'conversions' && (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid container mt={4}>
          <Grid item sx={styles.dialogFooter}>
            <LoadingButton
              variant="contained"
              color="primary"
              sx={styles.confirmButton}
              onClick={onSubmit}
              isLoading={loading}
              disabled={disabled}>
              {t('common.save')}
            </LoadingButton>
            <Button onClick={close} color="primary">
              {t('common.cancel')}
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default NewAdRuleDialog;
