import { number as yupNumber, object as yupObject } from 'yup';

export enum GoogleAdCampaignSettingsFormFields {
  budget = 'budget',
}

export type GoogleAdCampaignSettingsFormValues = {
  [GoogleAdCampaignSettingsFormFields.budget]: number;
};

export const VALIDATION_SCHEMA = yupObject().shape({
  [GoogleAdCampaignSettingsFormFields.budget]: yupNumber().required('errors.required'),
});
