import { getDataFromLocalStorage, removeDataFromLocalStorage, setDataToLocalStorage } from 'utils/localStorage';
import { refresh } from 'constants/endpoints';

const baseUrl = process.env.REACT_APP_API_URL;
const UNAUTHORIZED = 401;

interface IConfig<P = any> {
  params?: P;
  isFormData?: boolean;
  isOauth?: boolean;
  body?: any;
  headers?: any;
  observe?: string;
  responseType?: string;
}

interface IFullConfig<P = any> extends IConfig<P> {
  method?: string;
}

const attachParamsToEndpoint = (endpoint: string, params: any): string => {
  if (Object.keys(params).length === 0 && params.constructor !== Object) {
    return endpoint;
  }

  let createdParams: string = '';

  for (let key in params) {
    if (Array.isArray(params[key])) {
      createdParams =
        createdParams +
        params[key].map((param: string) => `&${encodeURIComponent(key)}=${encodeURIComponent(param)}`).join('');
    } else {
      createdParams = createdParams + `&${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
    }
  }
  return endpoint + '?' + createdParams.slice(1);
};

let isAlreadyFetchingAccessToken = false;
let subscribers: any[] = [];

function onAccessTokenFetched(access_token: any) {
  subscribers.forEach((callback) => callback(access_token));
  subscribers = [];
}

function addSubscriber(callback: any) {
  subscribers.push(callback);
}

const apiClient = async (endpoint: string, { body, isFormData, ...customConfig }: IFullConfig = {}) => {
  const params = {
    ...customConfig?.params,
  };
  const token = await getDataFromLocalStorage('accessToken');
  const headers: HeadersInit = new Headers({
    ...(isFormData ? null : { 'Content-Type': 'application/json' }),
    ...customConfig.headers,
    Authorization: `Bearer ${token}`,
  });

  const config: IFullConfig = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    params,
    headers,
  };

  if (body) {
    config.body = isFormData ? body : JSON.stringify(body);
  }

  endpoint = params ? attachParamsToEndpoint(endpoint, params) : endpoint;

  let results;
  try {
    results = await fetch(`${baseUrl}${endpoint}`, config);
  } catch (err) {
    throw new Error(err as any);
  }

  if (!results.ok) {
    try {
      const json = await results.json();
      if (results.status === UNAUTHORIZED) {
        try {
          const retryOriginalRequest = new Promise((resolve) => {
            addSubscriber(async (accessToken: any) => {
              const newHeaders: HeadersInit = new Headers({
                ...(isFormData ? null : { 'Content-Type': 'application/json' }),
                ...customConfig.headers,
                Authorization: `Bearer ${accessToken}`,
              });

              const newConfig: IFullConfig = {
                ...config,
                headers: newHeaders,
              };
              const results = await fetch(`${baseUrl}${endpoint}`, newConfig);
              if (newConfig.responseType === 'blob') {
                resolve(results);
              }
              try {
                const json = await results.json();
                resolve(json.data);
              } catch (err) {
                resolve(results);
              }
            });
          });
          if (!isAlreadyFetchingAccessToken) {
            try {
              isAlreadyFetchingAccessToken = true;
              const refreshBody = JSON.stringify({ refreshToken: getDataFromLocalStorage('refreshToken') });
              const refreshConfig = { ...config, method: 'POST' };
              refreshConfig.body = refreshBody;

              const refreshResponse = await fetch(`${baseUrl}${refresh}`, refreshConfig);
              const refreshJson = await refreshResponse.json();
              const {
                data: { accessToken, refreshToken, userId },
              } = refreshJson;
              setDataToLocalStorage('accessToken', accessToken);
              setDataToLocalStorage('refreshToken', refreshToken);
              setDataToLocalStorage('userId', userId);
              isAlreadyFetchingAccessToken = false;
              onAccessTokenFetched(accessToken);
            } catch (e) {
              removeDataFromLocalStorage('accessToken');
              removeDataFromLocalStorage('refreshToken');
              removeDataFromLocalStorage('userId');
              window.location.href = '/login';
            }
          }
          return retryOriginalRequest;
        } catch (error) {}
      }
      return Promise.reject(json);
    } catch (error) {
      return Promise.reject(results);
    }
  }

  if (config.responseType === 'blob') {
    return results;
  }

  try {
    const json = await results.json();
    return json.data;
  } catch (err) {
    return results;
  }
};

const GET = async (endpoint: string, config: IConfig = {}) => {
  return await apiClient(endpoint, {
    method: 'GET',
    ...config,
  });
};

const POST = async (endpoint: string, config: IConfig = {}) => {
  return await apiClient(endpoint, {
    method: 'POST',
    ...config,
  });
};

const PUT = (endpoint: string, config: IConfig = {}) => {
  return apiClient(endpoint, {
    method: 'PUT',
    ...config,
  });
};

const PATCH = (endpoint: string, config: IConfig = {}) => {
  return apiClient(endpoint, {
    method: 'PATCH',
    ...config,
  });
};

const DELETE = (endpoint: string, config: IConfig = {}) => {
  return apiClient(endpoint, {
    method: 'DELETE',
    ...config,
  });
};

const ApiClient = { GET, POST, PUT, PATCH, DELETE };

export default ApiClient;
