import React from 'react';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import LoadingButton from 'components/Common/LoadingButton';
import { styles } from 'components/Common/Shared/dialogStyles';

interface FooterProps {
  onSubmit: () => void;
  close: () => void;
  loading?: boolean;
  disabled?: boolean;
}
const Footer: React.FC<FooterProps> = ({ onSubmit, close, loading = false, disabled = false }) => {
  const { t } = useTranslation();
  return (
    <Grid container>
      <Grid item sx={styles.dialogFooter}>
        <LoadingButton
          variant="contained"
          color="primary"
          sx={styles.confirmButton}
          onClick={onSubmit}
          isLoading={loading}
          disabled={disabled}>
          {t('common.save')}
        </LoadingButton>
        <Button onClick={close} color="primary">
          {t('common.cancel')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default Footer;
