import React, { ChangeEvent, useMemo, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  TextField,
  InputAdornment,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useAdvertisers } from 'services/advertisers/useAdvertisers';
import { ReactComponent as SearchIcon } from 'assets/svg/search.svg';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import { useAccountFilter } from 'context/AccountFilter';
import { useDebounce } from 'hooks/useDebounce';

const styles = {
  customTableContainer: {
    overflowX: 'initial',
  },
  checkboxCell: {
    width: 30,
  },
  checkbox: {
    margin: '-5px',
  },
  clickable: {
    cursor: 'pointer',
  },
  closeButton: {
    padding: 0,
  },
  headerContainer: (theme: any) => ({
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 31px',
    height: '71px',
    [theme.breakpoints.down('md')]: {
      padding: '0 26px',
      height: '69px',
    },
  }),
  title: (theme: any) => ({
    fontSize: '24px',
    [theme.breakpoints.down('md')]: {
      fontSize: '18px',
    },
  }),
  table: {
    border: (theme: any) => `1px solid ${theme.otherColors.lightGray}`,
  },
  contentContainer: (theme: any) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 31px',
    paddingTop: '0',
    [theme.breakpoints.down('md')]: {
      padding: '0 26px',
      paddingTop: '10px',
    },
  }),
  tableBody: (theme: any) => ({
    display: 'block',
    maxHeight: 'calc(90vh - 71px - 56px - 24px - 84px)',
    height: 'calc(90vh - 71px - 56px - 24px - 84px)',
    overflow: 'auto',
    [theme.breakpoints.down('md')]: {
      maxHeight: 'calc(80vh - 69px - 56px - 10px - 102px)',
      height: 'calc(80vh - 69px - 56px - 10px - 102px)',
    },
  }),
  searchInput: {
    width: '100%',
    '& .MuiOutlinedInput-input': {
      boxSizing: 'border-box',
      height: '36px',
    },
  },
  searchInputContainer: (theme: any) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '56px',
    border: `1px solid ${theme.otherColors.lightGray}`,
    borderBottom: 'none',
    padding: '0 16px',
    boxSizing: 'border-box',
  }),
  container: {
    overflow: 'hidden',
    position: 'relative',
  },
  tableCell: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: 'none',
  },
  footer: (theme: any) => ({
    bottom: '32px',
    height: '84px',
    backgroundColor: theme.otherColors.lightBlue,
    padding: '0 31px',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    margin: 0,
    borderBottomRightRadius: '4px',
    borderBottomLeftRadius: '4px',
    [theme.breakpoints.down('md')]: {
      padding: '0 26px',
      height: '102px',
    },
  }),
  confirmButton: {
    width: '120px',
    height: '36px',
    boxSizing: 'border-box',
    marginRight: '25px',
  },
  text: {
    fontSize: '12px',
    fontWeight: 600,
  },
  tableRow: {
    display: 'flex',
    width: '100%',
  },
};
interface FilterProps {
  close: () => void;
}

const AdvertiserFilter: React.FC<FilterProps> = ({ close }) => {
  const { activeRows, setFilter } = useAccountFilter();
  const [checkedRows, setCheckedRows] = useState<string[]>(activeRows);
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValue = useDebounce(searchValue, 300);
  const { t } = useTranslation();

  const { advertisers, loading } = useAdvertisers();
  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value);

  const filteredAdvertisers = useMemo(() => {
    if (!debouncedSearchValue) return advertisers;
    return advertisers.filter(({ name }) => name.toLowerCase().includes(debouncedSearchValue.toLowerCase()));
  }, [debouncedSearchValue, advertisers]);

  const handleCheckAll = () => {
    if (!checkedRows.length || checkedRows.length < advertisers.length) {
      setCheckedRows(advertisers.map((item) => item._id));
      return;
    }
    setCheckedRows([]);
  };

  const handleCheck = (id: string) => {
    if (checkedRows.indexOf(id) === -1) {
      setCheckedRows([...checkedRows, id]);
      return;
    }
    setCheckedRows(checkedRows.filter((item) => item !== id));
  };

  const handleSave = async () => {
    setFilter(checkedRows);
    close();
  };

  return (
    <Grid container sx={styles.container}>
      <Grid container sx={styles.headerContainer}>
        <Grid item>
          <Typography variant="h3">{t('dashboard.filterAccounts')}</Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={close} sx={styles.closeButton} size="large">
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Box sx={styles.contentContainer}>
        <Box sx={styles.searchInputContainer}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            placeholder="Search"
            variant="outlined"
            sx={styles.searchInput}
            onChange={handleSearchChange}
            value={searchValue}
          />
        </Box>
        <Table sx={styles.table}>
          <TableBody sx={styles.tableBody}>
            <TableRow sx={styles.tableRow}>
              <TableCell component="td" sx={{ ...styles.checkboxCell, ...styles.tableCell }}>
                <Checkbox
                  size={'small'}
                  color={'primary'}
                  onChange={() => handleCheckAll()}
                  disabled={loading}
                  checked={!!checkedRows.length && checkedRows.length === advertisers.length}
                  indeterminate={!!checkedRows.length && checkedRows.length !== advertisers.length}
                  sx={styles.checkbox}
                />
              </TableCell>
              <TableCell component="th" sx={styles.tableCell}>
                <Typography sx={styles.text}>{t('dashboard.all')}</Typography>
              </TableCell>
            </TableRow>
            {loading && (
              <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '10px' }}>
                <CircularProgress />
              </div>
            )}
            {filteredAdvertisers.map((row) => (
              <TableRow
                sx={{ ...styles.clickable, ...styles.tableRow }}
                key={row._id}
                onClick={() => handleCheck(row._id)}>
                <TableCell component="td" sx={styles.tableCell}>
                  <Checkbox
                    size={'small'}
                    color={'primary'}
                    checked={checkedRows.indexOf(row._id) !== -1}
                    sx={styles.checkbox}
                  />
                </TableCell>
                <TableCell component="td" sx={styles.tableCell}>
                  <Typography sx={styles.text}>{row.name}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <Grid sx={styles.footer}>
        <Button
          disabled={!checkedRows.length}
          variant="contained"
          color={'primary'}
          onClick={handleSave}
          sx={styles.confirmButton}>
          {t('common.save')}
        </Button>
        <Button onClick={close} color={'primary'}>
          {t('common.cancel')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default AdvertiserFilter;
