import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, Grid, IconButton, Typography } from '@mui/material';

import CommonDateTimePicker from 'components/Common/CommonDateTimePicker';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import LoadingButton from 'components/Common/LoadingButton';
import CommonSelect from 'components/Common/CommonSelect';

import { PendingOperationActionsOptions } from '../constants';
import { NewPendingOperationsFormFields } from './form';
import { useNewPendingOperationsForm } from './hook';

export const styles = {
  contentContainer: (theme: any) => ({
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    padding: '0 31px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 26px',
    },
  }),
  secondContentContainer: (theme: any) => ({
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    padding: '0 31px',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      padding: '0 26px',
    },
  }),
  switchContainer: {
    alignItems: 'center',
    marginBottom: '20px',
    flexWrap: 'nowrap',
  },
  inputsContainer: {
    flexWrap: 'nowrap',
  },
  secondInput: {
    marginLeft: '24px',
  },
  confirmButton: {
    width: '120px',
    height: '36px',
    boxSizing: 'border-box',
    marginRight: '25px',
  },
  headerContainer: (theme: any) => ({
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 31px',
    height: '69px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 26px',
    },
  }),
  dialogFooter: (theme: any) => ({
    height: '98px',
    backgroundColor: theme.otherColors.lightBlue,
    padding: '0 26px',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    margin: 0,
    borderBottomRightRadius: '4px',
    borderBottomLeftRadius: '4px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 26px',
      height: '102px',
    },
  }),
  closeButton: {
    padding: 0,
  },
};

export const dialogStyles = {
  primaryContainer: (theme: any) => ({
    width: '780px',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width: '390px',
    },
  }),
};

export interface NewPendingOperationsDialogProps {
  close: () => void;
}

const NewPendingOperationsDialog: React.FC<NewPendingOperationsDialogProps> = ({ close }) => {
  const { t } = useTranslation();
  const { onSubmit, loading, control, selectedCampaigns } = useNewPendingOperationsForm({ close });

  return (
    <Dialog open={true} PaperProps={{ sx: dialogStyles.primaryContainer }}>
      <Grid container sx={styles.headerContainer}>
        <Grid item>
          <Typography variant="h3">{t('dashboard.pendingOperation')}</Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={close} sx={styles.closeButton} size="large">
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container sx={{ ...styles.inputsContainer, flexDirection: 'column', padding: '0 31px', mb: '39px' }}>
        <Grid item>
          <CommonSelect
            control={control}
            variant="outlined"
            label={t('dashboard.action')}
            name={NewPendingOperationsFormFields.action}
            options={PendingOperationActionsOptions}
          />
        </Grid>
        <Grid item mt={2}>
          <CommonDateTimePicker
            label={t('dashboard.date')}
            name={NewPendingOperationsFormFields.date}
            control={control}
            minDate={new Date()}
          />
        </Grid>
        <Grid item mt={2}>
          <Typography>{t('dashboard.pendingOperationCount', { count: selectedCampaigns.length })}</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sx={styles.dialogFooter}>
          <LoadingButton
            variant="contained"
            color="primary"
            sx={styles.confirmButton}
            onClick={onSubmit}
            isLoading={loading}>
            {t('common.save')}
          </LoadingButton>
          <Button onClick={close} color="primary">
            {t('common.cancel')}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default NewPendingOperationsDialog;
