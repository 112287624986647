import React, { useMemo, useState } from 'react';
import { Table, TableContainer, Grid, TableBody, TableHead, Card } from '@mui/material';
import _filter from 'lodash.filter';
import _isempty from 'lodash.isempty';

import Loader from 'components/Common/Loader';
import { Params, Column, Filters } from './models';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import TableFilters from './TableFilters';
import { CrudTableProvider } from './CrudTableContext';

interface CrudTableProps<T> {
  rows: T[];
  columns: Column<T>[];
  onEdit: (row: T) => void;
  onDelete: (row: T) => void;
  onAdd: () => void;
  isLoading?: boolean;
  isActionsDisabled?: (row: T) => boolean;
}

const CrudTable = <T extends Params<T>>({
  columns,
  rows,
  onEdit,
  onDelete,
  onAdd,
  isLoading,
  isActionsDisabled,
}: CrudTableProps<T>) => {
  const [filters, setFilters] = useState<Filters<T>>({});

  const filteredRows = useMemo(() => {
    if (_isempty(filters)) {
      return rows;
    }

    return _filter(rows, (row) => {
      for (const [filterKey, filterObj] of Object.entries(filters)) {
        const { value, compareFunction } = filterObj;
        if (filterKey && value && compareFunction) {
          if (!compareFunction(row, value, filterKey)) {
            return false;
          }
        }
      }

      return true;
    });
  }, [filters, rows]);

  return (
    <Card>
      {isLoading ? (
        <Loader />
      ) : (
        <CrudTableProvider onAdd={onAdd} onEdit={onEdit} onDelete={onDelete}>
          <Grid container>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableHeader columns={columns} />
                  <TableFilters columns={columns} setFilters={setFilters} />
                </TableHead>
                <TableBody>
                  {filteredRows.map((row) => (
                    <TableRow key={row._id} columns={columns} row={row} isActionsDisabled={isActionsDisabled} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </CrudTableProvider>
      )}
    </Card>
  );
};

export default CrudTable;
