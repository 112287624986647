import React from 'react';
import { useUpdateAdRuleRequest } from './hooks/useUpdateAdRule';
import { Switch } from '@mui/material';
import { AdRule } from 'models/adRule';

interface Props {
  adRule: AdRule;
  refetch: () => void;
}

const AdRuleSwitch: React.FC<Props> = ({ adRule, refetch }) => {
  const { updateAdRule, loading } = useUpdateAdRuleRequest(refetch);
  const { _id, isActive } = adRule;

  return (
    <Switch
      disabled={loading}
      value={isActive}
      defaultChecked={isActive}
      onChange={() => updateAdRule(_id, { isActive: !isActive })}
    />
  );
};

export default AdRuleSwitch;
