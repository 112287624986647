import React, { useContext, useEffect, useMemo } from 'react';

import { History } from 'models/history';
import { useHistoryRequest } from './useHistory';

interface NotificationsData {
  notifications: History[];
  notificationsCount: number;
}

const REFETCH_INTERVAL = 60000;

const Notifications = React.createContext<NotificationsData>({
  notifications: [],
  notificationsCount: 0,
});

const NotificationsProvider: React.FC = ({ children }) => {
  const { history, refetchHistory } = useHistoryRequest();

  useEffect(() => {
    const interval = setInterval(() => refetchHistory(), REFETCH_INTERVAL);
    return () => clearInterval(interval);
  }, [refetchHistory]);

  return (
    <Notifications.Provider value={{ notifications: history, notificationsCount: history.length }}>
      {children}
    </Notifications.Provider>
  );
};

export const useNotifications = () => {
  const contextValue = useContext(Notifications);
  return contextValue;
};

export default NotificationsProvider;
