import React from 'react';
import { AppBar } from '@mui/material';

import { useMediaQuery } from 'hooks/useMediaQuery';
import MobileHeader from './MobileHeader';
import DesktopHeader from './DesktopHeader';

const Header = () => {
  const { isMobile, isDesktop } = useMediaQuery();

  return (
    <div>
      <AppBar position="static">
        {isMobile && <MobileHeader />}
        {isDesktop && <DesktopHeader />}
      </AppBar>
    </div>
  );
};

export default Header;
