import React from 'react';
import { TableCell, Typography } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import _isempty from 'lodash.isempty';

import { changeCampaignStatus, changeGroupStatus, changeAdStatus } from 'constants/endpoints';
import { useDashboardFilterContext } from 'context/DashboardFilter';
import { Platforms } from 'constants/platforms';
import ApiClient from 'services/apiClient';
import { AdLevels } from 'constants/levels';

import { useDashboardDialogs } from './Dialogs/useDashboardDialogs';
import DashboardDialog from './Dialogs/DashboardDialog';
import DashboardTableRow from './DashboardTableRow';
import useDashboardData from './useDashboardData';
import styles from './styles';
import { TableColumn } from './constants';

interface Props {
  show: boolean;
  columns: TableColumn[];
  endpoint: string;
  children?: (id: string, isOpen: boolean) => React.ReactNode;
  level: AdLevels;
  platform: Platforms;
  hasEditPermission: boolean;
}

const DashboardList: React.FC<Props> = ({ show, columns, endpoint, children, level, platform, hasEditPermission }) => {
  const { activeRows, dashboardError } = useDashboardFilterContext();
  const { openDashboardDialog, closeDashboardDialog, isDialogOpen, dialogType, dialogData } = useDashboardDialogs();
  const [openRows, setOpenRows] = React.useState<string[]>([]);
  const [updatingStatuses, setUpdatingStatuses] = React.useState<string[]>([]);

  const { forceUpdate, statistics, isLoading } = useDashboardData({
    show,
    level,
    endpoint,
    setUpdatingStatuses,
    platform,
  });

  React.useEffect(() => {
    forceUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRows]);

  React.useEffect(() => {
    if (!show) {
      setOpenRows([]);
    }
  }, [show]);

  if (!show) {
    return null;
  }

  const handleRowClick = (id: string) => {
    if (id === 'overall') return;
    if (openRows.indexOf(id) === -1) {
      setOpenRows([...openRows, id]);
      return;
    }
    setOpenRows(openRows.filter((item) => item !== id));
  };

  const changeStatus = async (id: string, value: boolean) => {
    const endpoints = {
      campaign: changeCampaignStatus(id, platform),
      ad: changeAdStatus(id, platform),
      group: changeGroupStatus(id, platform),
      advertiser: '',
    };
    const statusEndpoint = endpoints[level];
    if (!statusEndpoint) {
      return;
    }
    const newUpdating = [...updatingStatuses, id];
    setUpdatingStatuses(newUpdating);
    try {
      await ApiClient.PUT(statusEndpoint, { body: { status: value } });
    } catch (e) {
      alert((e as any).message);
      setUpdatingStatuses(newUpdating.filter((item) => item !== id));
      return;
    }
  };

  return (
    <>
      {isLoading && (
        <TableRow>
          <TableCell component="td" colSpan={columns.length} style={{ border: 'none' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress size="20px" color="primary" />
            </div>
          </TableCell>
        </TableRow>
      )}
      {!isLoading && !dashboardError && _isempty(statistics) && (
        <TableRow>
          <TableCell component="td" colSpan={columns.length} sx={styles.placeholderCell}>
            <Typography variant="h5" sx={styles.placeholder}>
              Нет данных
            </Typography>
          </TableCell>
        </TableRow>
      )}
      {dashboardError && (
        <TableRow>
          <TableCell colSpan={columns.length} sx={styles.errorCell}>
            <Typography variant="h5" sx={styles.error}>
              {dashboardError}
            </Typography>
          </TableCell>
        </TableRow>
      )}
      {statistics.map((row) => (
        <React.Fragment key={row.id}>
          <DashboardTableRow
            columns={columns}
            level={level}
            platform={platform}
            hasEditPermission={hasEditPermission}
            handleRowClick={handleRowClick}
            refreshData={forceUpdate}
            row={row}
            changeStatus={changeStatus}
            updatingStatuses={updatingStatuses}
            openDashboardDialog={openDashboardDialog}
          />
          {children && children(row.id, openRows.indexOf(row.id) !== -1)}
        </React.Fragment>
      ))}
      {isDialogOpen && dialogData && dialogType && (
        <DashboardDialog
          type={dialogType}
          data={dialogData}
          close={closeDashboardDialog}
          refetchData={forceUpdate}
          changeStatus={changeStatus}
        />
      )}
    </>
  );
};

export default DashboardList;
