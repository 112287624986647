import {
  addDays,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfWeek,
  isSameDay,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import i18next from 'i18next';

const staticRangeHandler = {
  range: {},
  isSelected(range: any) {
    const definedRange = (this.range as any)();
    return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate);
  },
};

export const createStaticRanges = (ranges: any[]) =>
  ranges.map((range) => ({ ...staticRangeHandler, ...range, label: i18next.t(range.label) }));

export const getStaticRanges = () => {
  const defineds = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    startOf3days: startOfDay(addDays(new Date(), -2)),
    startOf7days: startOfDay(addDays(new Date(), -6)),
    startOf10days: startOfDay(addDays(new Date(), -9)),
    startOf30days: startOfDay(addDays(new Date(), -29)),
  };

  return createStaticRanges([
    {
      label: 'dashboard.today',
      range: () => ({
        startDate: defineds.startOfToday,
        endDate: defineds.startOfToday,
      }),
    },
    {
      label: 'dashboard.yesterday',
      range: () => ({
        startDate: defineds.startOfYesterday,
        endDate: defineds.startOfYesterday,
      }),
    },

    {
      label: 'dashboard.last3days',
      range: () => ({
        startDate: defineds.startOf3days,
        endDate: defineds.startOfToday,
      }),
    },
    {
      label: 'dashboard.last7days',
      range: () => ({
        startDate: defineds.startOf7days,
        endDate: defineds.startOfToday,
      }),
    },
    {
      label: 'dashboard.last10days',
      range: () => ({
        startDate: defineds.startOf10days,
        endDate: defineds.startOfToday,
      }),
    },
    {
      label: 'dashboard.forMonth',
      range: () => ({
        startDate: defineds.startOf30days,
        endDate: defineds.startOfToday,
      }),
    },
  ]);
};
