import React from 'react';
import { Box, CircularProgress, Switch } from '@mui/material';

import { useMediaQuery } from 'hooks/useMediaQuery';
import { AdLevels } from 'constants/levels';

import { getStatusData } from '../DashboardTable/utils';
import { CircleColors, CircleStatuses, getStatusKey } from './utils';
import { StatsItem } from 'models/stats';

interface Props {
  data: StatsItem;
  disabled: boolean;
  isStatusUpdating: boolean;
  onChangeStatus: (id: string, value: boolean) => void;
  refreshData: () => void;
  level: AdLevels;
}

const width = 22;
const height = 14;

const styles = {
  circle: (theme: any) => ({
    minWidth: 10,
    minHeight: 10,
    height: 10,
    width: 10,
    borderRadius: 8,
    [theme.breakpoints.down('sm')]: {
      minWidth: 8,
      minHeight: 8,
      height: 8,
      width: 8,
    },
  }),
  container: {
    width: '100%',
    height: '100%',
    p: '10px',
    boxSizing: 'border-box',
  },
  switch: {
    width,
    height,
    padding: 0,
    mx: 1,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: '2px',
      '& .MuiSwitch-thumb': {
        backgroundColor: (theme: any) => theme.otherColors.white,
        boxSizing: 'border-box',
        width: height - 4,
        height: height - 4,
      },
      '&.Mui-checked': {
        transform: `translateX(${width - height}px)`,
        '& + .MuiSwitch-track': {
          opacity: 1,
        },
      },
      '&.Mui-disabled': {
        '& + .MuiSwitch-track': {
          opacity: 1,
        },
      },
    },
    '& .MuiSwitch-track': {
      borderRadius: height / 2,
      border: 0,
    },
  },
};

const StatusCircle: React.FC<Props> = ({ data, disabled, onChangeStatus, isStatusUpdating, refreshData, level }) => {
  const { canBeChanged, isActiveDefault } = getStatusData(data);
  const { isMobile } = useMediaQuery();
  const statusKey = getStatusKey(data.status || 'unknown');
  const statusColor = CircleColors[statusKey];

  const changeStatus = async (e: any) => {
    e.stopPropagation();
    if (disabled || !canBeChanged) {
      return;
    }
    await onChangeStatus(data.id, !isActiveDefault);
    refreshData();
  };

  if (isMobile || level === AdLevels.advertiser) {
    if (isStatusUpdating) {
      return (
        <Box mt={1}>
          <CircularProgress size={10} />
        </Box>
      );
    }
    return (
      <Box sx={styles.container} onClick={changeStatus}>
        <Box style={{ backgroundColor: statusColor }} sx={styles.circle} />
      </Box>
    );
  }

  if (isStatusUpdating) {
    return (
      <Box width={width + 16} display={'flex'} justifyContent={'center'}>
        <CircularProgress size={10} />
      </Box>
    );
  }
  return (
    <Box>
      <Switch
        value={isActiveDefault}
        onChange={changeStatus}
        defaultChecked={isActiveDefault}
        disableRipple
        disabled={disabled || statusKey === CircleStatuses.unknown || statusKey === CircleStatuses.deleted}
        sx={{
          ...styles.switch,
          '& .MuiSwitch-track': {
            backgroundColor: `${statusColor} !important`,
          },
        }}
      />
    </Box>
  );
};

export default StatusCircle;
