export enum HistoryActions {
  CAMPAIGN_ON_BY_SCHEDULED = 'CAMPAIGN_ON_BY_SCHEDULED',
  CAMPAIGN_OFF_BY_SCHEDULED = 'CAMPAIGN_OFF_BY_SCHEDULED',
  GROUP_ON_BY_SCHEDULED = 'GROUP_ON_BY_SCHEDULED',
  CAMPAIGN_OFF_BY_AUTO_RULE = 'CAMPAIGN_OFF_BY_AUTO_RULE',
  GROUP_OFF_BY_AUTO_RULE = 'GROUP_OFF_BY_AUTO_RULE',
  AD_OFF_BY_AUTO_RULE = 'AD_OFF_BY_AUTO_RULE',
  AD_OFF_BY_SCHEDULED = 'AD_OFF_BY_SCHEDULED',
  AD_ON_BY_SCHEDULED = 'AD_ON_BY_SCHEDULED',
  GROUP_OFF_BY_SCHEDULED = 'GROUP_OFF_BY_SCHEDULED',
  CAMPAIGN_BID_UPDATE_BY_AUTO_RULE = 'CAMPAIGN_BID_UPDATE_BY_AUTO_RULE',
  GROUP_BID_UPDATE_BY_AUTO_RULE = 'GROUP_BID_UPDATE_BY_AUTO_RULE',
  AD_BID_UPDATE_BY_AUTO_RULE = 'AD_BID_UPDATE_BY_AUTO_RULE',

  CAMPAIGN_ON = 'CAMPAIGN_ON',
  CAMPAIGN_OFF = 'CAMPAIGN_OFF',
  GROUP_ON = 'GROUP_ON',
  GROUP_OFF = 'GROUP_OFF',
  AD_ON = 'AD_ON',
  AD_OFF = 'AD_OFF',
  CHANGE_BID_AND_BUDGET = 'CHANGE_BID_AND_BUDGET',
  USER_SIGN_IN = 'USER_SIGN_IN',
  UNKNOWN = 'UNKNOWN',
}
export enum HistoryInitiators {
  autoRule = 'autoRule',
  eventSchedule = 'eventSchedule',
}

export interface History {
  _id: string;
  entityId: string;
  initiatorId: string;
  action: HistoryActions;
  entity: any;
  entityName: string;
  initiator: any;
  initiatorName: HistoryInitiators | null;
  createdAt: string;
  updatedAt: string;
}
