import React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import CommonTextField from 'components/Common/CommonTextField';
import { styles } from 'components/Common/Shared/dialogStyles';
import { addCurrencySignToMoneyLabel } from 'utils/currency';

import Footer from '../../Footer';
import { DashboardModalProps } from '../../models';
import { useTiktokGroupSettingsForm } from './hook';
import { TiktokGroupSettingsFormFields } from './form';

const TiktokGroupSettings: React.FC<DashboardModalProps> = ({ data, close, refetchData }) => {
  const { control, onSubmit, loading, disabled } = useTiktokGroupSettingsForm({
    data,
    close,
    refetchData,
  });
  const { t } = useTranslation();

  return (
    <>
      <Grid container sx={styles.secondContentContainer}>
        <Grid container sx={{ ...styles.inputsContainer, mb: '39px' }}>
          <Grid item>
            <CommonTextField
              control={control}
              variant="outlined"
              label={`${addCurrencySignToMoneyLabel(t('dashboard.budget'), data.currency)}`}
              name={TiktokGroupSettingsFormFields.budget}
            />
          </Grid>
          <Grid item sx={styles.secondInput}>
            <CommonTextField
              control={control}
              variant="outlined"
              label={`${addCurrencySignToMoneyLabel(t('dashboard.bid'), data.currency)}`}
              name={TiktokGroupSettingsFormFields.rate}
            />
          </Grid>
        </Grid>
      </Grid>
      <Footer onSubmit={onSubmit} close={close} loading={loading} disabled={disabled} />
    </>
  );
};

export default TiktokGroupSettings;
