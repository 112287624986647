import React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import CommonTextField from 'components/Common/CommonTextField';
import { addCurrencySignToMoneyLabel } from 'utils/currency';

import Footer from '../../Footer';
import { DashboardModalProps } from '../../models';
import { useGoogleGroupSettingsForm } from './hook';
import { GoogleGroupSettingsFormFields } from './form';

const specificStyles = {
  contentContainer: (theme: any) => ({
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    padding: '0 31px',
    marginBottom: '39px',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      padding: '0 26px',
    },
  }),
  switchContainer: {
    alignItems: 'center',
    marginBottom: '20px',
    flexWrap: 'nowrap',
  },
  inputsContainer: {
    flexWrap: 'nowrap',
  },
};
const FacebookGroupSettings: React.FC<DashboardModalProps> = ({ data, close, refetchData }) => {
  const { control, onSubmit, loading, disabled } = useGoogleGroupSettingsForm({ data, close, refetchData });
  const { t } = useTranslation();

  return (
    <>
      <Grid container sx={specificStyles.contentContainer}>
        <Grid container sx={specificStyles.inputsContainer}>
          <Grid item>
            <CommonTextField
              control={control}
              variant="outlined"
              label={`${addCurrencySignToMoneyLabel(t('dashboard.bid'), data.currency)}`}
              name={GoogleGroupSettingsFormFields.rate}
            />
          </Grid>
        </Grid>
      </Grid>
      <Footer onSubmit={onSubmit} close={close} loading={loading} disabled={disabled} />
    </>
  );
};

export default FacebookGroupSettings;
