import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Typography, Card } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as LogoIcon } from 'assets/svg/logo.svg';
import { useAuth } from 'context/Auth';
import { styles } from '../styles';

interface LocationState {
  userId: string;
}

const ChangePassword = () => {
  const { changePassword } = useAuth();
  const { t } = useTranslation();
  const locationState = useLocation().state as LocationState;
  const userId = locationState?.userId;

  useEffect(() => {
    if (!userId) {
      navigate('/login');
    }
  }, []);

  const navigate = useNavigate();
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    changePassword(userId, e.target[0].value, e.target[2].value);
    navigate('/login');
  };

  return (
    <Box sx={styles.wrapper}>
      <form
        onSubmit={handleSubmit}
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Card sx={styles.card}>
          <LogoIcon />
          <Typography variant="h4" sx={styles.loginMessage}>
            {t('login.changePassword')}
          </Typography>
          <Box sx={styles.inputContainer}>
            <Typography variant="h5" sx={styles.inputLabel}>
              {t('login.password')}
            </Typography>
            <TextField
              sx={styles.input}
              name="password"
              placeholder={t('login.enterPassword')}
              variant="outlined"
              type="password"
            />
          </Box>
          <Box sx={styles.inputContainer}>
            <Typography variant="h5" sx={styles.inputLabel}>
              {t('login.confirmPassword')}
            </Typography>
            <TextField
              sx={styles.input}
              name="passwordConfirm"
              type="password"
              variant="outlined"
              placeholder={t('login.enterPasswordAgain')}
            />
          </Box>
          <Button variant="contained" color="primary" type="submit" sx={styles.submitButton}>
            {t('common.confirm')}
          </Button>
          <Typography variant="body2" sx={styles.changePasswordText}>
            {t('login.rememberPassword')}{' '}
            <Link style={styles.changePasswordLink} to="/login">
              {t('login.enter')}
            </Link>
          </Typography>
        </Card>
      </form>
    </Box>
  );
};

export default ChangePassword;
