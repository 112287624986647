import { Button, Checkbox, FormControlLabel, Grid, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';

import { ReactComponent as CheckboxCheckedIcon } from 'assets/svg/checkboxSecondaryChecked.svg';
import { ReactComponent as CheckboxIcon } from 'assets/svg/checkboxSecondary.svg';
import MobileDatePicker from 'components/Common/MobileDatePicker';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import { getDaysInMonth } from 'utils/date';

const styles = {
  confirmButton: {
    width: '120px',
    height: '36px',
    boxSizing: 'border-box',
    marginRight: '25px',
  },
  headerContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 26px',
    height: '69px',
  },
  dialogFooter: (theme: any) => ({
    height: '98px',
    backgroundColor: theme.otherColors.lightBlue,
    padding: '0 26px',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    margin: 0,
    marginTop: '24px',
    borderBottomRightRadius: '4px',
    borderBottomLeftRadius: '4px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 26px',
      height: '102px',
    },
  }),
  closeButton: {
    padding: 0,
  },
  periodsContainer: {
    flexDirection: 'column',
    padding: '0 26px',
  },
  daysContainer: {
    marginTop: '14px',
  },
};

interface FilterModalProps {
  close: () => void;
  currentDate: { startDate: Date; endDate: Date };
  handleDateChange: ({ startDate, endDate }: { startDate: Date; endDate: Date }) => void;
}

enum FilterPeriod {
  period = 'period',
  today = 'today',
  threeDays = 'threeDays',
  week = 'week',
  month = 'month',
}

const FILTER_PERIOD_NUMBER = {
  [FilterPeriod.today]: 0,
  [FilterPeriod.threeDays]: 3,
  [FilterPeriod.week]: 7,
  [FilterPeriod.month]: getDaysInMonth(),
};

const addDaysToNowDate = (days: number) => {
  const date = new Date();
  date.setDate(date.getDate() - days);
  return date;
};
const FilterModal: React.FC<FilterModalProps> = ({ close, currentDate, handleDateChange }) => {
  const { t } = useTranslation();
  const [selectedPeriod, setSelectedPeriod] = useState<FilterPeriod>(FilterPeriod.period);
  const [periodValue, setPeriodValue] = useState(currentDate);

  const handleSave = () => {
    const getDateFromSelectedPeriod = () => {
      if (selectedPeriod === FilterPeriod.period) return periodValue;
      return { startDate: addDaysToNowDate(FILTER_PERIOD_NUMBER[selectedPeriod]), endDate: new Date() };
    };

    const date = getDateFromSelectedPeriod();
    handleDateChange(date);
    close();
  };

  return (
    <>
      <Grid container sx={styles.headerContainer}>
        <Grid item>
          <Typography variant="h3">{t('dashboard.filter')}</Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={close} sx={styles.closeButton} size="large">
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container sx={styles.periodsContainer}>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                color="primary"
                onChange={() => setSelectedPeriod(FilterPeriod.period)}
                checked={selectedPeriod === FilterPeriod.period}
                icon={<CheckboxIcon />}
                checkedIcon={<CheckboxCheckedIcon />}
              />
            }
            label={t('dashboard.period')}
          />
          <MobileDatePicker
            name="period"
            value={periodValue}
            onChange={setPeriodValue}
            disabled={selectedPeriod !== FilterPeriod.period}
            maxDate={new Date()}
          />
        </Grid>
        <Grid container sx={styles.daysContainer}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  color="primary"
                  onChange={() => setSelectedPeriod(FilterPeriod.today)}
                  checked={selectedPeriod === FilterPeriod.today}
                  icon={<CheckboxIcon />}
                  checkedIcon={<CheckboxCheckedIcon />}
                />
              }
              label={t('dashboard.today')}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  color="primary"
                  onChange={() => setSelectedPeriod(FilterPeriod.threeDays)}
                  checked={selectedPeriod === FilterPeriod.threeDays}
                  icon={<CheckboxIcon />}
                  checkedIcon={<CheckboxCheckedIcon />}
                />
              }
              label={t('dashboard.for3days')}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  color="primary"
                  onChange={() => setSelectedPeriod(FilterPeriod.week)}
                  checked={selectedPeriod === FilterPeriod.week}
                  icon={<CheckboxIcon />}
                  checkedIcon={<CheckboxCheckedIcon />}
                />
              }
              label={t('dashboard.forWeek')}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  color="primary"
                  onChange={() => setSelectedPeriod(FilterPeriod.month)}
                  checked={selectedPeriod === FilterPeriod.month}
                  icon={<CheckboxIcon />}
                  checkedIcon={<CheckboxCheckedIcon />}
                />
              }
              label={t('dashboard.forMonth')}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sx={styles.dialogFooter}>
          <Button variant="contained" color="primary" sx={styles.confirmButton} onClick={handleSave}>
            {t('common.save')}
          </Button>
          <Button onClick={close} color="primary">
            {t('common.cancel')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default FilterModal;
