import React from 'react';

import { useAuth } from 'context/Auth';
import AppRouter from './AppRouter';
import AuthRouter from './AuthRouter';

const Router = () => {
  const {
    authData: { userId },
  } = useAuth();

  return userId ? <AppRouter /> : <AuthRouter />;
};

export default Router;
