import React, { useEffect, useState } from 'react';
import { Fullscreen } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers';
import { Box, Card, Grid, IconButton, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { styles } from './styles';

interface Props {
  updateDate: (date: Date | null) => void;
  date: Date | null;
  data: any;
  level: string;
  setLevel: (level: string) => void;
}

const CompetitiveHeader: React.FC<Props> = ({ updateDate, date, data, level, setLevel }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const onFullScreenClick = () => {
    const elem = document.querySelector('.full-screen');
    if (elem) {
      elem.requestFullscreen();
    }
  };
  const currentDate = new Date(date || '');

  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
      <Box display={'flex'} alignItems={'center'}>
        <Box>
          <Typography variant="h1" sx={styles.title}>
            {level === 'day'
              ? currentDate.toLocaleDateString()
              : currentDate.toLocaleString('default', { month: 'long' })}
          </Typography>
          {!!data?.updatedAt && (
            <Typography variant="body1">
              {t('table.updated')} {new Date(data.updatedAt).toLocaleString()}
            </Typography>
          )}
        </Box>
        <Box ml={2}>
          <Tabs value={level} onChange={(e, value) => setLevel(value)}>
            <Tab label={t('table.day')} value={'day'} />
            <Tab label={t('table.month')} value={'month'} />
          </Tabs>
        </Box>
      </Box>
      <Box display={'flex'}>
        <DatePicker
          value={date ? new Date(date) : date}
          onChange={updateDate}
          format="dd.MM.yyyy"
          maxDate={new Date()}
        />
        <IconButton onClick={onFullScreenClick}>
          <Fullscreen />
        </IconButton>
      </Box>
    </Box>
  );
};

export default CompetitiveHeader;
