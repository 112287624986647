import React from 'react';
import { TableCell, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Column, Params } from './models';

interface TableHeaderProps<T> {
  columns: Column<T>[];
}

const TableHeader = <T extends Params<T>>({ columns }: TableHeaderProps<T>) => {
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell width={100}>
        <Typography>{t('common.actions')}</Typography>
      </TableCell>
      {columns.map((column) => (
        <TableCell key={column.title}>
          <Typography> {column.title}</Typography>
        </TableCell>
      ))}
    </TableRow>
  );
};

export default TableHeader;
