const px = '24px';
const py = '20px';
export const HEADER_HEIGHT = '70px';
export const DESKTOP_WIDTH = '400px';
export const styles = {
  drawer: {
    height: '100%',
  },
  taskTitle: {
    textTransform: 'uppercase',
    color: (theme: any) => theme.otherColors.white,
    fontWeight: 600,
    letterSpacing: '1.25px',
    fontSize: 16,
  },
  container: {
    height: '100%',
    padding: 0,
    overflow: 'auto',
  },
  header: {
    height: HEADER_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: (theme: any) => `1px solid ${theme.otherColors.lightGray}`,
    position: 'fixed',
    zIndex: 1,
    px,
    boxSizing: 'border-box',
  },
  subHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    mt: HEADER_HEIGHT,
    px,
    py,
    boxSizing: 'border-box',
  },
  closeButton: {
    padding: 0,
  },
  subtitle: {
    fontSize: '14px',
    color: (theme: any) => theme.otherColors.darkGray,
  },
  iconButton: {
    mr: '20px',
  },
  buttonContainer: {
    height: '18px',
    position: 'relative',
    display: 'flex',
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    px,
  },
  listItem: {
    width: '100%',
    mt: '12px',
    p: '16px',
    border: (theme: any) => `1px solid ${theme.otherColors.lightGray}`,
    boxSizing: 'border-box',
  },
  date: {
    fontSize: '10px',
    color: (theme: any) => theme.otherColors.darkGray,
  },
  link: {
    fontStyle: 'italic',
    color: '#2D3748',
  },
};
