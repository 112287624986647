export const getNounCount = (number: number): number => {
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return 5;
  }
  n %= 10;
  if (n === 1) {
    return 1;
  }
  if (n >= 2 && n <= 4) {
    return 2;
  }
  return 5;
};
