import { useCallback, useEffect, useState } from 'react';

import { tasksCount as tasksCountEndpoint } from 'constants/endpoints';
import { customDateFormat } from 'utils/date';
import ApiClient from 'services/apiClient';
import { JobCount } from './models';

export const useJobCountRequest = (date: Date) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [jobCount, setJobCount] = useState<JobCount>({
    successNumber: 0,
    errorsNumber: 0,
    warningsNumber: 0,
  });

  const getJobCount = useCallback(async () => {
    try {
      setLoading(true);
      const res = await ApiClient.GET(tasksCountEndpoint, {
        params: {
          date: customDateFormat(date, 'YYYY-MM-DD'),
        },
      });
      const { success, warning, error } = res;
      setJobCount({
        successNumber: success,
        errorsNumber: error,
        warningsNumber: warning,
      });
    } catch (e) {
      console.log((e as any).statusText);
    } finally {
      setLoading(false);
    }
  }, [date]);

  useEffect(() => {
    getJobCount();
  }, [getJobCount]);

  return {
    loading,
    jobCount,
    refetchJobCount: getJobCount,
  };
};
