import { Checkbox, FormControl, FormControlLabel, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useController } from 'react-hook-form';
import InfoIcon from '@mui/icons-material/Info';
import _get from 'lodash.get';

import { CommonCheckboxProps } from './models';

const CommonCheckbox: React.FC<CommonCheckboxProps> = ({ name, control, label, description, ...props }) => {
  const {
    field,
    formState: { errors },
  } = useController({
    name,
    control,
  });

  const error = _get(errors, name);
  // const errorMessage = error?.['message'];

  return (
    <FormControl error={!!error}>
      <FormControlLabel
        label={
          <Typography variant="h5" sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            {label}
            {description && (
              <Tooltip title={description}>
                <InfoIcon fontSize="small" />
              </Tooltip>
            )}
          </Typography>
        }
        control={<Checkbox inputRef={field.ref} {...field} {...props} checked={field.value} />}
      />
      {/*{error && <FormHelperText>{t(`${errorMessage}`)}</FormHelperText>}*/}
    </FormControl>
  );
};

export default CommonCheckbox;
