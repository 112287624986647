import React, { useState } from 'react';

const ExpandableText = ({ text }: { text: string }) => {
  const [expanded, setExpanded] = useState(false);

  const handlePreviewClick = () => {
    if (expanded) return;
    setExpanded(!expanded);
  };

  return (
    <div>
      <div onClick={handlePreviewClick}>{expanded ? text : `${text.slice(0, 100)}...`}</div>
    </div>
  );
};

export default ExpandableText;
