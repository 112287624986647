import { useMemo } from 'react';

import { Platforms } from 'constants/platforms';
import { usePermissionCheck } from 'hooks/usePermissionCheck';
import { ReactComponent as TiktokIcon } from 'assets/svg/tiktok.svg';
import { ReactComponent as FacebookIcon } from 'assets/svg/facebook.svg';
import { ReactComponent as GoogleIcon } from 'assets/svg/google.svg';
import { ReactComponent as YandexIcon } from 'assets/svg/yandex.svg';

interface PlatformLinksProps {
  tiktokViewAllowed: boolean;
  googleViewAllowed: boolean;
  facebookViewAllowed: boolean;
  yandexViewAllowed: boolean;
}

const getPlatformLinks = ({
  tiktokViewAllowed,
  googleViewAllowed,
  facebookViewAllowed,
  yandexViewAllowed,
}: PlatformLinksProps): { link: string; Icon: any; label: string; isVisible: boolean; linkedPlatform: Platforms }[] => [
  {
    link: '/tiktok',
    Icon: TiktokIcon,
    label: 'Tiktok',
    isVisible: tiktokViewAllowed,
    linkedPlatform: Platforms.tiktok,
  },
  {
    link: '/facebook',
    Icon: FacebookIcon,
    label: 'Facebook',
    isVisible: facebookViewAllowed,
    linkedPlatform: Platforms.facebook,
  },
  {
    link: '/google',
    Icon: GoogleIcon,
    label: 'Google',
    isVisible: googleViewAllowed,
    linkedPlatform: Platforms.google,
  },
  {
    link: '/yandex',
    Icon: YandexIcon,
    label: 'Yandex',
    isVisible: yandexViewAllowed,
    linkedPlatform: Platforms.yandex,
  },
];
export const usePlatformLinks = () => {
  const tiktokViewAllowed = usePermissionCheck(Platforms.tiktok);
  const googleViewAllowed = usePermissionCheck(Platforms.google);
  const yandexViewAllowed = usePermissionCheck(Platforms.yandex);
  const facebookViewAllowed = usePermissionCheck(Platforms.facebook);

  return useMemo(
    () =>
      getPlatformLinks({
        tiktokViewAllowed,
        googleViewAllowed,
        yandexViewAllowed,
        facebookViewAllowed,
      }),
    [tiktokViewAllowed, googleViewAllowed, yandexViewAllowed, facebookViewAllowed]
  );
};
