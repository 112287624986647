import React from 'react';
import { TableBody, TableCell, TableHead, Table, Card, TableContainer, TableRow, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { adsByGroup, groupsByCampaign, campaignsByAdvertiser, todayStats } from 'constants/endpoints';
import { useDashboardFilterContext } from 'context/DashboardFilter';
import { usePermissionCheck } from 'hooks/usePermissionCheck';
import PendingOperationsFooter from './PendingOperations';
import { ResourceOperationEnum } from 'models/permission';
import SortArrow from 'components/Common/SortArrow';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { Platforms } from 'constants/platforms';
import { AdLevels } from 'constants/levels';

import DashboardList from './DashboardList';
import DashboardHeader from './DashboardHeader';
import {
  DASHBOARD_CAMPAIGN_COLUMNS,
  DASHBOARD_COLUMNS,
  DASHBOARD_GROUP_COLUMNS,
  DASHBOARD_AD_COLUMNS,
  TableColumn,
} from './constants';
import PriorityProvider from './Context/Priority';
import styles from './styles';

interface Props {
  platform: Platforms;
}

const DashboardTable: React.FC<Props> = ({ platform }) => {
  const { isMobile } = useMediaQuery();
  const { t } = useTranslation();
  const { sort, onSort, dashboardError } = useDashboardFilterContext();
  const hasEditPermission = usePermissionCheck(platform, ResourceOperationEnum.edit);

  const renderHeadCell = (column: TableColumn) => {
    switch (column.key) {
      case 'checkbox':
      case 'options':
        if (isMobile) return null;
        return <TableCell key={column.key} component="th" sx={{ ...styles.checkboxCell, ...styles.tableHeadCell }} />;
      default:
        return (
          <TableCell
            style={{ minWidth: column.width, ...column.styles }}
            key={column.key}
            component="th"
            sx={styles.tableHeadCell}
            onClick={() => onSort(column.key)}>
            <Box sx={styles.tableHeadCellContent}>
              <Box>{t(`table.${column.key}`)}</Box>
              {column.key === sort?.field && <SortArrow value={sort?.order || -1} />}
            </Box>
          </TableCell>
        );
    }
  };

  return (
    <PriorityProvider>
      <Card sx={styles.table}>
        <DashboardHeader />
        <TableContainer sx={styles.customTableContainer}>
          <Table stickyHeader sx={styles.tableContainer}>
            <TableHead sx={styles.tableHead}>
              <TableRow>{DASHBOARD_COLUMNS.map((column: any) => renderHeadCell(column))}</TableRow>
            </TableHead>
            <TableBody>
              <DashboardList
                endpoint={todayStats(platform)}
                columns={DASHBOARD_COLUMNS}
                level={AdLevels.advertiser}
                show
                platform={platform}
                hasEditPermission={hasEditPermission}>
                {(advertiserId: string, isAdvertiserExpanded: boolean) => (
                  <DashboardList
                    show={isAdvertiserExpanded}
                    endpoint={campaignsByAdvertiser(advertiserId, platform)}
                    columns={DASHBOARD_CAMPAIGN_COLUMNS}
                    level={AdLevels.campaign}
                    platform={platform}
                    hasEditPermission={hasEditPermission}>
                    {(campaignId: string, isCampaignExpanded: boolean) => (
                      <DashboardList
                        endpoint={groupsByCampaign(campaignId, platform)}
                        columns={DASHBOARD_GROUP_COLUMNS}
                        show={isCampaignExpanded}
                        level={AdLevels.group}
                        platform={platform}
                        hasEditPermission={hasEditPermission}>
                        {(groupId: string, isGroupExpanded: boolean) => (
                          <DashboardList
                            endpoint={adsByGroup(groupId, platform)}
                            columns={DASHBOARD_AD_COLUMNS}
                            show={isGroupExpanded}
                            level={AdLevels.ad}
                            platform={platform}
                            hasEditPermission={hasEditPermission}
                          />
                        )}
                      </DashboardList>
                    )}
                  </DashboardList>
                )}
              </DashboardList>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <PendingOperationsFooter />
    </PriorityProvider>
  );
};

export default DashboardTable;
