export const styles = {
  customTableContainer: {
    overflowX: 'initial',
  },
  checkboxCell: {
    width: 30,
  },
  checkbox: {
    margin: '-5px',
  },
  clickable: {
    cursor: 'pointer',
  },
  closeButton: {
    padding: 0,
  },
  headerContainer: (theme: any) => ({
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 31px',
    height: '71px',
    [theme.breakpoints.down('md')]: {
      padding: '0 26px',
      height: '69px',
    },
  }),
  title: (theme: any) => ({
    fontSize: '24px',
    [theme.breakpoints.down('md')]: {
      fontSize: '18px',
    },
  }),
  table: {
    border: (theme: any) => `1px solid ${theme.otherColors.lightGray}`,
  },
  contentContainer: (theme: any) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 31px',
    paddingTop: '0',
    [theme.breakpoints.down('md')]: {
      padding: '0 26px',
      paddingTop: '10px',
    },
  }),
  tableBody: (theme: any) => ({
    display: 'block',
    maxHeight: 'calc(90vh - 71px - 56px - 24px - 84px)',
    height: 'calc(90vh - 71px - 56px - 24px - 84px)',
    overflow: 'auto',
    [theme.breakpoints.down('md')]: {
      maxHeight: 'calc(80vh - 69px - 56px - 10px - 102px)',
      height: 'calc(80vh - 69px - 56px - 10px - 102px)',
    },
  }),
  searchInput: {
    width: '100%',
    '& .MuiOutlinedInput-input': {
      boxSizing: 'border-box',
      height: '36px',
    }
  },
  searchInputContainer: (theme: any) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '56px',
    border: `1px solid ${theme.otherColors.lightGray}`,
    borderBottom: 'none',
    padding: '0 16px',
    boxSizing: 'border-box',
  }),
  container: {
    overflow: 'hidden',
    position: 'relative',
  },
  tableCell: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: 'none',
  },
  footer: (theme: any) => ({
    bottom: '32px',
    height: '84px',
    backgroundColor: theme.otherColors.lightBlue,
    padding: '0 31px',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    margin: 0,
    borderBottomRightRadius: '4px',
    borderBottomLeftRadius: '4px',
    [theme.breakpoints.down('md')]: {
      padding: '0 26px',
      height: '102px',
    },
  }),
  confirmButton: {
    width: '120px',
    height: '36px',
    boxSizing: 'border-box',
    marginRight: '25px',
  },
  text: {
    fontSize: '12px',
    fontWeight: 600,
  },
  tableRow: {
    display: 'flex',
    width: '100%',
  }
}