import { string as yupString, object as yupObject, date as yupDate } from 'yup';

export enum NewPendingOperationsFormFields {
  date = 'date',
  action = 'action',
}

export type NewPendingOperationsFormValues = {
  [NewPendingOperationsFormFields.date]: Date | null;
  [NewPendingOperationsFormFields.action]: string;
};

export const VALIDATION_SCHEMA = yupObject().shape({
  [NewPendingOperationsFormFields.date]: yupDate().required('errors.required'),
  [NewPendingOperationsFormFields.action]: yupString().required('errors.required'),
});
