import _get from 'lodash.get';

export const compareNumber = (compareInstance: any, value: any, comparedValueKey: string) => {
  const comparedValue = _get(compareInstance, comparedValueKey);
  return Number(comparedValue) === Number(value);
};

export const compareIncludes = (compareInstance: any, value: any, comparedValueKey: string) => {
  const comparedValue = _get(compareInstance, comparedValueKey);
  const comparedValueLowerCase = comparedValue.toLowerCase();
  const valueLowerCase = value.toLowerCase();
  return comparedValueLowerCase.includes(valueLowerCase);
};
