import { useTranslation } from 'react-i18next';
import React, { ChangeEvent, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material';
import { ReactComponent as SearchIcon } from 'assets/svg/search.svg';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import { useAccountFilter } from 'context/AccountFilter';
import { useDebounce } from 'hooks/useDebounce';
import { Advertiser } from 'models/advertiser';

import { styles } from './styles';

interface AdvertiserFilterProps {
  onSave: (advertisers: string[]) => void;
  close: () => void;
  selectedAccounts: string[];
  advertisers: Advertiser[];
  advertisersLoading: boolean;
}

const AdvertiserFilter: React.FC<AdvertiserFilterProps> = ({
  onSave,
  close,
  selectedAccounts,
  advertisersLoading,
  advertisers,
}) => {
  const { activeRows } = useAccountFilter();
  const { t } = useTranslation();
  const [checkedRows, setCheckedRows] = useState<string[]>(selectedAccounts);
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValue = useDebounce(searchValue, 300);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value);

  const filteredAdvertisers = useMemo(() => {
    if (!debouncedSearchValue) {
      return advertisers.filter(({ name, _id }) => activeRows.includes(_id));
    }

    return advertisers.filter(
      ({ name, _id }) => activeRows.includes(_id) && name.toLowerCase().includes(debouncedSearchValue.toLowerCase())
    );
  }, [debouncedSearchValue, activeRows, advertisers]);

  const handleCheckAll = () => {
    if (!checkedRows.length || checkedRows.length < activeRows.length) {
      setCheckedRows(activeRows);
      return;
    }
    setCheckedRows([]);
  };

  const handleCheck = (id: string) => {
    if (checkedRows.indexOf(id) === -1) {
      setCheckedRows([...checkedRows, id]);
      return;
    }
    setCheckedRows(checkedRows.filter((item) => item !== id));
  };

  const handleSave = async () => {
    onSave(checkedRows);
    close();
  };

  return (
    <Grid container sx={styles.container}>
      <Grid container sx={styles.headerContainer}>
        <Grid item>
          <Typography variant="h3">{t('dashboard.filterAccounts')}</Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={close} sx={styles.closeButton} size="large">
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Box sx={styles.contentContainer}>
        <Box sx={styles.searchInputContainer}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            placeholder="Search"
            variant="outlined"
            sx={styles.searchInput}
            onChange={handleSearchChange}
            value={searchValue}
          />
        </Box>
        <Table sx={styles.table}>
          <TableBody sx={styles.tableBody}>
            <TableRow sx={styles.tableRow}>
              <TableCell component="td" sx={{ ...styles.checkboxCell, ...styles.tableCell }}>
                <Checkbox
                  size={'small'}
                  color={'primary'}
                  onChange={() => handleCheckAll()}
                  checked={!!checkedRows.length && checkedRows.length === activeRows.length}
                  indeterminate={!!checkedRows.length && checkedRows.length !== activeRows.length}
                  sx={styles.checkbox}
                />
              </TableCell>
              <TableCell component="th" sx={styles.tableCell}>
                <Typography sx={styles.text}>{t('dashboard.all')}</Typography>
              </TableCell>
            </TableRow>
            {advertisersLoading && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  marginTop: '10px',
                }}>
                <CircularProgress />
              </div>
            )}
            {filteredAdvertisers.map(({ _id, name }) => (
              <TableRow sx={{ ...styles.clickable, ...styles.tableRow }} key={_id} onClick={() => handleCheck(_id)}>
                <TableCell component="td" sx={styles.tableCell}>
                  <Checkbox
                    size={'small'}
                    color={'primary'}
                    checked={checkedRows.indexOf(_id) !== -1}
                    sx={styles.checkbox}
                  />
                </TableCell>
                <TableCell component="td" sx={styles.tableCell}>
                  <Typography sx={styles.text}>{name}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <Grid sx={styles.footer}>
        <Button
          disabled={!checkedRows.length}
          variant="contained"
          color={'primary'}
          onClick={handleSave}
          sx={styles.confirmButton}>
          {t('common.save')}
        </Button>
        <Button onClick={close} color={'primary'}>
          {t('common.cancel')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default AdvertiserFilter;
