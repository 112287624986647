import React from 'react';
import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';
import { ArrowDropDown } from '@mui/icons-material';
import { Select, MenuItem, FormControl, FormHelperText, Typography, InputLabel } from '@mui/material';
import _get from 'lodash.get';

import { CommonSelectProps, OptionItem } from './models';
import { LoaderSizes } from '../Loader/models';
import Loader from '../Loader';

interface CommonSelectIconInputProps {
  loading: boolean;
  IconComponent?: React.ElementType;
}

export const CommonSelectIconInput: React.FC<CommonSelectIconInputProps> = ({ loading = false, IconComponent }) => {
  if (loading) return <Loader sx={{ marginRight: '10px', color: 'gray' }} size={LoaderSizes.small} />;
  return (
    <>
      {IconComponent && <IconComponent />}
      <ArrowDropDown />
    </>
  );
};

const CommonSelect: React.FC<CommonSelectProps> = ({
  options,
  name,
  control,
  label,
  width = '100%',
  loading = false,
  IconComponent,
  shouldTranslate = false,
  description,
  placeholder,
  ...props
}) => {
  const { t } = useTranslation();
  const {
    field,
    formState: { errors },
  } = useController({
    name,
    control,
  });

  const error = _get(errors, name);
  const errorMessage = error?.['message'];

  return (
    <FormControl error={!!error} sx={{ width }}>
      <Typography variant="h5" sx={{ display: 'flex', justifyContent: 'flex-start' }}>
        {label}
      </Typography>
      <Select
        {...field}
        {...props}
        label={''}
        IconComponent={() => <CommonSelectIconInput loading={loading} IconComponent={IconComponent} />}>
        {options.map((option: OptionItem) => (
          <MenuItem key={option.id} value={option.id}>
            {shouldTranslate ? t(option.label) : option.label}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{t(`${errorMessage}`)}</FormHelperText>}
    </FormControl>
  );
};
export default CommonSelect;
