import { useCallback, useMemo, useState } from 'react';
import { useTheme } from '@mui/material';

import { Analytic, AnalyticMetrics } from '../models';
import { formatValue } from '../utils';

export const useAnalyticMetrics = (analytics: Analytic[]) => {
  const theme = useTheme();
  const [selectedMetric, setSelectedMetric] = useState<AnalyticMetrics>(
    AnalyticMetrics.spend,
  );

  const getMetricAnalytic = useCallback(
    (analyticMetric: AnalyticMetrics): Analytic | null =>
      analytics.find(({ key }) => key === analyticMetric) || null,
    [analytics],
  );

  const selectedMetricAnalytics = useMemo(
    () => getMetricAnalytic(selectedMetric),
    [getMetricAnalytic, selectedMetric],
  );

  const tooltip = {
    callbacks: {
      label: (context: any) => {
        const { dataIndex } = context;
        const value = context.dataset.data[dataIndex];
        return `${formatValue(selectedMetric, value)}`;
      },
    },
  };

  const chartData = useMemo(() => {
    const data = selectedMetricAnalytics?.body.monthValues;

    return {
      labels: data?.map(({ day }) => day.substring(0, day.length - 5)),
      datasets: [
        {
          data: data?.map(({ value }) => value),
          borderColor: theme.palette.primary.main,
        },
      ],
    };
  }, [selectedMetricAnalytics?.body.monthValues, theme.palette.primary.main]);

  return {
    chartData,
    getMetricAnalytic,
    tooltip,
    selectedMetric,
    setSelectedMetric,
  }
}