export const getDataFromLocalStorage = (key: string) => {
  try {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  } catch (error) {
    return null;
  }
};

export const setDataToLocalStorage = (key: string, value: any) => {
  if (typeof value === 'boolean') {
    localStorage.setItem(key, JSON.stringify(value));
  }
  if (value) {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

export const removeDataFromLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};
