import { Task } from './models';
import { ReactNode } from 'react';
import { customDateFormat } from 'utils/date';
import ExpandableText from './ExpandableText';
import moment from 'moment';

export const TABLE_COLUMNS: {
  key: string;
  title: string;
  render?: (errorLog: Task) => ReactNode;
  cellStyles?: any;
}[] = [
  {
    key: 'dateEnd',
    title: 'admin.jobs.time',
    render: (errorLog) => customDateFormat(errorLog.payload?.dateEnd, 'HH:mm'),
    cellStyles: { width: '60px' },
  },
  {
    key: 'date',
    title: 'admin.jobs.interval',
    render: (errorLog) =>
      errorLog.payload?.dateEnd
        ? moment(errorLog.payload.dateEnd).diff(moment(errorLog.payload.dateStart), 'minute')
        : '',
    cellStyles: { width: '60px' },
  },
  {
    key: 'date',
    title: 'admin.jobs.date',
    render: (errorLog) => customDateFormat(errorLog.date, 'DD.MM.YYYY'),
    cellStyles: { width: '120px' },
  },
  {
    key: 'name',
    title: 'admin.jobs.name',
    cellStyles: { width: '200px' },
  },
  {
    key: 'platform',
    title: 'admin.jobs.platform',
    cellStyles: { width: '150px' },
  },
  {
    key: 'status',
    title: 'admin.jobs.status',
    cellStyles: { width: '150px' },
  },
  {
    key: 'payload.errors',
    title: 'admin.jobs.errorMessage',
    render: (log) => <ExpandableText text={log.payload?.errors?.join('\n') || ''} />,
  },
];
