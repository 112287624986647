import React from 'react';
import { CircularProgress, Box, SxProps } from '@mui/material';
import { LoaderSizes } from './models';

const LOADER_SIZES = {
  [LoaderSizes.small]: '1rem',
  [LoaderSizes.medium]: '2rem',
};

interface LoaderProps {
  sx?: SxProps;
  size?: LoaderSizes;
}

const Loader: React.FC<LoaderProps> = ({ sx, size = LoaderSizes.medium }) => {
  return (
    <Box sx={{ ...styles, ...sx }}>
      <CircularProgress size={LOADER_SIZES[size]} color="inherit" />
    </Box>
  );
};

const styles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export default Loader;
