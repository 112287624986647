import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { User } from 'models/user';
import ApiClient from 'services/apiClient';
import {
  currentUser as currentUserEndpoint,
  login as loginEndpoint,
  changePassword as changePasswordEndpoint,
} from 'constants/endpoints';
import Loader from 'components/Common/Loader/Loader';
import { getDataFromLocalStorage, removeDataFromLocalStorage, setDataToLocalStorage } from '../utils/localStorage';

interface AuthData {
  user?: User | null;
  userId?: string;
}
interface IAuthContext {
  authData: AuthData;
  logout: () => void;
  login: (email: string, password: string) => void;
  changePassword: (userId: string, password: string, passwordConfirmation: string) => void;
  updateTrackedAccounts: (ids: string[], platform: string) => void;
}

const initialData = {
  user: undefined,
  userId: getDataFromLocalStorage('userId'),
};

const normalizeUser = (user: User) => ({
  ...user,
  normalizedPermissions: user.permissions.map(({ permission }) => permission),
});

const AuthContext = createContext<IAuthContext>({} as IAuthContext);
const AuthProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const [authData, setAuthData] = useState<AuthData>(initialData);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
  const { userId } = authData;

  useEffect(() => {
    const getCurrentUser = async () => {
      try {
        setLoadingInitial(true);
        if (!userId) return;
        const res = await ApiClient.GET(currentUserEndpoint);
        setAuthData((prev) => ({ ...prev, user: normalizeUser(res) }));
      } catch (e) {
        logout();
        console.log((e as any).statusText);
      } finally {
        setLoadingInitial(false);
      }
    };

    getCurrentUser();
  }, [userId]);

  const login = useCallback(async (email: string, password: string) => {
    try {
      setLoading(true);
      const res = await ApiClient.POST(loginEndpoint, { body: { email, password } });
      const { accessToken, refreshToken, userId } = res;
      if (accessToken && refreshToken && userId) {
        setDataToLocalStorage('accessToken', accessToken);
        setDataToLocalStorage('refreshToken', refreshToken);
        setDataToLocalStorage('userId', userId);
        setAuthData({ userId });
        return;
      }
      navigate('/change-password', {
        state: { userId },
      });
    } catch (e) {
      console.log((e as any).statusText);
      throw new Error('Wrong credentials');
    } finally {
      setLoading(false);
    }
  }, []);

  const changePassword = useCallback(async (id, password: string, passwordConfirmation: string) => {
    try {
      setLoading(true);
      const res = await ApiClient.POST(changePasswordEndpoint, {
        body: { userId: id, password, passwordConfirmation },
      });
      const { accessToken, refreshToken, userId } = res;
      setDataToLocalStorage('accessToken', accessToken);
      setDataToLocalStorage('refreshToken', refreshToken);
      setDataToLocalStorage('userId', userId);
      setAuthData({ userId });
    } catch (e) {
      console.log((e as any).statusText);
    } finally {
      setLoading(false);
    }
  }, []);

  const logout = useCallback(() => {
    removeDataFromLocalStorage('authData');
    setAuthData({});
  }, []);

  const updateTrackedAccounts = useCallback((ids: string[], platform: string) => {
    setAuthData((prev) => {
      if (prev.user) {
        return {
          ...prev,
          user: {
            ...prev.user,
            trackedAccounts: {
              ...prev.user?.trackedAccounts,
              [platform]: ids,
            },
          },
        };
      }

      return prev;
    });
  }, []);

  const memoValue = useMemo(
    () => ({
      authData,
      loading,
      login,
      logout,
      changePassword,
      updateTrackedAccounts,
    }),
    [authData, loading, login, logout, changePassword, updateTrackedAccounts]
  );

  return (
    <AuthContext.Provider value={memoValue}>
      {loadingInitial && <Loader sx={{ height: '100vh ' }} />}
      {!loadingInitial && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const contextValue = useContext(AuthContext);
  return contextValue;
};

export default AuthProvider;
