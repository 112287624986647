import _get from 'lodash/get';
import moment from 'moment';
import 'moment/locale/ru';

import { AdLevels } from 'constants/levels';
import { checkNumberValue } from 'utils/number';
import { Platforms } from 'constants/platforms';
import { ACTIVE_STATUSES, CAN_CHANGE_STATUS_PLATFORMS, DISABLED_STATUSES, formatMoney } from './constants';
import { addDays, customDateFormat } from '../../utils/date';
import { StatsItem } from 'models/stats';
import { Currencies } from 'models/currencies';

export const getBackgroundColor = (theme: any, level: AdLevels): string => {
  switch (level) {
    case AdLevels.campaign:
      return '#F4F5F7';
    case AdLevels.group:
      return '#E8ECF0';
    case AdLevels.ad:
      return '#DDE2E8';
    default:
      return '#fff';
  }
};

export const getStatusData = (data: any) => {
  const isActiveDefault = ACTIVE_STATUSES.indexOf(data.status || data) !== -1;
  const isInactiveDefault = DISABLED_STATUSES.indexOf(data.status || data) !== -1;
  const canBeChanged = isActiveDefault || isInactiveDefault;

  return {
    isActiveDefault,
    isInactiveDefault,
    canBeChanged,
  };
};

export const canChangeStatus = (platform: Platforms, level: AdLevels) => CAN_CHANGE_STATUS_PLATFORMS[platform][level];

export const getDifference = (dateString?: string) => {
  if (!dateString) return '';
  return moment(dateString).fromNow();
};

const metricsFields = [
  'clicks',
  'conversion',
  'conversion_rate',
  'cost_per_conversion',
  'cpc',
  'ctr',
  'cpm',
  'impressions',
  'reach',
  'frequency',
  'spend',
];

export const getSortFieldName = (fieldName: string) => {
  return metricsFields.includes(fieldName) ? `metrics.${fieldName}` : fieldName;
};

enum Metrics {
  Clicks = 'clicks',
  Conversion = 'conversion',
  CR = 'conversion_rate',
  CPA = 'cost_per_conversion',
  CPC = 'cpc',
  CTR = 'ctr',
  CPM = 'cpm',
  Impressions = 'impressions',
  Spend = 'spend',
  Reach = 'reach',
  Frequency = 'frequency',
}

type Limit = {
  [key in Metrics]?: {
    [currency in Currencies]?: {
      min: number;
      color: string;
    }[];
  };
};

const LIMITS: Limit = {
  [Metrics.CPA]: {
    [Currencies.USD]: [
      { min: 0, color: '#3bff008a' },
      { min: 2.5, color: '#7fff586a' },
      { min: 2.9, color: '#fff9588a' },
      { min: 3.3, color: '#f9ae3d8a' },
      { min: 3.5, color: '#ff7f588a' },
      { min: 3.7, color: '#fd7d278a' },
      { min: 4.1, color: '#fd27278a' },
      { min: 4.5, color: '#0000004a' },
    ],
  },
};

export const getColor = (row: StatsItem, key: Metrics) => {
  if (!Number(row[key])) {
    return false;
  }
  const value = Number(row[key]);
  const limits = LIMITS[key]?.[Currencies.USD];
  if (!limits) return false;
  const result = [...limits].reverse().find((limit) => value >= limit.min);

  console.log(value, result);
  return result?.color;
};

const SHOWN_FIELDS = {
  [Platforms.tiktok]: {
    [AdLevels.group]: ['bid', 'budget'],
  },
  [Platforms.facebook]: {
    [AdLevels.group]: ['budget', 'lifetime_budget'],
  },
  [Platforms.google]: {
    [AdLevels.group]: ['bid'],
    [AdLevels.campaign]: ['budget'],
  },
  [Platforms.yandex]: {
    [AdLevels.campaign]: ['bid'],
  },
};

export const formatBidValue = (data: StatsItem, platform: Platforms, level: AdLevels) => {
  const currency = _get(data, 'currency') || Currencies.USD;
  const fields = _get(SHOWN_FIELDS, [platform, level], '');
  if (!fields) return '';
  return fields
    .filter((field: string) => checkNumberValue(_get(data, field, '')))
    .map((field: string) => formatMoney(Number(_get(data, field)), { currency }))
    .join('\n');
};

type GenerateLinkInput = {
  platform: Platforms;
  account: { externalId?: string };
  date: { startDate: Date; endDate: Date };
};

export const generateLink = ({ platform, account, date: { startDate, endDate } }: GenerateLinkInput) => {
  const { externalId: id } = account;
  const startDateString = customDateFormat(startDate, 'YYYY-MM-DD');
  const endDateString = customDateFormat(endDate, 'YYYY-MM-DD');
  if (!id) return '';
  switch (platform) {
    case Platforms.tiktok:
      return `https://ads.tiktok.com/i18n/perf/campaign?aadvid=${id}&st=${startDateString}&et=${endDateString}`;
    case Platforms.facebook: {
      const facebookEndDateString = customDateFormat(addDays(endDate), 'YYYY-MM-DD');
      return `https://adsmanager.facebook.com/adsmanager/manage/campaigns?act=${
        id.split('act_')[1]
      }&date=${startDateString}_${facebookEndDateString}`;
    }
  }
};
