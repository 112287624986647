import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useCreatePendingOperationsRequest } from './useCreatePendingOperationsRequest';
import { usePendingOperations } from 'context/PendingOperations/PendingOperations';
import { PendingOperationActions } from 'models/pendingOperations';
import { NewPendingOperationsFormFields, VALIDATION_SCHEMA } from './form';

interface Props {
  close: () => void;
}

export const useNewPendingOperationsForm = ({ close }: Props) => {
  const { selectedCampaigns, resetSelectedCampaigns, refetchPendingOperations } = usePendingOperations();
  const { createPendingOperations } = useCreatePendingOperationsRequest();
  const [loading, setLoading] = useState<boolean>(false);

  const { handleSubmit, control } = useForm<any>({
    mode: 'onBlur',
    defaultValues: {
      [NewPendingOperationsFormFields.date]: null,
      [NewPendingOperationsFormFields.action]: PendingOperationActions.turnOn,
    },
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const onSubmit = handleSubmit(
    async (formState) => {
      setLoading(true);
      const action = formState.action as PendingOperationActions;
      const date = formState.date.toISOString();
      await createPendingOperations({ date, action, campaigns: selectedCampaigns });
      await refetchPendingOperations();
      resetSelectedCampaigns();
      setLoading(false);
      close();
    },
    (e) => console.log(e)
  );

  return {
    control,
    onSubmit,
    loading,
    selectedCampaigns,
  };
};
