import React, { useEffect, useState } from 'react';
import { Box, Card, Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import _isempty from 'lodash.isempty';
import { styles } from './styles';
import CompetitiveCard from './CompetitiveCard';

const COLUMNS = [
  { title: 'table.targetolog', key: 'user.name', width: '30%' },
  { title: 'table.conversion', key: 'metrics.conversion', width: '7%' },
  { title: 'table.crmCount', key: 'metrics.totalCount', width: '7%' },
  { title: 'table.cpl', key: 'metrics.cpl', width: '14%' },
  { title: 'table.vp2', key: 'metrics.vp2', width: '14%' },
  { title: 'table.vp2Single', key: 'metrics.vp2Single', width: '14%' },
  { title: 'table.romi', key: 'metrics.romi', width: '14%' },
];

const rowHeight = 40;

interface Props {
  list: any[];
  country: string;
}

const CompetitiveTable: React.FC<Props> = ({ list: data, country }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [list, setList] = useState<any[]>([]);

  useEffect(() => {
    if (_isempty(data)) {
      setList([]);
      return;
    }
    setList(data);
    const isOrderChanged =
      _isempty(list) ||
      list.some((item, index) => item.user && data[index].user && item.user._id !== data[index].user._id);
    if (isOrderChanged) {
      setTimeout(() => {
        data.forEach((item, i) => {
          const card = document.getElementById(`${country}-card-${item.user?._id || 'total'}`);
          if (card) {
            card.style.transform = `translateY(${i * rowHeight}px)`;
          }
        });
      }, 100);
    }
  }, [data]);

  return (
    <Box mt={2}>
      <Box display={'flex'} alignItems={'center'}>
        <Typography variant="h3" sx={styles.country}>
          {t(`analytics.${country}`)}
        </Typography>
        <Box width={'100%'} pl={{ md: 2, xs: 0 }}>
          <Grid container mx={2} my={1} width={'auto'}>
            {COLUMNS.map((column) => (
              <Grid item key={column.title} sx={column.width ? { width: column.width } : null}>
                <Typography variant="h4" sx={styles.label}>
                  {t(column.title)}
                </Typography>
              </Grid>
            ))}
          </Grid>
          <Grid container position={'relative'} sx={{ height: `${list.length * rowHeight}px` }}>
            {list.map((item) => (
              <CompetitiveCard key={item.user?._id || 'total'} columns={COLUMNS} item={item} country={country} />
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default CompetitiveTable;
