import { Navigate, Route, Routes } from 'react-router-dom';
import AdminRouter from './AdminRouter';
import ClientRouter from './ClientRouter';
import React from 'react';
import { useAuth } from 'context/Auth';

const AppRouter = () => {
  const { authData } = useAuth();

  return (
    <Routes>
      <Route path="admin/*" element={authData?.user?.isAdmin ? <AdminRouter /> : <Navigate to="/" />} />
      <Route path="*" element={<ClientRouter />} />
    </Routes>
  );
};

export default AppRouter;
