import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Card, Drawer, Grid, IconButton, Typography } from '@mui/material';

import { ReactComponent as CloseDrawerIcon } from 'assets/svg/close-notifications.svg';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { usePlatform } from 'hooks/usePlatform';
import { formatDate } from 'utils/date';

import { useNotifications } from './NotificationsContext';
import { renderNotificationMessage } from './utils';
import { DESKTOP_WIDTH, styles } from './styles';

interface NotificationsSidebarProps {
  close: () => void;
  isOpen: boolean;
}

const NotificationsSidebar: React.FC<NotificationsSidebarProps> = ({ close, isOpen }) => {
  const platform = usePlatform();
  const { isMobile } = useMediaQuery();
  const { t } = useTranslation();
  const { notificationsCount, notifications } = useNotifications();

  return (
    <Drawer
      anchor={isMobile ? 'bottom' : 'right'}
      open={isOpen}
      ModalProps={{ onBackdropClick: close }}
      sx={{ height: '100%' }}
      PaperProps={{
        sx: styles.drawer,
      }}>
      <Card sx={{ ...styles.container, width: isMobile ? '100%' : DESKTOP_WIDTH }}>
        <Box sx={{ ...styles.header, width: isMobile ? '100%' : DESKTOP_WIDTH }}>
          <Typography variant="h2">{t('notifications.title')}</Typography>
          <IconButton onClick={close}>
            <CloseDrawerIcon />
          </IconButton>
        </Box>
        <Box sx={styles.subHeader}>
          <Typography sx={styles.subtitle}>
            {t(notificationsCount > 0 ? 'notifications.notificationsCount' : 'notifications.notificationsPlaceholder', {
              count: notificationsCount,
            })}
          </Typography>
        </Box>
        {notificationsCount > 0 && (
          <Box sx={styles.listContainer}>
            {notifications.map((notification) => (
              <Box sx={styles.listItem} key={notification._id}>
                <Grid container>
                  <Grid item xs={10}>
                    {renderNotificationMessage(notification, platform)}
                  </Grid>
                </Grid>
                <Grid container mt={1}>
                  <Typography sx={styles.date}>{formatDate(notification.createdAt, true)}</Typography>
                </Grid>
              </Box>
            ))}
          </Box>
        )}
      </Card>
    </Drawer>
  );
};

export default NotificationsSidebar;
