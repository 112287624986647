import React from 'react';
import { Box } from '@mui/material';

import PendingOperationsProvider from 'context/PendingOperations/PendingOperations';
import DashboardFilterProvider from 'context/DashboardFilter';
import DashboardTable from 'components/DashboardTable';
import { Platforms } from 'constants/platforms';

const styles = {
  root: (theme: any) => ({
    marginBottom: 100,
    padding: '30px 32px',
    boxSizing: 'border-box',
    height: 'calc(100% - 64px - 60px)',
    [theme.breakpoints.down('md')]: {
      padding: '24px 16px',
    },
  }),
};

interface Props {
  platform: Platforms;
}

const Dashboard: React.FC<Props> = ({ platform }) => (
  <PendingOperationsProvider>
    <DashboardFilterProvider>
      <Box sx={styles.root}>
        <DashboardTable platform={platform} />
      </Box>
    </DashboardFilterProvider>
  </PendingOperationsProvider>
);

export default Dashboard;
