import { useEffect, useState, useRef, useCallback } from 'react';

export const useDialog = () => {
  const [isDialogOpen, setOpen] = useState<boolean>(false);
  const [dialogParams, setDialogParams] = useState<any>(null);
  const didMount = useRef(false);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }

    if (!isDialogOpen) {
      setDialogParams(null);
    }
  }, [isDialogOpen, setDialogParams]);

  const closeDialog = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const openDialog = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const openDialogWithParams = useCallback((params: any) => {
    setDialogParams(params);
    setOpen(true);
  }, []);

  return {
    isDialogOpen,
    closeDialog,
    openDialog,
    openDialogWithParams,
    dialogParams,
  };
};
