import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import i18n from 'i18n';

export const ADMIN_MENU_ITEMS = [
  {
    link: '/admin/users',
    Icon: IntegrationInstructionsIcon,
    description: i18n.t('admin.users.users'),
    divider: false,
  },
  {
    link: '/admin/jobs',
    Icon: IntegrationInstructionsIcon,
    description: i18n.t('admin.jobs.jobs'),
    divider: false,
  },
  {
    link: '/admin/variables',
    Icon: IntegrationInstructionsIcon,
    description: i18n.t('admin.table.title'),
    divider: false,
  },
];
