import React from 'react';
import { Navigate } from 'react-router-dom';

import { getAvailablePlatform, usePermissionCheck } from 'hooks/usePermissionCheck';
import { Platforms } from 'constants/platforms';
import { useAuth } from 'context/Auth';

interface PermissionRouteProps {
  platform: Platforms;
}

// @ts-ignore
const PermissionRoute: React.FC<PermissionRouteProps> = ({ children, platform }) => {
  const {
    authData: { user },
  } = useAuth();
  const isRouteAllowed = usePermissionCheck(platform);

  if (isRouteAllowed) return children;

  const allowedPlatform = getAvailablePlatform(user?.normalizedPermissions);
  return <Navigate to={`/${allowedPlatform}`} replace />;
};

export default PermissionRoute;
