import { useCallback, useEffect, useMemo, useState } from 'react';

import { pendingOperations as pendingOperationsEndpoint } from 'constants/endpoints';
import { PendingOperation, PendingOperationStatus } from 'models/pendingOperations';
import ApiClient from 'services/apiClient';

export const usePendingOperationsRequest = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [pendingOperations, setPendingOperations] = useState<PendingOperation[]>([]);

  const activePendingOperations = useMemo(
    () =>
      pendingOperations.filter(
        ({ status }) => status !== PendingOperationStatus.deleted && status !== PendingOperationStatus.completed
      ),
    [pendingOperations]
  );

  const pendingOperationEntitiesIds = useMemo(
    () => activePendingOperations.map(({ entity }) => entity._id),
    [activePendingOperations]
  );

  const getPendingOperations = useCallback(async () => {
    try {
      setLoading(true);
      const res = await ApiClient.GET(pendingOperationsEndpoint);
      setPendingOperations(res);
    } catch (e) {
      console.log((e as any).statusText);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getPendingOperations();
  }, [getPendingOperations]);

  return {
    loading,
    pendingOperationEntitiesIds,
    pendingOperations: activePendingOperations,
    refetchPendingOperations: getPendingOperations,
  };
};
