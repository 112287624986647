import { useState } from 'react';
import { useDialog } from 'hooks/useDialog';
import { AdLevels } from 'constants/levels';
import { StatsItem } from 'models/stats';

export const useDashboardDialogs = () => {
  const [dialogData, setDialogData] = useState<any>(null);
  const [dialogType, setDialogType] = useState<AdLevels | null>(null);
  const { isDialogOpen, openDialog, closeDialog } = useDialog();

  const openDashboardDialog = (data: StatsItem, type: AdLevels) => {
    if (type === AdLevels.advertiser) return;
    setDialogData(data);
    setDialogType(type);
    openDialog();
  };

  const closeDashboardDialog = () => {
    setDialogData(null);
    setDialogType(null);
    closeDialog();
  };

  return {
    closeDashboardDialog,
    openDashboardDialog,
    isDialogOpen,
    dialogData,
    dialogType,
  };
};
