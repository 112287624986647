import React from 'react';
import AccountFilterProvider from 'context/AccountFilter';
import AuthProvider from 'context/Auth';
import Router from './router';
import './i18n';

const App = () => {
  return (
    <div style={{ height: '100vh' }}>
      <AuthProvider>
        <AccountFilterProvider>
          <Router />
        </AccountFilterProvider>
      </AuthProvider>
    </div>
  );
};

export default App;
